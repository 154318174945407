import React, { useEffect, useState } from "react";
import styled from "styled-components";
import FetchModule from "../../../Model/Network/FetchModule";

export default function NotePage(props) {

  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('wod/', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setEvents(responseData.data);
        }
      }
    )();
  }, []);

  return (
    <TopLevelWrapper>
      <TotalEventWrapper>
        {
          events.map((data, index) => (
            <EventInfoWrapper key={'EventInfo-' + index}>
              <EventProfileBoxWrapper>
                <EventProfileBox>

                </EventProfileBox>
                <EventNameBox>
                  <Text fontFamily='NotoSansKR-Black' fontSize={14} color='#FFFFFF' hover='default'>{data.eventTitle} 123123123123123123</Text>
                </EventNameBox>
              </EventProfileBoxWrapper>
            </EventInfoWrapper>
          ))
        }
      </TotalEventWrapper>
    </TopLevelWrapper>
  );
}
 
const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TotalEventWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  flex-wrap: wrap;

  width: 100%;
`;

const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(50% - 5px);
  gap: 10px;
`;

const EventProfileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(50% - 5px);

  gap: 10px;
`;

const EventProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: auto;

  border-radius: 24px;

  overflow: hidden;
`;

const EventNameBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  padding: 10px;

  width: calc(100% - 20px);
  height: 20px;
  
  overflow: hidden;

  border-radius: 10px;
  background-color: #2F2F32;
`;

const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  height: 100%;
`;

const EventWodBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(50% - 5px);

  height: 40px;

  background-color: red;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }
`;