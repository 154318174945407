import React from "react";
import styled from "styled-components";

import WhiteDownArrow from '../../../Image/WhiteDownArrow.png';

export default function ParticipantCountInput(props) {
  const { value, setValue, placeholder } = props;

	function handleChange(e) {
    const curValue = e.target.value
		const newValue = curValue.replace(/[^0-9]/g, '')

    setValue(newValue);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  // 위로 버튼 클릭시 //
  function onClickUpButton() {
    if (value === '' || value === 0) setValue(1);
    else setValue(parseInt(value) + 1);
  }

  // 아래로 버튼 클릭시 //
  function onClickDownButton() {
    if (value !== '' && value !== 0) setValue(parseInt(value) - 1);
  }

  return (
    <TopLevelWrapper>
      <InputView>
        <Input
          placeholder={placeholder}
          value={value}
          type='text'
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
      </InputView>
      <Button onClick={onClickUpButton}>
        <Image style={{ transform: `rotate(180deg)` }} src={WhiteDownArrow}/>
      </Button>
      <Button onClick={onClickDownButton}>
        <Image src={WhiteDownArrow}/>
      </Button>
      <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>명</Text>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
  
  gap: 4px;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 15px;

  width: 46px;
  height: 28px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Input = styled.input`
	font-family: NotoSansKR-Medium;
	font-size: 14px;
	line-height: 21px;

	width: 80%;
	height: 21px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
	color: #FFFFFF;

  border: none;
  background-color: #444447;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background-color: #444447;

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;