import React from "react";
import styled from "styled-components";

import NameArrow from '../../../Image/NameArrow.png';

export default function TicketNameInput(props) {
  const { value, setValue, placeholder } = props;

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <TopLevelWrapper>
      <EventNameImage src={NameArrow} />
      <InputView>
        <Input
          placeholder={placeholder}
          value={value}
          type='text'
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
      </InputView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  // width: 100%;
  width: 96.59%;

  gap: 10px;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 15px;

  // width: calc(100% - 34px);
  width: calc(100% - 56px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Input = styled.input`
	font-family: NotoSansKR-Black;
	font-size: 18px;
	line-height: 22px;

	width: 100%;
	height: 22px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
	color: #FFFFFF;

  border: none;
  background-color: #444447;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;
