import moment from "moment";
import React from "react";
import styled from "styled-components";

export default function DataViewBox(props) {
  const {
    data,
    events,
    onClickTeamInfo
  } = props;

  // 티켓 이름 결정 //
  function decideTicketName() {
    let ticketName = '';

    for (let i = 0; i < events.length; i++) {
      if (events[i].eventId === data.eventId) {
        ticketName = events[i].eventTitle;
        break;
      }
    }

    return ticketName;
  }

  // 소속 결정 //
  function decideBelong() {
    let belong = '';

    belong = data.members[0].belong;

    return belong;
  }

  return (
    <TopLevelWrapper onClick={() => onClickTeamInfo(data)}>
      <ColumnBox width={112}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.teamName}</Text>
      </ColumnBox>
      <ColumnBox width={252}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{decideTicketName()}</Text>
      </ColumnBox>
      <ColumnBox width={343}>
        {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>아하하</Text> */}
        {
          data.members.map((memberData, memberIndex) => (
            <MemberInfoWrapper key={'Members-' + memberIndex}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{memberData.name}</Text>
              <GenderBox isMale={memberData.gender === 'male'}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={8} color='#FFFFFF' cursor='pointer'>{memberData.gender === 'male' ? '남성' : '여성'}</Text>
              </GenderBox>
            </MemberInfoWrapper>
          ))
        }
      </ColumnBox>
      <ColumnBox width={172}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{decideBelong()}</Text>
      </ColumnBox>
      <ColumnBox width={70}>
        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>{moment(data.approvalDate).format('YYYY.MM.DD')}</Text>
      </ColumnBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 20px;

  width: calc(100% - 40px);
  height: 50px;

  gap: 8px;

  border-radius: 30px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 17px;

  gap: 4px;
  
  overflow: hidden;
`;
const MemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  min-width: 70px;
  
  gap: 4px;
`;

const GenderBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 6px;

  height: 14px;

  border-radius: 2px;
  background-color: ${props => props.isMale ? '#2F80ED' : '#FF8B48'};
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;
