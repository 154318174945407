import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import ExitIcon from '../../../../Image/ExitIcon.png';

export default function EventSelectModal(props) {
  const { 
    events, 
    setSelectedEvent, 
    closeFn 
  } = props;

  const { t } = useTranslation();

  const [pointY, setPointY] = useState(undefined);
  const [nowIndex, setNowIndex] = useState(-1); 

  const TopLevelRef = useRef();

  useEffect(() => {
    setTimeout(() => {
      TopLevelRef.current.style.height = 174 + (events.length * 68) + 'px';
    }, 100)
  }, [TopLevelRef, events])

  // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%;`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])


  // PC일경우 마우스 클릭 감지 //
  function onMouseDown(e) {
    if (pointY !== undefined) return;

    setPointY(e.screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // PC일경우 마우스 움직임 감지 //
  function onMouseMove(e) {
    if (pointY === undefined) return;

    if (e.screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.screenY - pointY) + 'px';
  }

  // PC일경우 마우스 클릭 마무리 감지 //
  function onMouseUp(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.screenY - pointY > 130) onClickExitButton();
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 모바일일 경우 터치 감지 //
  function onTouchStart(e) {
    if (pointY !== undefined) return;

    setPointY(e.changedTouches[0].screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // 모바일일 경우 터치 움직임 감지 //
  function onTouchMove(e) {
    if (pointY === undefined) return;

    if (e.changedTouches[0].screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.changedTouches[0].screenY - pointY) + 'px';
  }

  // 모바일일 경우 터치 움직임 마무리 감지 //
  function onTouchEnd(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.changedTouches[0].screenY - pointY > 130) {
      onClickExitButton();
    }
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 선택 버튼 클릭시 //
  function onClickSelectButton() {
    if (nowIndex === -1) {
      alert('종목을 선택해주세요');
      return;
    }

    setSelectedEvent(events[nowIndex]);
    onClickExitButton();
  }

  //  닫기 버튼 클릭시 //
  function onClickExitButton() {
    // TopLevelRef.current.style.marginTop = `790px`;
    TopLevelRef.current.style.height = '0px';

    setTimeout(() => {
      closeFn();
    }, 500);
  }

  return (
    <Background>
      <TopLevelWrapper ref={TopLevelRef}>
        <TitleLineWrapper
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          <TitleWrapper>
            <TitleMaximumWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F' cursor='default'>{t('종목 선택')}</Text>
            </TitleMaximumWrapper>
            <Image src={ExitIcon} onClick={onClickExitButton}/>
          </TitleWrapper>
        </TitleLineWrapper>
        <EventBoxWrapper>
        {
          events.map((data, index) => (
            <EventBox 
              style={{ marginTop: '16px' }} 
              key={index} 
              isClicked={index === nowIndex}
              onClick={() => setNowIndex(index)}>
              <Text style={{ marginLeft: '14px' }} fontFamily='NotoSansKR-Medium' fontSize={18} color={index === nowIndex ? `#FFFFFF` : '#4F4F4F'} cursor='pointer'>{data.name}</Text>
            </EventBox>
          ))
        }
        </EventBoxWrapper>
        <SelectButton onClick={onClickSelectButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'cursor='pointer'>{t('선택')}</Text>
        </SelectButton>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
  // position: absolute;
	display: flex;
	flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  // width: 100vw;
  // height: 100vh;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 0px 24px 0px;

  // margin-top: 790px;

	width: 100%;
  height: 0px;
  // height: 55%;
  // height: 55%;
  
	border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 56px;

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleMaximumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 83.68%;
  height: 25px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const EventBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: calc(90.33% - 4px);
  height: 48px;

  border: ${props => props.isClicked ? '2px solid #6DD49E' : '2px solid #DFDFDF'};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }
  transition: all 0.3s ease-in-out;
`;

const SelectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 34px;
  // margin-top: 12px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;
  
  transition: all 0.3s ease-in-out;

  :hover {
    cursor: ${props => props.cursor};
  }
`;