import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import KakaoAddress from '../../../../Model/EventApplicationModel/KakaoAddress';

export default function AddressBox(props) {
	const { 
    windowSize,
    zipCode, 
    onChangeZipCode,
    address,
    onChangeAddress,
    addressDetail,
    onChangeAddressDetail
  } = props;

  const { t } = useTranslation();

  const [isViewAddress, setIsViewAddress] = useState(false);
	const [changed, setChanged] = useState(false);

  const AddressRef = useRef();
  const InputDetailRef = useRef();

  // 상세 주소 클릭시 //
	function detailHandleClick(e) {
	}

  // 상세 주소 데이터 변경시 //
	function detailHandleChange(e) {
    onChangeAddressDetail(e.target.value);
		setChanged(true);
	}

  // 상세 주소 클릭시  //
	function detailHandleFocus(e) {
		if (changed) {
			// do nothing
		} else {
      onChangeAddressDetail('');
		}
	}

  // 상세 주소 클릭시  //
	function detailHandleBlur(e) {
	}

  // 주소 검색 클릭시 //
  function onClickAddressSearch() {
    setIsViewAddress(true);
  }

  return (
    <TotalWrapper>
      { 
        isViewAddress && 
        <KakaoAddress
          ref={AddressRef} 
          setZipCode={onChangeZipCode}
          setAddress={onChangeAddress}
          windowSize={windowSize}
          exitClick={() => setIsViewAddress(false)}/>
      }
      <ZipCodeBox>
        <TextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={15} color={zipCode === '' ? '#CBCBCB' : '#000000'}>{zipCode === '' ? '주소를 검색해주세요' : zipCode}</Text>
        </TextWrapper>
        <SearchButton onClick={onClickAddressSearch}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF'>검색</Text>
        </SearchButton>
      </ZipCodeBox>
      <Address>
        <AddressTextWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={15} color={address === '' ? '#CBCBCB' : '#000000'}>{address === '' ? '주소를 검색해주세요' : address}</Text>
        </AddressTextWrapper>
      </Address>
      <Input
        ref={InputDetailRef}
        placeholder={t('상세주소를 작성해주세요')}
        value={addressDetail}
        type='text'
        onClick={detailHandleClick}
        onChange={detailHandleChange}
        onFocus={detailHandleFocus}
        onBlur={detailHandleBlur} />
    </TotalWrapper>
	)
}

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const ZipCodeBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

	width: 100%;
	height: 48px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: #F9F9F9;
	
	color: #000000;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: colmn;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: 12px;

  width: 70%;
  height: 20px;
`;

const SearchButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 18.5%;
  height: 100%;

  border: 1px solid #6DD49E;
  border-radius: 0px 8px 8px 0px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Address = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

	width: calc(100% - 24px);
  height: 48px;

	padding: 0 12px 0 12px;
	margin: 8px 0 0 0;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  background-color: #F9F9F9;
	
	color: #000000;
`;

const AddressTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 22px;
  
  overflow: hidden;
`;

const Input = styled.input`
	font-family: NotoSansKR-Bold;
	font-size: 15px;
	line-height: 20px;

	width: calc(100% - 24px);
  height: 48px;

	padding: 0 12px 0 12px;
	margin: 8px 0 0 0;

	outline: none;
	border: 1px solid #E8E8E8;
	border-radius: 8px;
  background-color: #F9F9F9;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;