import React from 'react';
import styled from 'styled-components';

import InfoView from '../Model/InfoView';

import EventerLogo from '../../../Image/EventerLoginLogo.png';

export default function EventerLoginPage() {


  return (
    <Container>
        <BrowserViewWrapper>
          <LogoView>
            <Logo src={EventerLogo} />
            {/* <Text fontFamily='NotoSansKR-Bold' fontSize={50} color='#FFFFFF' cursor='default'>링코 관리자</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={20} color='#FFFFFF' cursor='default'>쉽고 간편한 피트니스 회원 관리 프로그램</Text> */}
          </LogoView>
          <InfoView/>
        </BrowserViewWrapper>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;

  justify-content: center;
  // align-items: flex-start;
  align-items: center;

  top: 0;
  left: 0;

  width: 100vw;
  // min-width: 1194px;
  height: 100vh;

  // overflow: scroll;
  // gap: 132px;

  background: linear-gradient(180deg, #429C63 0%, #000000 100%);
`;

const LogoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 480px;
  height: 604px;

  gap: 4px;

  border-radius: 50px;
  background-color: #000000;
`;

const Logo = styled.img`
  width: 456px;
  height: 456px;
`;

const BrowserViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 132px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;