import React, { useState } from "react";
import styled from "styled-components";

import OptionIcon from '../../../Image/OptionIcon.png';

export default function SouvenirSalesBox(props) {
  const { sales, approvalDate } = props;

  const [isHover, setIsHover] = useState(false);

  // 선택 옵션 텍스트 결정 //
  function decideSouvenirOptionName(data) {
    if (data.souvenirOptionName === undefined || data.souvenirOptionName === '') return '없음';
    else return data.souvenirOptionName;
  }

  return (
    <TotalSalesWrapper>
        <SalesColumnWrapper>
          <SalesTextWrapper style={{ marginLeft: '20px' }} width={112}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>품목 구분</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={202}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>품목 이름</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={202}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>선택 옵션</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>사이즈</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>품목 금액</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>구매 개수</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>최종 금액</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>결제일</Text>
          </SalesTextWrapper>
        </SalesColumnWrapper>

        <SalesInfoBoxWrapper>
        {
          sales.map((data, index) => (
            <SalesInfoBox key={'SouvenirSales-' + index}>
              <SalesTextWrapper width={112}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.souvenirDivision === 'Common' ? '공통 기념품' : '추가 기념품'}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={202}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.itemName}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={202}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{decideSouvenirOptionName(data)}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={82}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.souvenirSizeName === '' ? '없음' : data.souvenirSizeName}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={82}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={82}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.count}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={82}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{data.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
              </SalesTextWrapper>
              <SalesTextWrapper width={82}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{approvalDate}</Text>
              </SalesTextWrapper>
              {/* <OptionBox isHover={isHover}>
                <OptionView src={OptionIcon} />
              </OptionBox> */}
            </SalesInfoBox>
          ))
        }
        </SalesInfoBoxWrapper>
      </TotalSalesWrapper>
  )
}

const TotalSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 24px;
`;

const SalesColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const SalesInfoBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const SalesInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 20px;

  width: calc(100% - 40px);
  height: 52px;

  gap: 8px;

  border-radius: 30px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const SalesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 21px;

  overflow: hidden;
`;

const OptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  // opacity: ${props => props.isHover ? 1 : 0};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const OptionView = styled.img`
  width: 21px;
  height: 18px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;