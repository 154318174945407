import React from "react";
import styled from "styled-components";

import TrashIcon from '../../../Image/WhiteTrashIcon.png';

export default function SelectOptionInput(props) {
  const { value, setValue, placeholder, index, onClickDelete } = props;

	function handleChange(e) {
    setValue(e.target.value, index);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <InputView>
      <DeleteButtonWrapper>
        <DeleteButton onClick={() => onClickDelete(index)}>
          <DeleteIcon src={TrashIcon} />
        </DeleteButton>
      </DeleteButtonWrapper>
      <InputWrapper>
        <Input
          placeholder={placeholder}
          value={value}
          type='text'
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur} />
      </InputWrapper>
    </InputView>
  )
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: calc(31.74% - 4px);
  height: 46px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  z-index: 2;

  border-radius: 0px 5px 0px 5px;
  background-color: #FF5A5A;

  :hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -6px;
  width: 100%;
`;

const Input = styled.input`
	font-family: NotoSansKR-Medium;
	font-size: 14px;
	line-height: 17px;

	width: 100%;
	height: 17px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
	color: #FFFFFF;

  text-align: center;

  border: none;
  background-color: #444447;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;