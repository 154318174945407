import { React } from 'react';
import styled from 'styled-components';

export default function Pagenation(props) {
  const { nowPage, totalPage, onClickNext, onClickPrev } = props;

  return (
    <TopLevelWrapper>
      <Button onClick={onClickPrev}>이전</Button>
      <Page>{nowPage + 1}/{totalPage}</Page>
      <Button onClick={onClickNext}>다음</Button>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  margin: 24px 0px;
`;

const Button = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
    color: #FFFFFF;
  }

  transition: all 0.3s ease-in-out;
`;

const Page = styled.p`
  font-family: NotoSansKR-Regular;
  font-size: 14px;

  color: #FFFFFF;

  margin: 0 10px 0 10px;
`;