import { React, useRef, forwardRef, useImperativeHandle, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import LoginSubject from './LoginSubject.js';
import LoginInfo from './LoginInfo.js';
import LoginBottomComponent from './LoginBottomComponent.js';
import FetchModule from '../../../Model/Network/FetchModule.js';

export default function InfoView(props) {
	const {} = props;

  const navigate = useNavigate();
  
	const LoginInfoRef = useRef();

	function onClickLoginButton() {
		var userId = LoginInfoRef.current.getUserId();
		var userPW = LoginInfoRef.current.getUserPw();
		var blankCount = 0;

		if (userId === '') {
			LoginInfoRef.current.isBlankId();
			blankCount++;
		}

		if (userPW === '') {
			LoginInfoRef.current.isBlankPw();
			blankCount++;
		}

		if (blankCount > 0) return; 

		const data = {
			loginId: userId,
			password: userPW
		}

		const fetchModule = new FetchModule();
		(
			async () => {
				const responseData = await fetchModule.postDataOfEventVer2('account/login', 'POST', data);

				if (responseData.status === 200) {
          // console.log(responseData);
					window.localStorage.setItem('Token', 'Bearer ' + responseData.data.accessToken);
					window.localStorage.setItem('RefreshToken', 'Bearer ' + responseData.data.refreshToken);
					// window.localStorage.setItem('StoreId', responseData.storeId);
					// window.localStorage.setItem('AccountId', userId);
					// window.sessionStorage.setItem('Token', "Bearer " + responseData.token);
					// window.sessionStorage.setItem('StoreId', responseData.storeId);
					// window.sessionStorage.setItem('AccountId', userId);
					navigate('/main');
				}
				else LoginInfoRef.current.failLogin();
			}
		)();
	}

	return (
		<TopLevelWrapper>
			<DisplayWrapper>
				<InfoBox>
					<InfoWrapper>
						<TopInfo>
							<LoginSubject/>
						</TopInfo>
						<LoginInfo ref={LoginInfoRef} onClickLoginButton={() => onClickLoginButton()}/> 
						<LoginBottomComponent onClickLoginButton={onClickLoginButton} />
					</InfoWrapper>
				</InfoBox>
			</DisplayWrapper>
		</TopLevelWrapper>
	)
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;

	border-radius: 50px;
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const DisplayWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const InfoBox = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: center;

	width: 582px;
	height: 604px;

	border-radius: 50px;
	background-color: #000000;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	width: 486px;
	height: 506px;
`;

const TopInfo = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;

	margin-top: 54px;
`;