import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import FetchModule from '../../../../Model/Network/FetchModule';
import EventBox from '../Model/EventBox';

export default function EventInfoPage() {

  const { t, i18n } = useTranslation();

  const changeLanguageToKo = () => i18n.changeLanguage('ko');
  const changeLanguageToEn = () => i18n.changeLanguage('en');

  const navigate = useNavigate();
  const params = useParams();

  const [organizerId, setOrganizerId] = useState(0);
  const [data, setData] = useState({});
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [titleFontSize, setTitleFontSize] = useState(30);
  const [eventTitleFontSize, setEventTitleFontSize] = useState(18);
  const [isFixed, setIsFixed] = useState(false);
  const [sectionY, setSectionY] = useState(0);
  const [profileSize, setProfileSize] = useState(0);
  const [buttonTopMargin, setButtonTopMargin] = useState(0);
  const [scrollDivision, setScrollDivision] = useState('info');
  const [language, setLanguage] = useState('English');
  const [merchantUid, setMerchantUid] = useState('');
  const [eventId, setEventId] = useState(0);
  const [availableModifyDate, setAvailableModifyDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(false);
  const [isAlreadyPayment, setIsAlreadyPayment] = useState(false);
  const [isClickedCancellation, setIsClickedCancellation] = useState(false);
  const [isViewCancellationInfoModal, setIsViewCancellationInfoModal] = useState(false);

  const [isAuthority, setIsAuthority] = useState(false);

  const [depositor, setDepositor] = useState('');
  const [bank, setBank] = useState('');
  const [accountNum, setAccountNum] = useState('');

  const [totalVideoWidth, setTotalVideoWidth] = useState(374);
  const [totalVideoHeight, setTotalVideoHeight] = useState(374);

  const TopLevelRef = useRef();
  const SectionRef = useRef();
  const InfoRef = useRef();
  const EventRef = useRef();
  const EventBoxRef = useRef();
  const ImageRef = useRef();

  // 이벤트 데이터 받아오기 //
  useEffect(() => {
    let organizerId = parseInt(params.organizerId);

    setOrganizerId(organizerId);
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/' + organizerId, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setData(responseData.data);

          window.localStorage.removeItem('EventData');
        }
      }
    )();
  }, [])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);

      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    let finalSize = windowSize.width * 0.9033;
    // let finalSize = windowSize.width * 0.60;
    let eventMargin = 0;

    if (data.tickets === undefined) eventMargin = 36;
    // else eventMargin = windowSize.height - (159 + (202 * data.tickets.length) + (24 * (data.tickets.length - 1)));
    else {
      eventMargin = windowSize.height - (159 + EventBoxRef.current.offsetHeight);
    }

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({
        pageName: t('대회 정보'),
        sectionY: parseInt(140 + finalSize),
        // sectionY: parseInt(finalSize),
        isAvailableGoback: true
      }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }

    setButtonTopMargin(eventMargin > 36 ? eventMargin : 36);
    setProfileSize(finalSize);
    setTitleFontSize(Math.round((20 * windowSize.width) / 414));
    setEventTitleFontSize(Math.round((17 * windowSize.width) / 414));
  }, [windowSize, data, EventBoxRef])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.9033;
    let totalVideoHeight = totalVideoWidth * 0.5614;

    let modalVideoWidth = windowSize.width;
    let modalVideoHeight = modalVideoWidth * 0.5603;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // Window Scroll Handler //
  function handleScroll(e) {
    if ((window.scrollY + 35) >= SectionRef.current.offsetTop && SectionRef.current.offsetTop !== 0) {
      setIsFixed(true);
      setSectionY(SectionRef.current.offsetTop);
    }
    else if ((window.scrollY + 35) < sectionY && SectionRef.current.offsetTop === 0) setIsFixed(false);
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if (((window.scrollY + 35) + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
  }

  // React Native에서 웹으로 보내는 데이터 handler //
  async function handleMassage(e) {

    // 언어 정보 받아오기 //
    let language = JSON.parse(e.data).language;
    // alert("language : " + language);
    if (language === 'Korea') {
      changeLanguageToKo();
      setLanguage(language);

      window.localStorage.setItem('Language', 'Korea');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: '대회 정보',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }
    else if (language === 'English') {
      changeLanguageToEn();
      setLanguage(language);

      window.localStorage.setItem('Language', 'English');

      try {
        window.ReactNativeWebView.postMessage(JSON.stringify({
          pageName: 'Event Infomation',
        }));
      }
      catch (e) {
        console.log("Web Message Post Error : " + e);
      }
    }

    let nowScrollY = JSON.parse(e.data).scrollY;
    let nowSectionY = JSON.parse(e.data).sectionY;
    let nowIsFixed = JSON.parse(e.data).isFixed;

    if (nowScrollY >= SectionRef.current.offsetTop && !nowIsFixed) {
      setIsFixed(true);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: true, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if (nowScrollY < nowSectionY && nowIsFixed) {
      setIsFixed(false);
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: false, sectionY: parseInt(SectionRef.current.offsetTop) }));
    }
    else if ((nowScrollY + SectionRef.current.clientHeight) >= EventRef.current.offsetTop) setScrollDivision('event');
    else if ((nowScrollY + SectionRef.current.clientHeight) < EventRef.current.offsetTop) setScrollDivision('info');
    else {
      window.ReactNativeWebView.postMessage(JSON.stringify({ isFixed: nowIsFixed, scrollY: nowScrollY, sectionY: nowSectionY }));
    }
  }

  // 대회 신청기간 결정 //
  function decideApplicationDate() {
    let startDate = moment(data.applicationStartDate).format('YY년 MM월 DD일');
    let startTime = moment(data.applicationStartDate).format('HH:mm');
    let endDate = moment(data.applicationEndDate).format('YY년 MM월 DD일');
    let endTime = moment(data.applicationEndDate).format('HH:mm');

    return startDate + '(' + startTime + ')부터 ' + endDate + '(' + endTime + ')까지';
  }

  // 대회내용 버튼 클릭시 //
  function onClickInfoButton() {
    setScrollDivision('info');

    if (SectionRef.current.offsetTop === 0) window.scrollTo({ top: (InfoRef.current.offsetTop - SectionRef.current.clientHeight + 12), behavior: 'smooth' });
    else window.scrollTo({ top: (SectionRef.current.offsetTop - SectionRef.current.clientHeight + 24), behavior: 'smooth' });
  }

  // 참가 종목 버튼 클릭시 //
  function onClickEventButton() {
    setScrollDivision('event');

    window.scrollTo({ top: EventRef.current.offsetTop - SectionRef.current.clientHeight, behavior: 'smooth' });
  }

  // 참가신청 바로가기 버튼 클릭시 //
  function onClickAppication() {
    // navigate('/agree/v2/' + organizerId);
    // if (data.isAdmin) {
    //   navigate('/agree/v2/' + organizerId);
    //   return;
    // }
    // else 
    if (data.applicationEndDate < moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('참가신청 가능 기간이 지났습니다'));
      return;
    }
    else if (data.applicationStartDate > moment().format('YYYY-MM-DDTHH:mm:ss')) {
      alert(t('참가신청 가능 기간이 아닙니다'));
      return;
    }
    else {
      navigate('/agree/v2/' + organizerId);
      return;
    }
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <ComponentWrapper>
        <ImageWrapper>
          <Image src={data.mainPicture} width={profileSize} height={profileSize} />
        </ImageWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={titleFontSize} color='#4F4F4F'>{data.title}</Text>
        </TitleWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={SectionRef} isFixed={isFixed} style={{ backgroundColor: '#F9F9F9' }}>
        <SectionTotalWrapper>
          <SectionWrapper onClick={onClickInfoButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('대회 내용')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'info'} />
          </SectionWrapper>
          <SectionWrapper onClick={onClickEventButton}>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('참가 종목')}</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'event'} />
          </SectionWrapper>
        </SectionTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={InfoRef} style={{ marginTop: `0px` }}>
        <InfoTotalWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 이름')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.title}</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('이벤트 내용')}</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.content}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회 기간')}</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.period}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('신청 기간')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{decideApplicationDate()}</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('대회 장소')}</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.place}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('주관사')}</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.subjectivity}</Text>
          </InfoWrapper>
          {
            data.sponsor !== '' &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('후원사')}</Text>
              <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{data.sponsor}</PreText>
            </InfoWrapper>
          }
          {
            data.souvenirPicture !== '' &&
            <InfoWrapper style={{ marginTop: `12px`, marginBottom: '8px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('공통 기념품')}</Text>
            </InfoWrapper>
          }
        </InfoTotalWrapper>
        {
          data.souvenirPicture !== '' &&
          <InfoWrapper ref={ImageRef}>
            <TShirtsSize src={data.souvenirPicture} />
          </InfoWrapper>
        }
      </ComponentWrapper>
      <ComponentWrapper ref={EventRef} style={{ paddingTop: `36px` }}>
        {
          data.tickets !== undefined &&
          <EventWrapper ref={EventBoxRef}>
            {
              data.tickets.map((data, index) => (
                <EventBox key={index} data={data} index={index} language={language} eventTitleFontSize={eventTitleFontSize} />
              ))
            }
          </EventWrapper>
        }
        <Button marginTop={buttonTopMargin} onClick={onClickAppication}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>{t('참가 신청 바로 가기')}</Text>
        </Button>
      </ComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
  height: 100%;
  // height: 100vh;

  background-color: #F9F9F9;
`;

const ComponentWrapper = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'static'};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #F9F9F9;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  
  margin-top: 30px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  margin-top: 12px;
`;

const SectionTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 18px;

  width: 100%;

  :hover {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 35px;
`;

const SectionTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 33px;
`;

const SectionDivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3px;

  background-color: ${props => props.isClicked ? '#FF8B48' : '#D9D9D9'};

  transition: all 0.3s ease-in-out;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PreText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-line;

  :hover {
    cursor: default;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.marginTop}px;
  
  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TShirtsSize = styled.img`
  width: 100%;

  object-fit: contain;
`;
