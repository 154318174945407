import React from "react";
import styled from "styled-components";

import NameArrow from '../../../Image/NameArrow.png';

export default function EventBox(props) {
  const { data, index, eventTitleFontSize, isUsePriceOption } = props;

  // 참가비 글자화 //
  function numberToKorean(number){
    if (number === '') return '무료';
    else return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ' KRW';
  }

  return (
    <TopLevelWrapper isFirst={index === 0}>
      <EventInnerWrapper>
        <EventNameWrapper>
          <EventNameImage src={NameArrow} style={{ marginRight: `6px` }}/>
          <Text fontFamily='NotoSansKR-Black' fontSize={eventTitleFontSize} color='#333333' cursor='default'>{data.name}</Text>
        </EventNameWrapper>
        <EventInfoWrapper style={{ marginTop: `24px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>참가 자격</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333' cursor='default'>{data.entryQualification}</Text>
        </EventInfoWrapper>
        {
          data.additionalInstructions !== '' &&
          <EventInfoWrapper style={{ justifyContent: 'flex-end', marginTop: '5px' }}>
            <PreText style={{ textAlign: 'right' }} fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'} cursor='default'>{data.additionalInstructions}</PreText>
          </EventInfoWrapper>
        }
        {
          data.priceOptions.map((priceInfo, priceIndex) => (
            <EventInfoWrapper key={'UserViewTicketPrice-' + priceIndex} style={{ marginTop: `4px` }}>
              { 
                priceIndex === 0 ?
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>참가비</Text>
                  :
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'></Text>
              }
              <EventPriceTextWrapper>
                { isUsePriceOption && <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='default'>{priceInfo.name}</Text> }
                { isUsePriceOption && <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='default'>{priceInfo.appliedDate.format('M월 D일')}</Text> }
                { isUsePriceOption && <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='default'>:</Text> }
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='default'>{numberToKorean(priceInfo.price)}</Text>
              </EventPriceTextWrapper>
            </EventInfoWrapper>
          ))
        }
        <EventInfoWrapper style={{ marginTop: `4px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>대회 장소</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333' cursor='default'>{data.place === '' ? 'Online' : data.place}</Text>
        </EventInfoWrapper>
      </EventInnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 15px 0px;

  width: 100%;
  // height: 178px;

  margin-top: ${props => props.isFirst ? 0 : 24}px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const EventInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.24%;
`;

const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;

const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const EventPriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space: pre-line;

  :hover {
    cursor: ${props => props.cursor};
  }
`;