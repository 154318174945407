import React from "react";
import styled from "styled-components";

// import EmptyLogo from '../../../Image/EmptyLogo.png';
import EmptyLogo from '../../../Image/EventerEmptyLogo.png';

export default function TermsPage(props) {

  return (
    <TopLevelWrapper>
      <LogoWrapper>
        <LogoImage src={EmptyLogo}/>
        <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#454549' cursor='default'>준비중인 페이지 입니다</Text>
      </LogoWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 240px;

  gap: 8px;
`;

const LogoImage = styled.img`
  width: 150px;
  height: 150px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;