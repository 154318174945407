import React from "react";
import styled, { keyframes } from "styled-components";
import Lottie from 'lottie-react-web';

import LoadingAnimation from '../../../Animation/LoadingAnimation.json';

export default function ListDownloadLoading(props) {
  return (
    <TopLevelWrapper>
      <LottieWrapper>
        <LottieBox>
          <Lottie options={{ animationData: LoadingAnimation }} />
        </LottieBox>
        <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' cursor='default'>명단 추출 진행중...</Text>
      </LottieWrapper>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  z-index: 1000;

  background-color: rgba(0, 0, 0, 0.2);

  animation-name: ${startAnimation};
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;
