import React, { useState } from "react";
import styled from "styled-components";
import EventTitleInput from "../Component/EventTitleInput";
import LogoInput from "../Component/LogoInput";
import EventTextArea from "../Component/EventTextArea";
import DateSelectView from "./DateSelectView";
import moment from "moment";
import SouvenirInput from "../Component/SouvenirInput";
import TicketView from "./TicketView";

export default function EditView(props) {
  const {
    mainPictureSize,
    bannerPictureWidth,
    bannerPictureHeight,
    commonSouvenirWidth,
    commonSouvenirHeight,
    plusWidth,
    plusHeight,

    title, 
    setTitle,
    mainPicture, 
    setMainPicture,
    bannerPicture, 
    setBannerPicture,
    content, 
    setContent, 
    period, 
    setPeriod,
    startDate, 
    setStartDate,
    startTime, 
    setStartTime,
    endDate, 
    setEndDate,
    endTime, 
    setEndTime,
    place, 
    setPlace,
    subjectivity, 
    setSubjectivity,
    sponsor,
    setSponsor,
    support,
    setSupport,
    souvenirPicture, 
    setSouvenirPicture,
    tickets, 
    setTickets
  } = props;

  const newTicketTemplate = {
    name: '',
    entryQualification: '',
    isUseSelectOption: false,
    selectOptions: [],
    isUseParticipantLimit: false,
    participantLimit: 0,
    teamMemberCount: 0,
    additionalInstructions: '',
    isUsePriceOption: false,
    priceOptions: [{
      id: 0,
      appliedDate: moment(),
      name: '',
      price: 0
    }],
    place: '',
    ticketThumbnail: ''
  };

  const newSelectOption = {
    id: 0,
    name: ''
  }

  const newPriceOption = {
    id: 0,
    appliedDate: moment(),
    name: '',
    price: 0
  }

  // 이벤트 티켓 추가 클릭시 //
  function onClickAdditionTicket() {
    let newTickets = tickets.slice();

    newTickets.push(newTicketTemplate);

    setTickets(newTickets);
  }

  // 티켓 이름 수정시 //
  function onChangeAppliedTicketName(data, index) {
    let newTickets = tickets.slice();

    newTickets[index].name = data;

    setTickets(newTickets);
  }

  // 티켓 참가자격 수정시 //
  function onChangeAppliedEntryQualification(data, index) {
    let newTickets = tickets.slice();

    newTickets[index].entryQualification = data;

    setTickets(newTickets);
  }

  // 티켓 선택 옵션 사용여부 수정시 //
  function onChangeAppliedIsUseSelectOption(index) {
    let newTickets = tickets.slice();

    newTickets[index].isUseSelectOption = !newTickets[index].isUseSelectOption;

    setTickets(newTickets);
  }

  // 티켓 선택 옵션 추가시 //
  function onChangeAppliedAddSelectOption(index) {
    let newTickets = tickets.slice();

    newTickets[index].selectOptions.push(newSelectOption);

    setTickets(newTickets);
  }

  // 티켓 선택 옵션 삭제시 //
  function onChangeAppliedDeleteSelectOption(index, selectOptionIndex) {
    let newTickets = tickets.slice();
    let newSelectOptions = newTickets[index].selectOptions.slice();

    newSelectOptions.splice(selectOptionIndex, 1);
    newTickets[index].selectOptions = newSelectOptions;

    setTickets(newTickets);
  }

  // 티켓 선택 옵션 이름 변경시 //
  function onChangeAppliedSelectOptionName(data, index, selectOptionIndex) {
    let newTickets = tickets.slice();
    let newSelectOptions = newTickets[index].selectOptions.slice();
    
    newSelectOptions[selectOptionIndex].name = data;
    newTickets[index].selectOptions = newSelectOptions;

    setTickets(newTickets);
  }

  // 티켓 참가인원 제한 사용여부 변경시 //
  function onChangeAppliedIsUseParticipantLimit(index) {
    let newTickets = tickets.slice();

    newTickets[index].isUseParticipantLimit = !newTickets[index].isUseParticipantLimit;

    setTickets(newTickets);
  }

  // 티켓 참가인원 제한 변경시 //
  function onChangeAppliedParticipantLimit(data, index) {
    let newTickets = tickets.slice();

    newTickets[index].participantLimit = data

    setTickets(newTickets);
  }

  // 티켓 팀원 수 변경시 //
  function onChangeAppliedTeamMemberCount(data, index) {
    let newTickets = tickets.slice();

    newTickets[index].teamMemberCount = data

    setTickets(newTickets);
  }

  // 티켓 참가 추가지침 변경시//
  function onChangeAppliedAdditionalInstaructions(data, index) {
    let newTickets = tickets.slice();

    newTickets[index].additionalInstructions = data

    setTickets(newTickets);
  }
  
  // 티켓 가격 옵션 사용여부 변경시 //
  function onChangeAppliedIsUsePriceOption(index) {
    let newTickets = tickets.slice();

    newTickets[index].isUsePriceOption = !newTickets[index].isUsePriceOption;

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 추가시 //
  function onChangeAppliedAddPriceOption(index) {
    let newTickets = tickets.slice();

    newTickets[index].priceOptions.push(newPriceOption);

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 삭제시 //
  function onChangeAppliedDeletePriceOption(index, priceOptionIndex) {
    let newTickets = tickets.slice();
    let newPriceOptions = newTickets[index].priceOptions.slice();

    newPriceOptions.splice(priceOptionIndex, 1);
    newTickets[index].priceOptions = newPriceOptions;

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 적용날짜 변경시 //
  function onChangeAppliedDeletePriceOption(index, priceOptionIndex) {
    let newTickets = tickets.slice();
    let newPriceOptions = newTickets[index].priceOptions.slice();
    
    if (newPriceOptions.length === 1) alert('기본 가격은 지울 수 없습니다');
    else {
      newPriceOptions.splice(priceOptionIndex, 1);
      newTickets[index].priceOptions = newPriceOptions;
    }

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 적용날짜 변경시 //
  function onChangeAppliedPriceOptionAppliedDate(data, index, priceOptionIndex) {
    let newTickets = tickets.slice();
    let newPriceOptions = newTickets[index].priceOptions.slice();
    
    newPriceOptions[priceOptionIndex].appliedDate = data;
    newTickets[index].priceOptions = newPriceOptions;

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 이름 변경시 //
  function onChangeAppliedPriceOptionName(data, index, priceOptionIndex) {
    let newTickets = tickets.slice();
    let newPriceOptions = newTickets[index].priceOptions.slice();
    
    newPriceOptions[priceOptionIndex].name = data;
    newTickets[index].priceOptions = newPriceOptions;

    setTickets(newTickets);
  }

  // 티켓 가격 옵션 가격 변경시 //
  function onChangeAppliedPriceOptionPrice(data, index, priceOptionIndex) {
    let newTickets = tickets.slice();
    let newPriceOptions = newTickets[index].priceOptions.slice();
    
    newPriceOptions[priceOptionIndex].price = data;
    newTickets[index].priceOptions = newPriceOptions;

    setTickets(newTickets);
  }

  // 티켓 대회 장소 변경시 //
  function onChangeAppliedPlace(data, index) {
    let newTickets = tickets.slice();
    
    newTickets[index].place = data;

    setTickets(newTickets);
  }

  // 티켓 가격 썸네일 변경시 //
  function onChangeAppliedTicketThumbnail(data, index) {
    let newTickets = tickets.slice();
    
    newTickets[index].ticketThumbnail = data;

    setTickets(newTickets);
  }

  // 티켓 삭제 클릭시 //
  function onClickAppliedDeleteTicket(index) {
    let newTickets = tickets.slice();
    
    if (newTickets.length > 1) {
      newTickets.splice(index, 1);
      setTickets(newTickets);
    }
    else setTickets([]);
  }

  return (
    <TopLevelWrapper>
      <ViewBox>
        <ViewInnerBox>
          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>이벤트 이름</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>이벤트의 브랜드를 강활할 수 있는 직관적인 이름을 선택하세요</Text>
            </TitleWrapper>
            <EventTitleInput value={title} setValue={setTitle} placeholder='이벤트 이름을 입력해주세요'/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <TitleInnerWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>이벤트 홍보 사진</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FFFFFF' cursor='default'>(정방향 1080x1080, 가로형 1920x1080)</Text>
              </TitleInnerWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>이벤트의 분위기를 한눈에 전달할 수 있는 이미지를 업로드하세요</Text>
            </TitleWrapper>
            <LogoInput 
              enabled={true}

              mainPictureSize={mainPictureSize}
              bannerPictureWidth={bannerPictureWidth}
              bannerPictureHeight={bannerPictureHeight}
              plusWidth={plusWidth}
              plusHeight={plusHeight}

              mainPicture={mainPicture}
              setMainPicture={setMainPicture}
              bannerPicture={bannerPicture}
              setBannerPicture={setBannerPicture}/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>이벤트 내용</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>이벤트의 특장점과 차별점을 강조하여 더 많은 관심을 유도하세요</Text>
            </TitleWrapper>
            <EventTextArea value={content} setValue={setContent} placeholder=''/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>대회 기간</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>예선, 본선, 결승 등 세부 일정이 있는 경우 상세히 기재하세요</Text>
            </TitleWrapper>
            <EventTextArea value={period} setValue={setPeriod} placeholder=''/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>신청 기간 [설정에서 변경 가능]</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>신청 마감 이후에는 접수가 불가능하니 충분한 기간을 설정하세요</Text>
            </TitleWrapper>
            <DateSelectView 
              startDate={startDate} 
              setStartDate={setStartDate}
              startTime={startTime}
              setStartTime={setStartTime}
              endDate={endDate}
              setEndDate={setEndDate}
              endTime={endTime}
              setEndTime={setEndTime}/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>대회 장소</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>참가자와 관중이 쉽게 찾아올 수 있도록 주소를 상세히 기재하세요</Text>
            </TitleWrapper>
            <EventTextArea value={place} setValue={setPlace} placeholder=''/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>대회 주관사</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>대회를 주최·운영하는 기관, 단체 또는 기업의 이름을 입력하세요</Text>
            </TitleWrapper>
            <EventTextArea value={subjectivity} setValue={setSubjectivity} placeholder=''/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>대회 후원사</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>대회를 지원하는 기업, 단체 또는 기관의 이름을 입력하세요</Text>
            </TitleWrapper>
            <EventTextArea value={sponsor} setValue={setSponsor} placeholder=''/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>공통 기념품</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>모든 참가자에게 제공되는 기념품 목록을 확인하세요</Text>
            </TitleWrapper>
            <SouvenirInput 
              enabled={true}

              commonSouvenirWidth={commonSouvenirWidth}
              commonSouvenirHeight={commonSouvenirHeight}
              plusWidth={plusWidth}
              plusHeight={plusHeight}

              souvenirPicture={souvenirPicture} 
              setSouvenirPicture={setSouvenirPicture}/>
          </ComponentWrapper>

          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>이벤트 티켓 내용</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>티켓 이름, 참가 자격, 참가 지침, 참가비, 대회 장소, 티켓별 대표 이미지를 설정하세요</Text>
            </TitleWrapper>
            <TicketViewWrapper>
              {
                tickets.map((data, index) => (
                  <TicketView 
                    key={'TicketView-' + index}
                    data={data}
                    index={index}

                    enabled={true}

                    pictureSize={mainPictureSize}
                    plusWidth={plusWidth}
                    plusHeight={plusHeight}

                    onChangeAppliedTicketName={onChangeAppliedTicketName}
                    onChangeAppliedEntryQualification={onChangeAppliedEntryQualification}
                    onChangeAppliedIsUseSelectOption={onChangeAppliedIsUseSelectOption}
                    onChangeAppliedAddSelectOption={onChangeAppliedAddSelectOption}
                    onChangeAppliedDeleteSelectOption={onChangeAppliedDeleteSelectOption}
                    onChangeAppliedSelectOptionName={onChangeAppliedSelectOptionName}
                    onChangeAppliedIsUseParticipantLimit={onChangeAppliedIsUseParticipantLimit}
                    onChangeAppliedParticipantLimit={onChangeAppliedParticipantLimit}
                    onChangeAppliedTeamMemberCount={onChangeAppliedTeamMemberCount}
                    onChangeAppliedAdditionalInstaructions={onChangeAppliedAdditionalInstaructions}
                    onChangeAppliedIsUsePriceOption={onChangeAppliedIsUsePriceOption}
                    onChangeAppliedAddPriceOption={onChangeAppliedAddPriceOption}
                    onChangeAppliedDeletePriceOption={onChangeAppliedDeletePriceOption}
                    onChangeAppliedPriceOptionAppliedDate={onChangeAppliedPriceOptionAppliedDate}
                    onChangeAppliedPriceOptionName={onChangeAppliedPriceOptionName}
                    onChangeAppliedPriceOptionPrice={onChangeAppliedPriceOptionPrice}
                    onChangeAppliedPlace={onChangeAppliedPlace}
                    onChangeAppliedTicketThumbnail={onChangeAppliedTicketThumbnail}
                    onClickAppliedDeleteTicket={onClickAppliedDeleteTicket}/>
                ))
              }
            </TicketViewWrapper>
          </ComponentWrapper>
            <TicketAdditionButton onClick={onClickAdditionTicket}>
              <PlusWrapper>
                <PlusStick width={24} height={3} />
                <PlusStick width={3} height={24} />
              </PlusWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={20} color='#969696' cursor='pointer'>이벤트 티켓 추가</Text>
            </TicketAdditionButton>
        </ViewInnerBox>
      </ViewBox>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper =  styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;

  width: 98%;

  border-radius: 20px;
  background-color: #17171B;
`;

const ViewInnerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 93%;

  gap: 34px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const TitleInnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const TicketViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 24px;
`;

const TicketAdditionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 98px;

  gap: 12px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

const PlusStick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #969696;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;