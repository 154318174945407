import React from 'react';
import styled from 'styled-components';

export default function DataColumnBox(props) {
  const { 
    approvalDateSort,
    setApprovalDateSort
  } = props;

  // 등록일 정렬버튼 클릭시 //
  function onClickApprovalDateSort() {
    if (approvalDateSort === 'asc') setApprovalDateSort('desc');
    else setApprovalDateSort('asc')
  }

  return (
    <TopLevelWrapper>
      <Text style={{marginRight: '78px', marginLeft: '20px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>팀 이름</Text>
      <SortingWrapper>
      <Text style={{marginRight: '205px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>등록 티켓</Text>
      </SortingWrapper>
      <SortingWrapper>
      <Text style={{marginRight: '296px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>팀원 구성</Text>
      </SortingWrapper>
      <SortingWrapper>
      <Text style={{marginRight: '154px'}} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>소속</Text>
      </SortingWrapper>
      <SortingWrapper>
        <TotalSortingButton onClick={onClickApprovalDateSort}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>등록일</Text>
          <SortingButton>
            <SortUpIcon isActivated={approvalDateSort === 'asc'}/>
            <SortDownIcon isActivated={approvalDateSort === 'desc'}/>
          </SortingButton>
        </TotalSortingButton>
      </SortingWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  
  justify-content: flex-start;
  align-items: center;

  margin-top: 18px;

  width: 100%;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;


const TotalSortingButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const SortingButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20px;
  height: 20px;

  gap: 2px;
`;

const SortUpIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 5px solid ${props => props.isActivated ? `#FFFFFF` : `#808080`};

  transition: all 0.3s ease-in-out;
`;

const SortDownIcon = styled.div`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 5px solid ${props => props.isActivated ? `#FFFFFF` : `#808080`};

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;