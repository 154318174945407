import React from "react";
import styled from "styled-components";

export default function EventTitleInput(props) {
  const { value, setValue, placeholder } = props;

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <InputView>
      <Input
        placeholder={placeholder}
        value={value}
        type='text'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </InputView>
  )
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 15px 15px;

  width: calc(100% - 34px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;
`;

const Input = styled.input`
	font-family: NotoSansKR-Black;
	font-size: 21px;
	line-height: 31px;

	width: 100%;
	height: 31px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
	color: #FFFFFF;

  border: none;
  background-color: #2F2F32;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;