import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// import PoweredImage from '../../Image/PoweredByLink.png';
import FetchModule from '../../../../Model/Network/FetchModule';

import PoweredImage from '../../../../Image/PoweredByLink5.png';
import DownloadIcon from '../../../../Image/DownloadIcon.png';

export default function CompletePage() {
  
  const { t } = useTranslation();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  const [data, setData] = useState({
    teamName: '',
    memberNames: '',
    members: []
  });
  const [individualMemberName, setIndividualMemberName] = useState('');
  const [isTeam, setIsTeam] = useState(false);
  const [eventProfile, setEventProfile] = useState('');
  const [eventText, setEventText] = useState('');
  const [title, setTitle] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [totalVideoWidth, setTotalVideoWidth] = useState(366);
  const [totalVideoHeight, setTotalVideoHeight] = useState(456);
  const [startModifyDate, setStartModifyDate] = useState(moment());
  const [endModifyDate, setEndModifyDate] = useState(moment());
  const [isAvailableModifyAuth, setIsAvailableModifyAuth] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [handleMassage])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    // alert(e.data);
    if (responseData.goBack) navigate(-1);
  }

  // 페이지 이름 변경내역 업로드 및 받아온 데이터 정리 //
  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('이벤트 등록 완료'), isAvailableGoback: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }, [])

  // 화면 크기 추출후 총 비디오 박스 크기 계산 //
  useEffect(() => {
    // var finalSize = windowSize.width * 0.3208;
    let totalVideoWidth = windowSize.width * 0.884;
    let totalVideoHeight = totalVideoWidth * 1.245;

    setTotalVideoWidth(totalVideoWidth);
    setTotalVideoHeight(totalVideoHeight);
  }, [windowSize])

  // 데이터 정리 //
  useEffect(() => {
    let eventId = params.eventId;
    let token = params.token;

    if (token !== undefined) window.localStorage.setItem('Token', 'Bearer ' + token);
    // alert(token);
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('event/application_state/' + eventId, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          if (responseData.data.isTeam) {
            let newList = JSON.parse(JSON.stringify(responseData.data.members));

            // 팀장 앞으로 빼기 //
            let tmp = undefined;
            let flag = false;
  
            for (let i = 0; i < newList.length; i++) {
              if (newList[i].division === 'representative') {
                tmp = newList[i];
                newList.splice(i, 1);
                newList = [tmp].concat(newList);
                break;
              }
            }
            // console.log(newList);
            // if (flag) {
            //   newList.unshift(tmp);
            // }
  
            responseData.data.members = newList;
  
            setIsTeam(responseData.data.isTeam);
            setData(responseData.data);
            setEventProfile(responseData.data.eventProfile);
            setEventText(responseData.data.eventText);
            setTitle(responseData.data.title);
            setEventTitle(responseData.data.eventTitle);
            setIsAvailableModifyAuth(responseData.data.isAvailableModifyAuth);
            setStartModifyDate(responseData.data.modifyStartDate);
            setEndModifyDate(responseData.data.modifyEndDate);
          }
          else {
            let members = [];

            members.push({})

            setIsTeam(responseData.data.isTeam);
            setData(responseData.data);
            setEventProfile(responseData.data.eventProfile);
            setEventText(responseData.data.eventText);
            setTitle(responseData.data.title);
            setEventTitle(responseData.data.eventTitle);
            setIsAvailableModifyAuth(responseData.data.isAvailableModifyAuth);
            setStartModifyDate(responseData.data.modifyStartDate);
            setEndModifyDate(responseData.data.modifyEndDate);
            setIndividualMemberName(responseData.data.name);
          }
        }
        else if (responseData.status === 201) {
          navigate('/info/v2/' + 36);
        }
      }
    )();
  }, [])

  // 메인화면으로 클릭시 //
  function onClickGoHome() {
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  // 수정하기 클릭시 //
  function onClickModify() {
    if (!isAvailableModifyAuth) alert(t('정보수정은 팀장만 가능합니다'));
    else if (moment().isAfter(moment(endModifyDate))) alert(t('정보 수정 가능기간이 아닙니다'));
    else navigate('/modify/v2/' + data.eventId + '/' + data.selectOptionId);
  }

  // 참가접수 이벤트 사진 다운받기 버튼 클릭시 //
  function onClickDownloadSuff() {

    // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://www.suffestival.com/suff-event2' }));
    window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://static.wixstatic.com/media/42d6b9_cf7dc284d03449ea94c0c6cee3ff2fae~mv2.jpg' }));
  }
  
  // 참가접수 이벤트 사진 다운받기 버튼 클릭시 //
  function onClickDownloadHyrox() {

    // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://www.suffestival.com/suff-event2' }));
    window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://drive.google.com/file/d/1IKMQW7yc1RJzJEgBzWq4c4-45oOH2xHc/view?usp=drive_link' }));
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{title}</Text>
          <Text style={{ marginTop: '10px' }} fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>{t('이벤트 등록 신청 완료')}</Text>
        </TitleWrapper>
        {
          (params.eventId === '139' || params.eventId === '140' || params.eventId === '141') ?
            // <ImageWrapper width={totalVideoWidth} height={totalVideoWidth} >
              <Image src='https://linko-picture.s3.ap-northeast-2.amazonaws.com/event/hyrox/%E1%84%92%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%85%E1%85%A1%E1%86%A8%E1%84%89%E1%85%B3%E1%84%92%E1%85%B5%E1%84%90%E1%85%B3.jpeg' />
            // </ImageWrapper>
            :
            <ImageWrapper width={totalVideoWidth} height={totalVideoWidth} >
              <Image src={eventProfile} />
            </ImageWrapper>
        }
        <PoweredImageWrapper>
          <PoweredImg src={PoweredImage} />
        </PoweredImageWrapper>
        <TextComponentWrapper>
          <TextWrapper style={{ marginTop: '16px', width: '100%' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{'<참가접수 이벤트>'}</Text>
            {/* SUFF 전용 */}
            {
              (params.eventId === '87' || params.eventId === '88' || params.eventId === '89') &&
              <SuffButton onClick={onClickDownloadSuff}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{t('참가접수 이벤트 사진 다운 받기')}</Text>
                <DownloadImage src={DownloadIcon}/>
              </SuffButton>
            }
            {/* SUFF 전용 */}
            {/* HYROX 전용 */}
            {
              (params.eventId === '139' || params.eventId === '140' || params.eventId === '141') &&
              <SuffButton onClick={onClickDownloadHyrox}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF'>{t('참가접수 이벤트 사진 다운 받기')}</Text>
                <DownloadImage src={DownloadIcon}/>
              </SuffButton>
            }
            {/* HYROX 전용 */}
            <PreText style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{eventText}</PreText>
          </TextWrapper>
          <LayerWrapper style={{ marginTop: '16px' }}>
            <TextWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{t('종목')}</Text>
              <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{title + ' - ' + eventTitle}</Text>
            </TextWrapper>
          </LayerWrapper>
          {
            isTeam &&
            <TextWrapper style={{ marginTop: '16px', width: '35%' }}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{t('팀이름')}</Text>
                <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{data.teamName}</Text>
            </TextWrapper>
          }
          <TextWrapper style={{ marginTop: '16px', width: '100%' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{t('참가자')}</Text>
            <Text style={{ marginTop: '4px' }} fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{isTeam ? data.memberNames : individualMemberName}</Text>
          </TextWrapper>
        </TextComponentWrapper>
      </TotalWrapper>
      <MainButtonWrapper>
        <ModifyButton onClick={onClickModify}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('정보 수정')}</Text>
        </ModifyButton>
      </MainButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100vh;

  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 22px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // width: 100%;
  width: 90.33%;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const TextComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 88.4%;
`;

const SuffButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;
  margin-top: 10px;

  width: calc(100% - 20px);
  height: 35px;

  border-radius: 5px;
  background-color: #6DD49E;
`;

const DownloadImage = styled.img`
  width: 21px;
  height: 21px;
`;

const MemberTotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 50%;
  width: 45%;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 0px 24px 0px;

  width: 100%;
  height: 1px;

  background-color: #E0E0E0;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PoweredImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 4px;

  width: 88%;
`;

const PoweredImg = styled.img`
  width: 140px;
  height: 34px;

  // object
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const MainButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  // padding-bottom: 15px;
  margin-top: 54px;

  width: 100%;
  // height: 68px;

  background-color: #FFFFFF;
`;

const MainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #FF8B48;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space: break-spaces;

  :hover {
    cursor: default;
  }
`;