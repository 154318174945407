import React, { useRef, useState } from "react";
import styled from "styled-components";

export default function SouvenirInput(props) {
  const { 
    enabled,
    souvenirPictureWidth,
    souvenirPictureHeight, 
    plusWidth, 
    plusHeight, 
    souvenirPicture, 
    setSouvenirPicture 
  } = props;

  const [souvenirImgLocalPath, setSouvenirImgLocalPath] = useState('');

  const TotalRef = useRef(null);
  const HiddenFileInput = useRef(null);
  const CanvasRef = useRef();

  const handleClick = e => {
    if (!enabled) return;

    HiddenFileInput.current.click();
  };

  const handleChange = e => {
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      // setImgLocalPath(e.target.value);
      var tmp = e.target.value.split('\\');

      setSouvenirImgLocalPath(tmp[tmp.length - 1]);

      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 1080;
          let resizeHeight = 1080;

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {

            canvas.width = resizeWidth;
            canvas.height = resizeHeight;

            image.src = base64.toString();

            // 사진 크기 조정후 Canvas에 그리기 //
            image.onload = function () {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
            }

            // 크기 조절된 사진 데이터 정리하기 //
            canvas.toBlob(function (blob) {
              const reader = new FileReader();
              // Canvas에 다 그려졌을때 Event //
              reader.onload = function (e) {
                // setPicture(canvas.toDataURL())
                // console.log(canvas.toDataURL());
                setSouvenirPicture(canvas.toDataURL());
              }
              reader.readAsDataURL(blob);
            })
          }
          else {
            alert('사용할 수 없는 파일입니다');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };


  return (
    <InputView ref={TotalRef}>
      <canvas ref={CanvasRef} style={{ display: 'none' }} />
      <input
        type="file"
        ref={HiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }} />
      <PictureButton 
        width={souvenirPictureWidth} 
        height={souvenirPictureHeight} 
        onClick={handleClick}>
        {
          souvenirPicture !== '' ?
            <ImageView 
              src={souvenirPicture}
              width={souvenirPictureWidth} 
              height={souvenirPictureHeight}   />
            :
            <PlusWrapper>
              <PlusStick width={plusWidth} height={plusHeight} />
              <PlusStick width={plusHeight} height={plusWidth} />
            </PlusWrapper>
        }
      </PictureButton>
    </InputView>
  )
}

const InputView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const PictureButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  
  min-width: ${props => props.width}px;
  min-height: ${props => props.height}px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ImageView = styled.img`
  width: ${props => props.width}px;
  // height: ${props => props.height}px;
  height: auto;
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
`;

const PlusStick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #969696;
`;

