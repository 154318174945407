import React from "react";
import styled from "styled-components";
import SizeOptionInput from "../Component/SizeOptionInput";

export default function SizeOptionView(props) {
  const {
    enabled,
    sizes,
    optionIndex,
    onClickAddSize,
    onClickDeleteSize,
    onChangeSouvenirSizeName,
  } = props;

  function onChangeSizeName(data, index) {
    onChangeSouvenirSizeName(data, optionIndex, index);
  }

  return (
    <TopLevelWrapper>
      <LayerWrapper>
        {
          enabled ?
            sizes.map((data, index) => (
              <SizeOptionInput key={'EnabledSizeNameInput-' + index}
                enabled={enabled}
                value={data.name}
                setValue={onChangeSizeName}
                index={index}
                optionIndex={optionIndex}
                placeholder='옵션을 입력해주세요'
                onClickDelete={onClickDeleteSize} />
            ))
            :
            sizes.map((data, index) => (
              <SelectOptionInputView key={'DisabledSizeNameInput-' + index}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.name}</Text>
              </SelectOptionInputView>
            ))
        }
      </LayerWrapper>
      {
        enabled &&
        <AddOptionButton onClick={() => onClickAddSize(optionIndex)}>
          <PlusWrapper>
            <PlusStick width={14} height={2} />
            <PlusStick width={2} height={14} />
          </PlusWrapper>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#969696' cursor='pointer'>사이즈 추가</Text>
        </AddOptionButton>
      }
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 24px;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 12px;
`;

const SelectOptionInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(31.74% - 4px);
  height: 46px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const AddOptionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 46px;

  gap: 12px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;
`;

const PlusStick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #969696;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;