import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export default function SelectSizeBox(props) {
  const { 
    souvenirData,
    value, 
    setSize,
    setColor,
    index 
  } = props;

  const { t } = useTranslation();

  // 컬러박스 너비 조절 //
  function decideColorBoxWidth() {
    if (souvenirData !== undefined) {
      if (souvenirData.colors.length === 1) return 'calc(100% - 4px)';
      else if (souvenirData.colors.length === 2) return 'calc(48% - 4px)';
      else return 'calc(30% - 4px)';
    }
    else return '0px';
  }

  // 사이즈박스 너비 조절 //
  function decideSizeBoxWidth() {
    if (souvenirData !== undefined) {
      if (souvenirData.sizes.length === 1) return 'calc(100% - 4px)';
      else if (souvenirData.sizes.length === 2) return 'calc(48% - 4px)';
      else return 'calc(30% - 4px)';
    }
    else return '0px';
  }

  // 컬러 선택 여부 확인 //
  function decideSelectedColorOption(option) {
    if (value.commonSouvenirs === undefined) return false;
    else {
      let commonSouvenirs = value.commonSouvenirs.slice();
      let isExist = false;

      for (let i = 0; i < commonSouvenirs.length; i++) {
        if (commonSouvenirs[i].option === undefined) break;
        else if (commonSouvenirs[i].option.souvenirOptionId === option.souvenirOptionId) {
          isExist = true;
          break;
        }
      }

      return isExist;
    }
  }

  // 사이즈 선택 여부 확인 //
  function decideSelectedSizeOption(size) {
    if (value.commonSouvenirs === undefined) return false;
    else {
      let commonSouvenirs = value.commonSouvenirs.slice();
      let isExist = false;

      for (let i = 0; i < commonSouvenirs.length; i++) {
        if (commonSouvenirs[i].size === undefined) break;
        else if (commonSouvenirs[i].size.souvenirSizeId === size.souvenirSizeId) {
          isExist = true;
          break;
        }
      }
      
      return isExist;
    }
  }

  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <LayerWrapper>
          {
            souvenirData.options.map((optionData, optionIndex) => (
              <InfoBox
                key={'OptionBox-' + optionIndex}
                width={decideColorBoxWidth()}
                isSelected={decideSelectedColorOption(optionData)} 
                onClick={() => setColor(souvenirData, optionData, index)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={decideSelectedColorOption(optionData) ? '#FFFFFF' : '#595959'}>{optionData.name}</Text>
              </InfoBox>
            ))
          }
        </LayerWrapper>
        <LayerWrapper>
          {
            souvenirData.sizes.map((sizeData, sizeIndex) => (
              <InfoBox
                key={'SizeBox-' + sizeIndex}
                width={decideSizeBoxWidth()}
                isSelected={decideSelectedSizeOption(sizeData)} 
                onClick={() => setSize(souvenirData, sizeData, index)}>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color={decideSelectedSizeOption(sizeData) ? '#FFFFFF' : '#595959'}>{sizeData.name}</Text>
              </InfoBox>
            ))
          }
        </LayerWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  // justify-content: space-between;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  gap: 10px;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  // width: calc(30% - 4px);
  width: ${props => props.width};
  height: 36px;

  border: ${props => props.isSelected ? '2px solid #6DD49E' : '2px solid #E0E0E0'};
  border-radius: 8px;
  background-color: ${props => props.isSelected ? '#6DD49E' : '#FFFFFF'};

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;