import React from "react";
import styled from "styled-components";

export default function TimeInput(props) {
  const { value, setValue, placeholder } = props;

	function handleChange(e) {
    // setValue(e.target.value);
    const regex = /^[0-9:\-\b]{0,11}$/;
		
		if (regex.test(e.target.value)) {
			if (e.target.value.length === 2) setValue(e.target.value.replace(/(\d{2})/, '$1:'));
			else if (e.target.value.length < 6)setValue(e.target.value);
		}
	}

	function handleFocus(e) {
    setValue("");
	}

	function handleBlur(e) {
	}

	function handleKeyDown(e) {
		if (e.key === 'Backspace') {
			if (value.length === 3 || value.length === 6 || value.length === 9) {
				setValue(value.substring(0, value.length - 1));
			}
		}
	}

  return (
    <InputView>
      <Input
        placeholder={placeholder}
        value={value}
        type='text'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown} />
    </InputView>
  )
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 88px;
  height: 28px;

  border: 2px dashed #909090;
  border-radius: 8px;
  background-color: #2F2F32;
`;

const Input = styled.input`
	font-family: NotoSansKR-Medium;
	font-size: 14px;
	line-height: 17px;

	// width: 100%;
  width: 37px;
	height: 17px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
	color: #FFFFFF;

  border: none;
  background-color: #2F2F32;

	::placeholder {
		color: #ECECEC;
	}
  
  transition: all 0.3s ease-in-out;
`;