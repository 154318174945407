import React, { useEffect } from "react";
import styled, { keyframes } from 'styled-components';
import TeamNameAndBelongInput from "../Component/TeamNameAndBelongInput";

export default function TeamNameAndBelongModifyModal(props) {
  const { 
    teamName,
    belong,
    changeTeamName, 
    setChangeTeamName, 
    changeBelong, 
    setChangeBelong,
    onClickExit,
    onClickSave
  } = props;

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  return (
    <TopLevelWrapper>
      <ModalView>
        <TotalWrapper>
          <TitleWrapper>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>회원 정보 변경</Text>
          </TitleWrapper>
          <TotalInputWrapper>
            <InputBoxWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>변경할 팀 이름</Text>
              <TeamNameAndBelongInput value={changeTeamName} setValue={setChangeTeamName} placeholder={teamName}/>
            </InputBoxWrapper>
            <InputBoxWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>변경할 소속</Text>
              <TeamNameAndBelongInput value={changeBelong} setValue={setChangeBelong} placeholder={belong}/>
            </InputBoxWrapper>
          </TotalInputWrapper>
          <ButtonWrapper>
            <ExitButton onClick={onClickExit}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>취소</Text>
            </ExitButton>
            <DeleteButton onClick={onClickSave}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
            </DeleteButton>
          </ButtonWrapper>
        </TotalWrapper>
      </ModalView>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
animation-name: ${startAnimation};
animation-duration: 0.5s;
animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 279px;
  height: 254px;

  border-radius: 10px;
  background-color: #2F2F32;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 90.33%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width: 100%;
`;

const TotalInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  width 100%;

  gap: 10px;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width 100%;

  gap: 8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: flex-start;

  margin-top: 30px;

  width: 100%;

  gap: 10px;
`;

const DeleteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;