import { useState, forwardRef, useRef, React } from 'react';
import styled from 'styled-components';

import SearchImgPath from '../../../Image/SearchIcon.png';

export default function SearchInput(props) {
	const { value, setValue, placeholder, type } = props;
  
	const [clickCount, setClickCount] = useState(0);

	const inputRef = useRef();


	function handleClick(e) {
		inputRef.current.style.borderColor = '#FF8B48';
	}

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
		setClickCount(clickCount + 1);
		inputRef.current.style.borderColor = '#E8E8E8';
	}

	return (
    <InputWrapper>
      <Image src={SearchImgPath}/>
      <Input
        ref={inputRef}
        clickCount={clickCount}
        type={type}
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}/>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 20px;

  width: 253px;
  height: 38px;

  gap: 12px;

  border: 1px solid #DFDFDF;
  border-radius: 15px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  width: calc(100% - 12px);
  height: calc(100% - 2px);

	outline: none;
  border: 0px solid #000000;
  background-color: #000000;
	color: #FFFFFF;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;
`;