import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import lodash from 'lodash';

import ExitIcon from '../../../../Image/ExitIcon.png';
import PlusIcon from '../../../../Image/PlusIcon.png';

export default function AdditionalSouvenirSelectModal(props) {
  const {
    viewSouvenir,
    selectedSouvenirs,
    setSelectedSouvenirs,
    closeFn
  } = props;

  const { t } = useTranslation();

  const [pointY, setPointY] = useState(undefined);
  const [isClicked, setIsClicked] = useState(false);
  const [optionWrapperHeight, setOptionWrapperHeight] = useState(0);
  const [sizeOptionWrapperHeight, setSizeOptionWrapperHeight] = useState(0);
  const [checkSouvenir, setCheckSouvenir] = useState(undefined);

  const TopLevelRef = useRef();

  // 모달 높이 결정 //
  useEffect(() => {
    setTimeout(() => {
      // let sizeLength = Math.ceil(viewSouvenir.sizes.length / 3);
      // let optionLength = Math.ceil(viewSouvenir.options.length / 3);

      // setOptionWrapperHeight(optionLength * 62);
      // setSizeOptionWrapperHeight(sizeLength * 62);

      let sizeLength = Math.ceil(viewSouvenir.sizes.length / 3);
      let optionLength = 0;

      if (viewSouvenir.isUseOption) {
        let totalSizeLength = 0;

        optionLength = Math.ceil(viewSouvenir.options.length / 3);

        for (let i = 0; i < viewSouvenir.options.length; i++) {
          if (viewSouvenir.options[i].sizes.length > 0) totalSizeLength = viewSouvenir.options[i].sizes.length;
        }

        sizeLength = Math.ceil(totalSizeLength / 3);
      }

      setOptionWrapperHeight(optionLength * 62);
      setSizeOptionWrapperHeight(sizeLength * 62);

      // TopLevelRef.current.style.height = 176 + ((sizeLength + 1) * 62) + 64 + 16 + 'px';
      TopLevelRef.current.style.height = 176 + ((sizeLength + 1) * 62) + ((optionLength + 1) * 62) + 64 + 16 + 'px';

    }, 100)
  }, [TopLevelRef, viewSouvenir])

  // // Modal 창 스크롤 제어 //
  useEffect(() => {
    document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; width: 100%;`

    return () => {
      const scrollY = document.body.style.top;
      document.body.style.cssText = `position: ""; top: "";`
      window.scrollTo(0, parseInt(scrollY || '0') * -1)
    }
  }, [])

  // 옵션박스 너비 조절 //
  function decideOptionBoxWidth() {
    if (viewSouvenir !== undefined) {
      if (viewSouvenir.options.length === 1) return 'calc(100% - 4px)';
      else if (viewSouvenir.options.length === 2) return 'calc(48% - 4px)';
      else return 'calc(30% - 4px)';
    }
    else return '0px';
  }

  // 사이즈박스 너비 조절 //
  function decideSizeBoxWidth() {
    if (viewSouvenir !== undefined) {
      if (viewSouvenir.sizes.length === 1) return 'calc(100% - 4px)';
      else if (viewSouvenir.sizes.length === 2) return 'calc(48% - 4px)';
      else return 'calc(30% - 4px)';
    }
    else return '0px';
  }

  // 클릭여부 확인 //
  function decideSelectSouvenir() {
    return checkSouvenir !== undefined;
  }

  // 옵션 클릭여부 확인 //
  function decideSelectSouvenirOption(data) {
    if (checkSouvenir === undefined || checkSouvenir.option === undefined) return false;
    else if (checkSouvenir.option.souvenirOptionId === data.souvenirOptionId) return true;
  }

  // 색상 옵션 클릭여부 확인 //
  function decideSelectSouvenirSizeOption(data) {
    // if (checkSouvenir === undefined || checkSouvenir.size === undefined) return false;
    // else if (checkSouvenir.size.souvenirSizeId === data.souvenirSizeId) return true;

    if (checkSouvenir === undefined) return false;
    else if (checkSouvenir.option !== undefined) {
      if (checkSouvenir.option.size === undefined) return false;
      else if (checkSouvenir.option.size.souvenirSizeId === data.souvenirSizeId) return true;
      else return false;
    }
    else if (checkSouvenir.size === undefined) return false;
    else {
      if (checkSouvenir.size.souvenirSizeId === data.souvenirSizeId) return true;
      return false;
    }
  }

  // 선택된 상품 이름 표출 결정 //
  function decideSelectedSouvenirName() {
    // if (checkSouvenir === undefined) return '';
    // else {
    //   if (checkSouvenir.size === undefined) {
    //     if (checkSouvenir.color === undefined) return checkSouvenir.name;
    //     else return checkSouvenir.name + ' (' + checkSouvenir.color.name + ')';
    //   }
    //   else {
    //     if (checkSouvenir.color === undefined) return checkSouvenir.name + ' - ' + checkSouvenir.size.name;
    //     else return checkSouvenir.name + ' (' + checkSouvenir.color.name + ') - ' + checkSouvenir.size.name;
    //   }
    // }
    if (checkSouvenir === undefined) return '';
    else {
      if (checkSouvenir.option !== undefined) {
        if (checkSouvenir.option.size === undefined) return checkSouvenir.name + ' (' + checkSouvenir.option.name + ')';
        else return checkSouvenir.name + ' (' + checkSouvenir.option.name + ' - ' + checkSouvenir.option.size.name + ')';
      }
      else {
        if (checkSouvenir.size === undefined) return checkSouvenir.name;
        else return checkSouvenir.name + ' (' + checkSouvenir.size.name + ')';
      }
    }
  }

  // 선택된 상품 이름 표출 결정 //
  function decideSelectedSouvenirCount() {
    if (checkSouvenir === undefined || checkSouvenir.count === undefined) return 0;
    else return checkSouvenir.count;
  }

  // PC일경우 마우스 클릭 감지 //
  function onMouseDown(e) {
    if (pointY !== undefined) return;

    setPointY(e.screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // PC일경우 마우스 움직임 감지 //
  function onMouseMove(e) {
    if (pointY === undefined) return;

    if (e.screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.screenY - pointY) + 'px';
  }

  // PC일경우 마우스 클릭 마무리 감지 //
  function onMouseUp(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.screenY - pointY > 130) onClickExitButton();
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 모바일일 경우 터치 감지 //
  function onTouchStart(e) {
    if (pointY !== undefined) return;

    setPointY(e.changedTouches[0].screenY);
    TopLevelRef.current.style.transition = 'none';
  }

  // 모바일일 경우 터치 움직임 감지 //
  function onTouchMove(e) {
    if (pointY === undefined) return;

    if (e.changedTouches[0].screenY - pointY > 0) TopLevelRef.current.style.marginTop = (e.changedTouches[0].screenY - pointY) + 'px';
  }

  // 모바일일 경우 터치 움직임 마무리 감지 //
  function onTouchEnd(e) {
    TopLevelRef.current.style.transition = 'all 0.5s ease-in-out';

    if (e.changedTouches[0].screenY - pointY > 130) {
      onClickExitButton();
    }
    else {
      TopLevelRef.current.style.marginTop = `0px`;
      setPointY(undefined);
    }
  }

  // 선택 버튼 클릭시 //
  function onClickSelectButton() {

    if (checkSouvenir === undefined) {
      alert('기념품을 선택해주세요');
      return;
    }
    else if (checkSouvenir.sizes.length > 0 && checkSouvenir.size === undefined) {
      alert('기념품 옵션을 선택해주세요');
      return;
    }
    else if (checkSouvenir.count === undefined || checkSouvenir.count === 0) {
      alert('기념품 개수를 올바르게 입력해주세요');
      return;
    }

    let newSelectedSouvenirs = selectedSouvenirs.slice();
    let isExist = false;

    for (let i = 0; i < newSelectedSouvenirs.length; i++) {
      if (newSelectedSouvenirs[i].souvenirId === checkSouvenir.souvenirId) {
        if (newSelectedSouvenirs[i].options.length > 0) {
          if (newSelectedSouvenirs[i].option.souvenirOptionId  === checkSouvenir.option.souvenirOptionId) {
            newSelectedSouvenirs[i].count += checkSouvenir.count;
            isExist = true;
            break;
          }
        }
        else if (newSelectedSouvenirs[i].sizes.length > 0) {
          if (newSelectedSouvenirs[i].size.souvenirSizeId === checkSouvenir.size.souvenirSizeId) {
            newSelectedSouvenirs[i].count += checkSouvenir.count;
            isExist = true;
            break;
          }
        }
        else {
          newSelectedSouvenirs[i].count += checkSouvenir.count;
          isExist = true;
          break;
        }
      }
    }

    if (!isExist) {
      newSelectedSouvenirs.push(checkSouvenir);
    }

    setSelectedSouvenirs(newSelectedSouvenirs);
    onClickExitButton();
  }

  //  닫기 버튼 클릭시 //
  function onClickExitButton() {
    TopLevelRef.current.style.height = '0px';
    setCheckSouvenir(undefined);

    setTimeout(() => {
      closeFn();
    }, 300);
  }

  // 개수 더하기 클릭시 //
  function onClickPlusButton() {
    let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

    newCheckSouvenir.count += 1;

    setCheckSouvenir(newCheckSouvenir);
  }

  // 개수 빼기 클릭시 //
  function onClickMinusButton() {
    if (checkSouvenir.count !== undefined && checkSouvenir.count > 0) {
      let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

      newCheckSouvenir.count -= 1;

      setCheckSouvenir(newCheckSouvenir);
    }
  }

  // 기념품 클릭시 //
  function onClickSouvenir() {
    if (checkSouvenir === undefined) {
      let newCheckSouvenir = lodash.cloneDeep(viewSouvenir);

      newCheckSouvenir.count = 1;

      setCheckSouvenir(newCheckSouvenir);
    }
    else setCheckSouvenir(undefined);
  }

  // 기념품 옵션 클릭시 //
  function onClickSouvenirOption(data) {
    if (checkSouvenir === undefined) return;
    else {
      let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

      newCheckSouvenir.option = data;

      setCheckSouvenir(newCheckSouvenir);
    }
  }

  // 기념품 색상 옵션 클릭시 //
  function onClickSouvenirSizeOption(data) {
    // console.log(data);
    if (checkSouvenir === undefined) return;
    else {
      // let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

      // newCheckSouvenir.size = data;

      // setCheckSouvenir(newCheckSouvenir);

      if (checkSouvenir.option === undefined) {
        let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

        newCheckSouvenir.size = data;
  
        setCheckSouvenir(newCheckSouvenir);
      }
      else {
        let newCheckSouvenir = lodash.cloneDeep(checkSouvenir);

        newCheckSouvenir.option.size = data;

        setCheckSouvenir(newCheckSouvenir);
      }
    }
  }


  return (
    <Background>
      <TopLevelWrapper
        ref={TopLevelRef}>
        <TitleLineWrapper
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}>
          <TitleWrapper>
            <TitleMaximumWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F' cursor='default'>추가 기념품 선택</Text>
            </TitleMaximumWrapper>
            <Image src={ExitIcon} onClick={onClickExitButton} />
          </TitleWrapper>
        </TitleLineWrapper>
        <EventBoxWrapper>
          <EventBox
            isClicked={decideSelectSouvenir()}
            onClick={() => onClickSouvenir()}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={decideSelectSouvenir() ? `#FFFFFF` : '#4F4F4F'} cursor='pointer'>{viewSouvenir.name}</Text>
          </EventBox>
        </EventBoxWrapper>
        {
          <SizeBoxWrapper height={optionWrapperHeight}>
            {
              viewSouvenir !== undefined && viewSouvenir.options.map((data, index) => (
                <SizeBox
                  key={'Options-' + index}
                  isClicked={decideSelectSouvenirOption(data)}
                  width={decideOptionBoxWidth()}
                  onClick={() => onClickSouvenirOption(data)}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={decideSelectSouvenirOption(data) ? '#FFFFFF' : '#4F4F4F'} cursor='pointer'>{data.name}</Text>
                </SizeBox>
              ))
            }
          </SizeBoxWrapper>
        }
        {
          (checkSouvenir !== undefined && checkSouvenir.option !== undefined) &&
          <SizeBoxWrapper style={{ marginTop: '0px' }} height={sizeOptionWrapperHeight}>
            {
              checkSouvenir !== undefined && checkSouvenir.option !== undefined && checkSouvenir.option.sizes.map((data, index) => (
                <SizeBox
                  key={'Size-' + index}
                  isClicked={decideSelectSouvenirSizeOption(data)}
                  width={decideSizeBoxWidth()}
                  onClick={() => onClickSouvenirSizeOption(data)}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={decideSelectSouvenirSizeOption(data) ? '#FFFFFF' : '#4F4F4F'} cursor='pointer'>{data.name}</Text>
                </SizeBox>
              ))
            }
          </SizeBoxWrapper>
        }
        {
          (checkSouvenir !== undefined && checkSouvenir.sizes !== undefined) &&
          <SizeBoxWrapper style={{ marginTop: '0px' }} height={sizeOptionWrapperHeight}>
            {
              checkSouvenir !== undefined && checkSouvenir.sizes.map((data, index) => (
                <SizeBox
                  key={'Size-' + index}
                  isClicked={decideSelectSouvenirSizeOption(data)}
                  width={decideSizeBoxWidth()}
                  onClick={() => onClickSouvenirSizeOption(data)}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={decideSelectSouvenirSizeOption(data) ? '#FFFFFF' : '#4F4F4F'} cursor='pointer'>{data.name}</Text>
                </SizeBox>
              ))
            }
          </SizeBoxWrapper>
        }
        <CountBoxWrapper isView={checkSouvenir !== undefined && (checkSouvenir.option !== undefined || checkSouvenir.size !== undefined)}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#4F4F4F'>{decideSelectedSouvenirName()}</Text>
          <InputWrapper>
            <Input style={{ marginRight: '6px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{decideSelectedSouvenirCount()}</Text>
            </Input>
            <IncreaseButton style={{ marginRight: '4px' }} onClick={onClickMinusButton}>
              <div style={{ width: `20px`, height: `3px`, borderRadius: `12px`, backgroundColor: '#FFFFFF' }} />
            </IncreaseButton>
            <IncreaseButton style={{ marginRight: '4px' }} onClick={onClickPlusButton}>
              <Image src={PlusIcon} />
            </IncreaseButton>
          </InputWrapper>
        </CountBoxWrapper>
        <SelectButton isClicked={isClicked} onClick={onClickSelectButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF' cursor='pointer'>{t('선택')}</Text>
        </SelectButton>
      </TopLevelWrapper>
    </Background>
  )
}

const Background = styled.div`
  position: fixed;
	display: flex;
	flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  top: 0;
  left: 0;

  z-index: 500;

  // width: 100vw;
  // height: 100vh;
  width: 100%;
  // height: 100%;
  height: 100vh;
  // height: min(100%, 100vh);

  background-color: rgba(0, 0, 0, 0.5);
`;

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  justify-content: flex-start;
  align-items: center;

	width: 100%;
  height: 0px;
  
	border-radius: 20px 20px 0px 0px;
  background-color: #FFFFFF;

  overflow: hidden;

  transition: all 0.5s ease-in-out;
`;

const TitleLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 56px;

  background-color: #FFFFFF;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const TitleMaximumWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  max-width: 83.68%;
  height: 25px;

  overflow: hidden;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const EventBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 12px;

  width: 90.33%;
`;

const EventBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: calc(100% - 4px);
  height: 46px;

  border: ${props => props.isClicked ? '2px solid #6DD49E' : '2px solid #DFDFDF'};
  border-radius: 8px;
  background-color:  ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.5s ease-in-out;
`;

const SizeBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;
  
  width: 90.33%;
  height: ${props => props.height}px;
`;

const SizeBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: ${props => props.width};
  height: 46px;

  border: ${props => props.isClicked ? '2px solid #6DD49E' : '2px solid #DFDFDF'};
  border-radius: 8px;
  background-color:  ${props => props.isClicked ? `#6DD49E` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.5s ease-in-out;
`;

const CountBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 24px;
  
  width: 90.33%;

  opacity: ${props => props.isView ? 1 : 0};
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;
`;

const Input = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 12px 0px 12px;

  width: 34px;
  height: 38px;

  border: 1px solid #E0E0E0;
  border-radius: 6px;
  background-color: #FFFFFF;
`;

const IncreaseButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 38px;
  height: 38px;

  border: 1px solid #DFDFDF;
  border-radius: 6px;
  background-color: #ECECEC;

  :hover {
    cursor: pointer;
  }
`;

const SelectButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  // margin-top: ${props => props.isClicked ? `34px` : `134px`};
  margin-top: 24px;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const HoverText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.5s ease-in-out;
`;