import { React, forwardRef, useRef, useImperativeHandle } from 'react';
import styled from 'styled-components';

import ChromeIcon from '../../../Image/ChromeIcon.png';

const LoginBottomComponent = forwardRef((props, ref) => {
	const {onClickLoginButton} = props;

	const TopLevelRef = useRef()

	useImperativeHandle(ref, () => ({
		setOpacity: (val) => {
			TopLevelRef.current.style.opacity = val;
			if (val === 0) TopLevelRef.current.style.zIndex = -1;
			else TopLevelRef.current.style.zIndex = 1;
		},
	}));

	return (
		<TopLevelWrapper ref={TopLevelRef}>
			<ColumnWrapper>
				<RowWrapper gap={6}>
					<Icon src={ChromeIcon}/>
					<Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>크롬 환경에서 로그인해 주세요</Text>
				</RowWrapper>
				<RowWrapper gap={24}>
					<MoreDetails onClick={() => window.open("https://link-zone.io/rule/service")}>이용약관</MoreDetails>
					<MoreDetails onClick={() => window.open("https://link-zone.io/rule/privacy")}>개인정보 처리방침</MoreDetails>
				</RowWrapper>
			</ColumnWrapper>
			<LoginButton onClick={onClickLoginButton}>로그인</LoginButton>
		</TopLevelWrapper>
	)
})

export default LoginBottomComponent;

const TopLevelWrapper = styled.div`
	position: absolute;
	display: flex;
	flex-direction: row;

	justify-content: flex-start;
	align-items: center;

	margin-top: 514px;
	// margin-top: max(193.359375px, 26.77083333333333vw);

	// margin-top: max(397px, 26.77083333333333vw);
	// margin-top: 358px;
	
	gap: 24px;
	// gap: max(9.765625px, 1.25vw);
	// gap: max(10px, 1.25vw);

	opacity: 1;
	z-index: 1;
  transition: all 0.5s ease-in-out;
`;

const ColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: flex-start;
	align-items: flex-start;

	gap: 10px;

	margin-top: -30px;
`;

const RowWrapper = styled.div`
	display: flex;
	flex-direction: row;

	justify-content: center;
	align-items: center;

	gap: ${props => props.gap}px;
`;

const Icon = styled.img`
	width: 19px;
	height: 19px;
`;

const MoreDetails = styled.p`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	// font-size: max(12px, 0.625vw);
	// line-height: 17.38px;

	margin: 0 0 0 0;

	color: rgba(119, 119, 119, 1);

	:hover {
		cursor: pointer;
	}
`;

const LoginButton = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: NotoSansKR-Bold;
	font-size: 14px;
	// font-size: max(12px, 0.7291666666666667vw);
	// line-height: 20px;

	width: 118px;
	// width: max(44.921875px, 6.145833333333333vw);
	// width: max(92px, 6.145833333333333vw);
	height: 46px;
	// height: max(17.51191737288136px, 2.395833333333333vw);
	// height: max(36px, 2.395833333333333vw);
	
	margin: 0 0 0 171px;
	// margin-top: 0px;
	// margin-right: 0px;
	// margin-bottom: 0px;
	// margin-left: 171px;
	// margin-left: max(25.04px, 8.90625vw);
	// margin-left: max(25.04px, 5.208333333333333vw);
	// margin-left: max(11px, 5.208333333333333vw);
	// margin-left: calc(10.390625vw - 28.5px);

	border-radius: 23px;
	// border-radius: max(18px, 1.197916666666667vw);
	// background-color: #FF8B48;
	background-color: #6DD49E;
	color: #FFFFFF;

	cursor: default;
  transition: all 0.5s ease-in-out;

	:hover {
		cursor: pointer;
		background-color: #FF7322;
	}
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space:pre;
  
  :hover {
    cursor: ${props => props.hover};
  }
`;