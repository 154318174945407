import React from "react";
import styled from "styled-components";

import SouvenirNameInput from "../Component/SouvenirNameInput";
import SouvenirInput from "../Component/SouvenirInput";
import PriceInput from "../Component/PriceInput";
import SizeOptionView from "./SizeOptionView";

import TrashIcon from '../../../Image/WhiteTrashIcon.png';
import OptionView from "./OptionView";


export default function SouvenirBox(props) {
  const {
    data,
    index,

    enabled,
    isCommon,

    pictureWidth,
    pictureHeight,
    plusWidth,
    plusHeight,

    onChangeAppliedSouvenirName,
    onChangeAppliedSouvenirPicture,
    onChangeAppliedSouvenirPrice,
    onChangeAppliedUseOption,
    onChangeAppliedAddOptionSize,
    onChangeAppliedDeleteOptionSize,
    onChangeAppliedSouvenirOptionSizeName,
    onChangeAppliedAddSize,
    onChangeAppliedDeleteSize,
    onChangeAppliedSouvenirSizeName,
    onChangeAppliedAddOption,
    onChangeAppliedDeleteOption,
    onChangeAppliedSouvenirOptionName,
    onClickAppliedDeleteSouvenir
  } = props;

  // 기념품 이름 변경 //
  function onChangeSouvenirName(data) {
    onChangeAppliedSouvenirName(data, index);
  }

  // 기념품 가격 변경시 //
  function onChangeSouvenirPrice(data) {
    onChangeAppliedSouvenirPrice(data, index);
  }

  // 기념품 썸네일 변경시 //
  function onChangeSouvenirPicture(data) {
    onChangeAppliedSouvenirPicture(data, index);
  }

  // 기념품 옵션 사용 클릭시 //
  function onChangeUseOption() {
    onChangeAppliedUseOption(index);
  }

  // 기념품 옵션 사이즈 추가 클릭시 //
  function onClickAddOptionSize(optionIndex) {
    onChangeAppliedAddOptionSize(index, optionIndex);
  }

  // 기념품 옵션 사이즈 삭제 클릭시 //
  function onClickDeleteOptionSize(optionIndex, sizeIndex) {
    onChangeAppliedDeleteOptionSize(index, optionIndex, sizeIndex);
  }

  // 기념품 옵션 사이즈 이름 변경시 //
  function onChangeSouvenirOptionSizeName(data, optionIndex, sizeIndex) {
    onChangeAppliedSouvenirOptionSizeName(data, index, optionIndex, sizeIndex);
  }

  // 기념품 기본 사이즈 추가 클릭시 //
  function onClickAddSize() {
    onChangeAppliedAddSize(index);
  }

  // 기념품 기본 사이즈 삭제 클릭시 //
  function onClickDeleteSize(optionIndex, sizeIndex) {
    onChangeAppliedDeleteSize(index, sizeIndex);
  }

  // 기념품 기본 사이즈 이름 변경시 //
  function onChangeSouvenirSizeName(data, optionIndex, sizeIndex) {
    onChangeAppliedSouvenirSizeName(data, index, sizeIndex);
  }

  // 기념품 옵션 추가 클릭시 //
  function onClickAddOption() {
    onChangeAppliedAddOption(index);
  }

  // 기념품 컬러 삭제 클릭시 //
  function onClickDeleteOption(optionIndex) {
    onChangeAppliedDeleteOption(index, optionIndex);
  }

  // 기념품 컬러 이름 변경시 //
  function onChangeSouvenirOptionName(data, optionIndex) {
    onChangeAppliedSouvenirOptionName(data, index, optionIndex);
  }

  // 기념품 삭제 클릭시 //
  function onClickDeleteSouvenir() {
    onClickAppliedDeleteSouvenir(index);
  }


  return (
    <TopLevelWrapper>
      <InfoWrapper>
        {
          enabled ?
            <SouvenirNameInput value={data.name} setValue={onChangeSouvenirName} placeholder='기념품 이름을 입력해주세요' />
            :
            <SouvenirNameInputViewWrapper>
              <Column />
              <SouvenirNameInputView>
                <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#FFFFFF' cursor='default'>{data.name}</Text>
              </SouvenirNameInputView>
            </SouvenirNameInputViewWrapper>
        }

        <DivisionLine />

        <ComponentWrapper>
          {/* <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>기념품 사진 (1975px x 1200px)</Text> */}
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>기념품 사진</Text>
          <SouvenirInput
            enabled={enabled}
            souvenirPictureWidth={pictureWidth}
            souvenirPictureHeight={pictureHeight}
            plusWidth={plusWidth}
            plusHeight={plusHeight}
            souvenirPicture={data.souvenirPicture}
            setSouvenirPicture={onChangeSouvenirPicture} />
        </ComponentWrapper>

        {
          !isCommon &&
          <ComponentWrapper style={{ marginTop: '15px' }}>
            <ComponentTitleOptionWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>기념품 가격 [부가세 포함 가격을 입력해주세요]</Text>
            </ComponentTitleOptionWrapper>
            <PriceInput enabled={enabled} value={data.price} setValue={onChangeSouvenirPrice} placeholder='0' />
          </ComponentWrapper>
        }

        {(enabled || data.options.length > 0) && <DivisionLine />}

        {
          (enabled || data.options.length > 0) &&
          <ComponentWrapper>
            <ComponentTitleOptionWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>공통 기념품 선택 옵션 (예: 남성 핏, 여성 핏)</Text>
              <SelectOptionWrapper onClick={onChangeUseOption}>
                <CheckBox isClicked={data.isUseOption === undefined ? false : data.isUseOption}/>
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>선택 옵션</Text>
              </SelectOptionWrapper>
            </ComponentTitleOptionWrapper>
            <OptionView
              enabled={enabled}
              options={data.options}
              isUseOption={data.isUseOption === undefined ? false : data.isUseOption}
              onClickAddOption={onClickAddOption}
              onClickDeleteOption={onClickDeleteOption}
              onChangeSouvenirOptionName={onChangeSouvenirOptionName} />
          </ComponentWrapper>
        }

        {
          data.options.map((optionData, optionIndex) => (
            <TotalComponentWrapper key={'CommonOption-' + optionIndex} style={{ marginTop: '15px' }}>
              {/* {(enabled || data.options.length > 0) && <DivisionLine />} */}
              {
                (enabled || optionData.sizes.length > 0) &&
                <ComponentWrapper>
                  <ComponentTitleOptionWrapper>
                    <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>{`\"${optionData.name}\" 기념품 사이즈`}</Text>
                  </ComponentTitleOptionWrapper>
                  <SizeOptionView
                    enabled={enabled}
                    sizes={optionData.sizes}
                    optionIndex={optionIndex}
                    onClickAddSize={onClickAddOptionSize}
                    onClickDeleteSize={onClickDeleteOptionSize}
                    onChangeSouvenirSizeName={onChangeSouvenirOptionSizeName} />
                </ComponentWrapper>
              }
            </TotalComponentWrapper>
          ))
        }

        { ((enabled || data.sizes.length > 0) && !data.isUseOption) && <DivisionLine /> }

        {
          ((enabled || data.sizes.length > 0) && !data.isUseOption) &&
          <ComponentWrapper>
            <ComponentTitleOptionWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>기념품 사이즈</Text>
            </ComponentTitleOptionWrapper>
            <SizeOptionView
              enabled={enabled}
              sizes={data.sizes}
              onClickAddSize={onClickAddSize}
              onClickDeleteSize={onClickDeleteSize}
              onChangeSouvenirSizeName={onChangeSouvenirSizeName} />
          </ComponentWrapper>
        }
      </InfoWrapper>
      {
        enabled &&
        <DeleteTicketButton onClick={onClickDeleteSouvenir}>
          <DeleteIcon src={TrashIcon} />
        </DeleteTicketButton>
      }
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-top: 20px;

  width: 100%;

  border-radius: 12px;
  background-color: #2F2F32;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-bottom: 30px;

  width: 96.7%;
`;

const SouvenirNameInputViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 96.59%;

  gap: 10px;
`;

const SouvenirNameInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 15px;

  width: calc(100% - 56px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Column = styled.div`
  width: 12px;
  height: 12px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const TotalComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 96.7%;

  gap: 15px;
`;

const DivisionLine = styled.div`
  margin: 24px 0px 10px 0px;

  width: 100%;
  height: 1px;

  border-radius: 2px;
  background-color: #ECECEC;
`;

const ComponentTitleOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const SelectOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;

  :hover {
    cursor: pointer;
  }
`;

const CheckBox = styled.div`
  width: 18px;
  height: 18px;

  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#2F2F32'};

  transition: all 0.3s ease-in-out;
`;

const DeleteTicketButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 54px;

  border-radius: 0px 0px 12px 12px;
  background-color: #FF5A5A;

  :hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;