import React from "react";
import styled from "styled-components";

import TicketNameInput from "../Component/TicketNameInput";
import EntryQualificationInput from "../Component/EntryQualificationInput";
import SelectOptionView from "./SelectOptionView";
import ParticipantCountInput from "../Component/ParticipantCountInput";
import AdditionalInstructionsTextArea from "../Component/AdditionalInstructionsTextArea";
import PriceInput from "../Component/PriceInput";
import SouvenirInput from "../Component/SouvenirInput";

import NameArrow from '../../../Image/NameArrow.png';
import TrashIcon from '../../../Image/WhiteTrashIcon.png';
import WhiteDownArrow from '../../../Image/WhiteDownArrow.png';
import TicketInput from "../Component/TicketInput";

export default function TicketView(props) {
  const {
    data,
    index,

    enabled,

    pictureSize,
    plusWidth,
    plusHeight,

    onChangeAppliedTicketName,
    onChangeAppliedEntryQualification,
    onChangeAppliedIsUseSelectOption,
    onChangeAppliedAddSelectOption,
    onChangeAppliedDeleteSelectOption,
    onChangeAppliedSelectOptionName,
    onChangeAppliedIsUseParticipantLimit,
    onChangeAppliedParticipantLimit,
    onChangeAppliedTeamMemberCount,
    onChangeAppliedAdditionalInstaructions,
    onChangeAppliedIsUsePriceOption,
    onChangeAppliedAddPriceOption,
    onChangeAppliedDeletePriceOption,
    onChangeAppliedPriceOptionAppliedDate,
    onChangeAppliedPriceOptionName,
    onChangeAppliedPriceOptionPrice,
    onChangeAppliedPlace,
    onChangeAppliedTicketThumbnail,
    onClickAppliedDeleteTicket
  } = props;

  // 티켓 이름 변경 //
  function onChangeTicketName(data) {
    onChangeAppliedTicketName(data, index);
  }

  // 참가 자격 변경 //
  function onChangeEntryQualification(data) {
    onChangeAppliedEntryQualification(data, index);
  }

  // 선택 옵션 사용 체크 클릭시 //
  function onChangeIsUseSelectOption() {
    if (!enabled) return;

    onChangeAppliedIsUseSelectOption(index);
  }

  // 선택 옵션 추가 클릭시 //
  function onClickAddSelectOption() {
    onChangeAppliedAddSelectOption(index);
  }

  // 선택 옵션 삭제 클릭시 //
  function onClickDeleteSelectOption(selectOptionIndex) {
    onChangeAppliedDeleteSelectOption(index, selectOptionIndex);
  }

  // 선택 옵션 이름 변경시 //
  function onChangeSelectOptionName(data, selectOptionIndex) {
    onChangeAppliedSelectOptionName(data, index, selectOptionIndex);
  }

  // 참가 인원 사용여부 변경시 //
  function onChangeIsUseParticipantLimit() {
    if (!enabled) return;

    onChangeAppliedIsUseParticipantLimit(index);
  }

  // 참가 인원 수정시 //
  function onChangeParticipantLimit(data) {
    onChangeAppliedParticipantLimit(data, index);
  }

  // 팀원 수 수정시 //
  function onChangeTeamMemberCount(data) {
    onChangeAppliedTeamMemberCount(data, index);
  }

  // 참가 추가 지침 변경시 //
  function onChangeAdditionalInstaructions(data) {
    onChangeAppliedAdditionalInstaructions(data, index);
  }

  // 참가비 옵션 사용여부 변경시 //
  function onChangeIsUsePriceOption() {
    if (!enabled) return;

    onChangeAppliedIsUsePriceOption(index);
  }

  // 참가비 옵션 추가 클릭시 //
  function onClickAddPriceOption() {
    onChangeAppliedAddPriceOption(index);
  }

  // 참가비 옵션 삭제 클릭시 //
  function onClickDeletePriceOption(priceOptionIndex) {
    onChangeAppliedDeletePriceOption(index, priceOptionIndex);
  }

  // 가격옵션 적용날짜 변경시 //
  function onChangePriceOptionAppliedDate(data, priceOptionIndex) {
    onChangeAppliedPriceOptionAppliedDate(data, index, priceOptionIndex);
  }

  // 가격옵션 이름 변경시 //
  function onChangePriceOptionName(data, priceOptionIndex) {
    onChangeAppliedPriceOptionName(data, index, priceOptionIndex);
  }

  // 가격옵션 가격 변경시 //
  function onChangePriceOptionPrice(data, priceOptionIndex) {
    onChangeAppliedPriceOptionPrice(data, index, priceOptionIndex);
  }

  // 장소 변경시 //
  function onChangePlace(data) {
    onChangeAppliedPlace(data, index);
  }

  // 티켓 썸네일 변경시 //
  function onChangeTicketThumbnail(data) {
    onChangeAppliedTicketThumbnail(data, index);
  }

  // 티켓 삭제 클릭시 //
  function onClickDeleteTicket() {
    onClickAppliedDeleteTicket(index);
  }

  return (
    <TopLevelWrapper>
      <InfoWrapper>
        {
          enabled ?
            <TicketNameInput value={data.name} setValue={onChangeTicketName} placeholder='티켓이름' />
            :
            <TicketNameInputViewWrapper>
              <EventNameImage src={NameArrow} />
              <TicketNameInputView>
                <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#FFFFFF' cursor='default'>{data.name}</Text>
              </TicketNameInputView>
            </TicketNameInputViewWrapper>
        }
        <DivisionLine />

        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>참가자격</Text>
          {
            enabled ?
              <EntryQualificationInput value={data.entryQualification} setValue={onChangeEntryQualification} placeholder='참가 자격을 입력해주세요' />
              :
              <EntryQualificationInputView>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.entryQualification}</Text>
              </EntryQualificationInputView>
          }
        </ComponentWrapper>

        <DivisionLine />

        <ComponentWrapper>
          <ComponentTitleOptionWrapper onClick={onChangeIsUseSelectOption}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>선택 옵션</Text>
            <OptionCheckBoxWrapper>
              <OptionCheckBox isClicked={data.isUseSelectOption} />
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>옵션 사용</Text>
            </OptionCheckBoxWrapper>
          </ComponentTitleOptionWrapper>
          {
            data.isUseSelectOption ?
              <SelectOptionView
                enabled={enabled}
                selectOptions={data.selectOptions}
                onClickAddSelectOption={onClickAddSelectOption}
                onClickDeleteSelectOption={onClickDeleteSelectOption}
                onChangeSelectOptionName={onChangeSelectOptionName} />
              :
              <EmptyBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>선택 옵션 없음</Text>
              </EmptyBox>
          }
        </ComponentWrapper>

        <DivisionLine />

        <ComponentWrapper>
          <ComponentTitleOptionWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>참가 인원</Text>
          </ComponentTitleOptionWrapper>
          {
            enabled ?
              <ParticipantCountInput value={data.teamMemberCount} setValue={onChangeTeamMemberCount} placeholder='0' />
              :
              <ParticipantInputViewWrapper>
                <ParticipantInputView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.teamMemberCount}</Text>
                </ParticipantInputView>
                <Button>
                  <Image style={{ transform: `rotate(180deg)` }} src={WhiteDownArrow}/>
                </Button>
                <Button>
                  <Image src={WhiteDownArrow}/>
                </Button>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>명</Text>
              </ParticipantInputViewWrapper>
          }
        </ComponentWrapper>

        <DivisionLine />

        <ComponentWrapper>
          <ComponentTitleOptionWrapper>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>선착순 참가 인원 마감</Text>
            <OptionCheckBoxWrapper onClick={onChangeIsUseParticipantLimit}>
              <OptionCheckBox isClicked={!data.isUseParticipantLimit} />
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>제한 없음</Text>
            </OptionCheckBoxWrapper>
          </ComponentTitleOptionWrapper>
          {
            data.isUseParticipantLimit ?
              enabled ?
                <ParticipantCountInput value={data.participantLimit} setValue={onChangeParticipantLimit} placeholder='0' />
                :
                <ParticipantInputViewWrapper>
                  <ParticipantInputView>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.participantLimit}</Text>
                  </ParticipantInputView>
                  <Button>
                    <Image style={{ transform: `rotate(180deg)` }} src={WhiteDownArrow} />
                  </Button>
                  <Button>
                    <Image src={WhiteDownArrow} />
                  </Button>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>명</Text>
                </ParticipantInputViewWrapper>
              :
              <EmptyBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>선착순 인원 제한 없음</Text>
              </EmptyBox>
          }
        </ComponentWrapper>
        <DivisionLine />

        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>참가 추가 지침</Text>
          {
            enabled ?
              <AdditionalInstructionsTextArea value={data.additionalInstructions} setValue={onChangeAdditionalInstaructions} placeholder='참가에 필요한 추가적인 지침을 적어주세요' />
              :
              <EntryQualificationInputView>
                <PreText style={{ textAlign: 'right' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.additionalInstructions}</PreText>
              </EntryQualificationInputView>
          }
        </ComponentWrapper>

        <DivisionLine />

        <PriceComponentWrapper>
          <ComponentWrapper>
            <ComponentTitleOptionWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>참가비 [단체 참가일 경우 총합 금액을 입력해주세요]</Text>
              <OptionCheckBoxWrapper onClick={onChangeIsUsePriceOption}>
                <OptionCheckBox isClicked={data.isUsePriceOption} />
                <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='pointer'>옵션 사용</Text>
              </OptionCheckBoxWrapper>
            </ComponentTitleOptionWrapper>
          </ComponentWrapper>
          {
            data.priceOptions.map((priceInfo, priceIndex) => (
              <PriceComponentWrapper key={'PriceInput-' + priceIndex}>
                <ComponentWrapper>
                  <PriceInput
                    enabled={enabled}
                    isUseOption={data.isUsePriceOption}
                    appliedDate={priceInfo.appliedDate}
                    setAppliedDate={onChangePriceOptionAppliedDate}
                    name={priceInfo.name}
                    setName={onChangePriceOptionName}
                    value={priceInfo.price}
                    setValue={onChangePriceOptionPrice}
                    index={priceIndex}
                    onClickDelete={onClickDeletePriceOption} />
                </ComponentWrapper>
              </PriceComponentWrapper>
            ))
          }
          {
            (data.isUsePriceOption && enabled) &&
            <ComponentWrapper>
              <AddPriceOptionButton onClick={onClickAddPriceOption}>
                <PlusWrapper>
                  <PlusStick width={14} height={2} />
                  <PlusStick width={2} height={14} />
                </PlusWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#969696' cursor='pointer'>옵션 추가</Text>
              </AddPriceOptionButton>
            </ComponentWrapper>
          }
        </PriceComponentWrapper>

        <DivisionLine />

        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>대회 장소</Text>
          {
            enabled ?
            <EntryQualificationInput value={data.place} setValue={onChangePlace} placeholder='Online' />
            :
            <EntryQualificationInputView>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{data.place}</Text>
            </EntryQualificationInputView>
          }
        </ComponentWrapper>

        <DivisionLine />

        <ComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>티켓 썸네일 (1080px x 1080px)</Text>
          <TicketInput
            enabled={enabled}
            ticketSize={pictureSize}
            plusWidth={plusWidth}
            plusHeight={plusHeight}
            souvenirPicture={data.ticketThumbnail}
            setSouvenirPicture={onChangeTicketThumbnail} />
        </ComponentWrapper>
      </InfoWrapper>
      {
        enabled &&
        <DeleteTicketButton onClick={onClickDeleteTicket}>
          <DeleteIcon src={TrashIcon} />
        </DeleteTicketButton>
      }
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding-top: 20px;

  width: 100%;

  border-radius: 12px;
  background-color: #2F2F32;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-bottom: 30px;

  width: 96.7%;
`;

const TicketNameInputViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 96.59%;

  gap: 10px;
`;

const TicketNameInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 8px 15px;

  width: calc(100% - 56px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const EntryQualificationInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  padding: 5px 10px;

  width: calc(100% - 24px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;

const PriceComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;

  width: 100%;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 96.7%;

  gap: 15px;
`;

const DivisionLine = styled.div`
  margin: 24px 0px 10px 0px;

  width: 100%;
  height: 1px;

  border-radius: 2px;
  background-color: #ECECEC;
`;

const ComponentTitleOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const OptionCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 8px;

  :hover {
    cursor: pointer;
  }
`;

const OptionCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 18px;
  height: 18px;
  
  border: 1px solid ${props => props.isClicked ? `#FFFFFF` : `#969696`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#5BD98A` : `#2F2F32`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const AddPriceOptionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 25px;

  width: calc(100% - 4px);
  height: 46px;

  gap: 12px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 14px;
  height: 14px;
`;

const PlusStick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #969696;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 46px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const DeleteTicketButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 54px;

  border-radius: 0px 0px 12px 12px;
  background-color: #FF5A5A;

  :hover {
    cursor: pointer;
  }
`;

const DeleteIcon = styled.img`
  width: 32px;
  height: 32px;
`;

const ParticipantInputViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
  
  gap: 4px;
`;

const ParticipantInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  padding: 0px 15px;

  width: 46px;
  height: 28px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 30px;
  height: 30px;

  border: 1px solid #FFFFFF;
  border-radius: 5px;
  background-color: #444447;

  :hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space: pre-wrap;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;