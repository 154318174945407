import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import EventSelectModal from './EventSelectModal';
import CommonSouvenirSelectModal from './CommonSouvenirSelectModal';
import AdditionalSouvenirSelectModal from './AdditionalSouvenirSelectModal';

import DownIcon from '../../../Image/DownIcon.png';
import GrayExit from '../../../Image/GrayExit.png';

export default function SelectPageUserView(props) {
  const {
    UserViewRef,
    scroll,
    events,
    commonSouvenirs,
    additionalSouvenirs
  } = props;

  const navigate = useNavigate();

  const [selectedEvent, setSelectedEvent] = useState({
    eventId: 0,
    name: '종목을 선택해주세요',
    organizerId: 0,
    prices: [],
    options: []
  });
  const [selectedDivision, setSelectedDivision] = useState({
    selectedOptionId: 0,
    eventId: 0,
    name: ''
  })
  const [viewSouvenir, setViewSouvenir] = useState(undefined);
  const [selectedCommonSouvenirs, setSelectedCommonSouvenirs] = useState([]);
  const [selectedAdditionalSouvenirs, setSelectedAdditionalSouvenirs] = useState([]);

  const [isClickedSelectEvent, setIsClickedSelectEvent] = useState(false);
  const [isClickedCommonSouvenirSetting, setIsClickedCommonSouvenirSetting] = useState(false);
  const [isClickedAdditionalSouvenirSetting, setIsClickedAdditionalSouvenirSetting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
  }

  // 선택한 공동기념품 품목 표시 //
  function decideSelectedCommomSouvenirInfoView(data) {
    if (data.size === undefined) return `- ${data.name} X 1`;
    else return `- ${data.name} (${data.size.name}) X 1`;
  }

  // 선택한 추가기념품 품목 표시 //
  function decideSelectedAdditionalSouvenirInfoView(data) {
    if (data.size === undefined) return `- ${data.name} X ${data.count}`;
    else return `- ${data.name} (${data.size.name}) X ${data.count}`;
  }

  // 참가비 계산 //
  function decideParticipantFee() {
    let priceName = '';
    let price = 0;

    if (selectedEvent.prices.length === 0) return '0원';
    else if (selectedEvent.prices.length === 1) {
      return selectedEvent.prices[0].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
    }
    else {
      let prices = selectedEvent.prices.slice();
      // // 날짜 오름차순 정렬 //
      // prices.sort((a, b) => moment(a.appliedDate).valueOf() - moment(b.appliedDate).valueOf());

      // 날짜 내림차순 정렬 //
      prices.sort((a, b) => moment(b.appliedDate).valueOf() - moment(a.appliedDate).valueOf());

      for (let i = 0; i < prices.length; i++) {
        if (moment().isAfter(moment(prices[i].appliedDate))) {
          priceName = prices[i].name;
          price = prices[i].price;
          break;
        }
      }

      return priceName + ' : ' + price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
    }
  }

  // 추가 기념품 구매비용 계산 //
  function decideAdditionalSouvenirPrice() {
    let totalPrice = 0;

    for (let i = 0; i < selectedAdditionalSouvenirs.length; i++) {
      totalPrice += parseInt(selectedAdditionalSouvenirs[i].price * selectedAdditionalSouvenirs[i].count);
    }

    return totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
  }

  // 총 지불금액 계산 //
  function decideTotalPrice() {
    let totalPrice = 0;

    // 참가비 계산 //
    if (selectedEvent.prices.length === 1) totalPrice += selectedEvent.prices[0].price;
    else {
      let prices = selectedEvent.prices.slice();
      // // 날짜 오름차순 정렬 //
      // prices.sort((a, b) => moment(a.appliedDate).valueOf() - moment(b.appliedDate).valueOf());
      // 날짜 내림차순 정렬 //
      prices.sort((a, b) => moment(b.appliedDate).valueOf() - moment(a.appliedDate).valueOf());

      for (let i = 0; i < prices.length; i++) {
        if (moment().isAfter(moment(prices[i].appliedDate))) {
          totalPrice += prices[i].price;
          break;
        }
      }
    }
    // 참가비 계산 //

    // 추가 기념품 계산 //
    for (let i = 0; i < selectedAdditionalSouvenirs.length; i++) {
      totalPrice += parseInt(selectedAdditionalSouvenirs[i].price * selectedAdditionalSouvenirs[i].count);
    }
    // 추가 기념품 계산 //

    return totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '원';
  }


  // 종목 선택 클릭시 //
  function onClickSelectEvent() {
    setIsClickedSelectEvent(true);
  }

  // 공통 기념품 선택 클릭시 //
  function onClickSelectCommonSouvenir(data) {
    setViewSouvenir(data);
    setIsClickedCommonSouvenirSetting(true);
  }

  // 공통 기념품 선택 닫기 //
  function onClickExitCommonSouvenir() {
    setIsClickedCommonSouvenirSetting(false);
    setViewSouvenir(undefined);
  }

  // 공통 기념품 삭제 버튼 클릭시 //
  function onClickDeleteCommonSouvenir(index) {
    let newSelectedCommonSouvenirs = selectedCommonSouvenirs.slice();

    newSelectedCommonSouvenirs.splice(index, 1);

    setSelectedCommonSouvenirs(newSelectedCommonSouvenirs);
  }

  // 추가 기념품 선택 클릭시 //
  function onClickSelectAdditionalSouvenir(data) {
    setViewSouvenir(data);
    setIsClickedAdditionalSouvenirSetting(true);
  }

  // 추가 기념품 삭제 버튼 클릭시 //
  function onClickDeleteAdditionalSouvenir(index) {
    let newSelectedAdditionalSouvenirs = selectedAdditionalSouvenirs.slice();

    newSelectedAdditionalSouvenirs.splice(index, 1);

    setSelectedAdditionalSouvenirs(newSelectedAdditionalSouvenirs);
  }

  // 이전 페이지로 이동 클릭시 //
  function onClickPrevButton() {
    navigate(-1);
  }

  // 다음 페이지로 이동 클릭시 //
  function onClickNextButton() {
    // setIsLoading(true);

    // if (selectedEvent.eventTitle === t('종목을 선택해주세요')) {
    //   alert(t('종목을 선택해주세요'));
    //   return;
    // }
    // else if (selectedEvent.isSoldOut) {
    //   alert(selectedEvent.eventTitle + '\n참가 인원이 마감되었습니다.');
    //   return;
    // }
    // setIsLoading(false);
  }

  // 종목 추가 옵션 클릭시 //
  function onClickDivisionOption(data) {
    setSelectedDivision(data);
  }

  return (
    <TopLevelWrapper>
      {
        isClickedSelectEvent &&
        <EventSelectModal
          UserViewRef={UserViewRef}
          scroll={scroll}
          events={events}
          setSelectedEvent={setSelectedEvent}
          closeFn={() => setIsClickedSelectEvent(false)} />
      }
      {
        isClickedCommonSouvenirSetting &&
        <CommonSouvenirSelectModal
          UserViewRef={UserViewRef}
          scroll={scroll}
          souvenirs={commonSouvenirs}
          viewSouvenir={viewSouvenir}
          selectedSouvenirs={selectedCommonSouvenirs}
          setSelectedSouvenirs={setSelectedCommonSouvenirs}
          closeFn={onClickExitCommonSouvenir} />
      }
      {
        isClickedAdditionalSouvenirSetting &&
        <AdditionalSouvenirSelectModal
          UserViewRef={UserViewRef}
          scroll={scroll}
          souvenirs={additionalSouvenirs}
          viewSouvenir={viewSouvenir}
          selectedSouvenirs={selectedAdditionalSouvenirs}
          setSelectedSouvenirs={setSelectedAdditionalSouvenirs}
          closeFn={() => setIsClickedAdditionalSouvenirSetting(false)} />
      }
      <TotalWrapper>
        <TopBar>
          <Image style={{ transform: 'rotate(90deg)' }} src={DownIcon} />
          <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F' cursor='default'>대회 신청</Text>
          <EmptyBox />
        </TopBar>
        <ComponentWrapper>
          <TitleWrapper style={{ marginTop: '22px' }}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F' cursor='default'>참가 종목과 기념품을 선택해 주세요</Text>
          </TitleWrapper>
          <SelectBoxWrapper style={{ marginTop: '22px' }}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F' cursor='default'>종목 선택</Text>
            <SelectBox onClick={onClickSelectEvent}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#333333' cursor='pointer'>{selectedEvent.name}</Text>
              <Image src={DownIcon} />
            </SelectBox>
          </SelectBoxWrapper>
          <DivisionOptionWrapper>
            {
              selectedEvent.options.map((data, index) => (
                <DivisionOption
                  key={'DivisionOption-' + index}
                  isClicked={data.selectOptionId === selectedDivision.selectOptionId}
                  onClick={() => onClickDivisionOption(data)}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={18} color={data.selectOptionId === selectedDivision.selectOptionId ? `#FFFFFF` : '#333333'} cursor='pointer'>{data.name}</Text>
                </DivisionOption>
              ))
            }
          </DivisionOptionWrapper>
          <DivisionLine />
          {
          commonSouvenirs.length > 0 &&
          <AdditionalWrapper style={{ marginTop: '17px' }}>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F'>공통 기념품 선택</Text>
            </TitleWrapper>
            {
              commonSouvenirs.map((data, index) => (
                <SouvenirBoxWrapper key={'CommonSelect-' + index}>
                  <SelectBoxWrapper style={{ marginTop: '16px' }}>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>{data.name}</Text>
                  </SelectBoxWrapper>
                  <SouvenirImage src={data.souvenirPicture} />
                  <SelectBoxWrapper>
                    <SelectBox onClick={() => onClickSelectCommonSouvenir(data)}>
                      <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#333333'>{t('선택하기')}</Text>
                      <Image src={DownIcon} />
                    </SelectBox>
                  </SelectBoxWrapper>
                  {
                    selectedCommonSouvenirs.map((selectedData, selectedIndex) => (
                      data.souvenirId === selectedData.souvenirId &&
                      <ItemsList key={'SelectedCommon-' + selectedIndex}>
                        <ItemWrapper>
                          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{decideSelectedCommomSouvenirInfoView(selectedData)}</Text>
                          <Image src={GrayExit} onClick={() => onClickDeleteCommonSouvenir(selectedIndex)} />
                        </ItemWrapper>
                      </ItemsList>
                    ))
                  }
                </SouvenirBoxWrapper>
              ))
            }
          </AdditionalWrapper>
        }
          {
            additionalSouvenirs.length > 0 &&
            <AdditionalWrapper>
              <TitleWrapper style={{ marginTop: '24px' }}>
                <Text fontFamily='NotoSansKR-Black' fontSize={18} color='#4F4F4F'>추가 기념품 선택</Text>
              </TitleWrapper>
              {
                additionalSouvenirs.map((data, index) => (
                  <SouvenirBoxWrapper key={'AdditionalSelect-' + index}>
                    <SelectBoxWrapper style={{ marginTop: '16px' }}>
                      <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{`${data.name} ${data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}원`}</Text>
                    </SelectBoxWrapper>
                    { data.souvenirPicture !== '' && <SouvenirImage src={data.souvenirPicture} /> }
                    <SelectBoxWrapper>
                      <SelectBox onClick={() => onClickSelectAdditionalSouvenir(data)}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#333333'>{t('선택하기')}</Text>
                        <Image src={DownIcon} />
                      </SelectBox>
                    </SelectBoxWrapper>
                    {
                      selectedAdditionalSouvenirs.map((selectedData, selectedIndex) => (
                        data.souvenirId === selectedData.souvenirId &&
                        <ItemsList key={'SelectedCommon-' + selectedIndex}>
                          <ItemWrapper>
                            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{decideSelectedAdditionalSouvenirInfoView(selectedData)}</Text>
                            <Image src={GrayExit} onClick={() => onClickDeleteAdditionalSouvenir(selectedIndex)} />
                          </ItemWrapper>
                        </ItemsList>
                      ))
                    }
                  </SouvenirBoxWrapper>
                ))
              }
            </AdditionalWrapper>
          }
          <DivisionLine style={{ marginTop: `36px` }} />
          <PriceBoxWrapper>
            <PriceLineWrapper style={{ marginTop: `0px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('참가비')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{decideParticipantFee()}</Text>
            </PriceLineWrapper>
            <PriceLineWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('추가 기념품')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{decideAdditionalSouvenirPrice()}</Text>
            </PriceLineWrapper>
          </PriceBoxWrapper>
          <DivisionLine />
          <PriceBoxWrapper>
            <PriceLineWrapper style={{ marginTop: `0px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F'>{t('총 주문 금액')}</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={15} color='#595959'>{decideTotalPrice()}</Text>
            </PriceLineWrapper>
          </PriceBoxWrapper>
        </ComponentWrapper>
      </TotalWrapper>
      <NextButtonWrapper>
        <NewButton onClick={onClickNextButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('다음')}</Text>
        </NewButton>
      </NextButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  width: 100%;
  min-height: 100%;

  border-radius: 10px;
  background-color: #F9F9F9;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 20px;

  width: calc(100% - 40px);
  min-height: 44px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const AdditionalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SouvenirBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const SelectBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 14px 0px 14px;
  margin: 8px 0px 0px 0px;

  width: calc(100% - 30px);
  height: 49px;

  border: 1px solid #EDEDED;
  border-radius: 8px;
  background-color: #F9F9F9;

  :hover {
    cursor: pointer;
  }
`;

const DivisionOptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const DivisionOption = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 28px;

  width: calc(48% - 3px);
  height: 55px;

  border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #EDEDED`};
  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#F9F9F9`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const ItemsList = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;
const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 18px 0px 18px 0px;

  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const PriceBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const PriceLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  margin-top: 36px;

  width: 100%;
`;

const SouvenirImage = styled.img`
  margin-top: 12px;

  width: 100%;
  height: 100%;
`;

const Image = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const EmptyBox = styled.div`
  width: 20px;
  height: 20px;
`;

const NewButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;