import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";

export default function EventTextArea(props) {
  const { value, setValue, placeholder } = props;

  const [isFirst, setIsFirst] = useState(true);

  const TextRef = useRef();

  useEffect(() => {
    if (isFirst) {
      TextRef.current.style.height = '100px';
      
      setTimeout(() => {
        setIsFirst(false);
      }, 100)
    }
    else {
      TextRef.current.style.height = 'auto';
      TextRef.current.style.height = TextRef.current.scrollHeight + 'px';
    }
  }, [value, isFirst])

	function handleChange(e) {
    setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

  return (
    <InputView>
      <Input
        ref={TextRef}
        placeholder={placeholder}
        value={value}
        type='text'
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur} />
    </InputView>
  )
}

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 15px 15px;

  width: calc(100% - 34px);

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;
`;

const Input = styled.textarea`
	font-family: NotoSansKR-Regular;
	font-size: 14px;

	width: 100%;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
  resize: none;
	
	color: #FFFFFF;

  border: none;
  background-color: #2F2F32;

	::placeholder {
		color: #ECECEC;
	}
  
  transition: all 0.3s ease-in-out;
`;