import React, { forwardRef, useImperativeHandle, useState, useRef, useEffect } from 'react';
// import logoImage from '../../../image/LinkLogo.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import FetchModule from '../../Model/Network/FetchModule';

// import HomeImagePath from '../../../image/Home.png';
// import MemberImagePath from '../../../image/Member.png';
// import ClassImagePath from '../../../image/Class.png';
// import ChartImagePath from '../../../image/Chart.png';
// import LockerImagePath from '../../../image/Locker.png';
// import BoardImagePath from '../../../image/Board.png';
// import NavNoticeImagePath from '../../../image/NavNotice.png';
// import NoteImagePath from '../../../image/Note.png';
// import NavSettingImagePath from '../../../image/NavSetting.png';

// import HoverHomeImagePath from '../../../image/HoverHome.png';
// import HoverMemberImagePath from '../../../image/HoverMember.png';
// import HoverClassImagePath from '../../../image/HoverClass.png';
// import HoverChartImagePath from '../../../image/HoverChart.png';
// import HoverLockerImagePath from '../../../image/HoverLocker.png';
// import HoverBoardImagePath from '../../../image/HoverBoard.png';
// import HoverNavNoticeImagePath from '../../../image/HoverNavNotice.png';
// import HoverNoteImagePath from '../../../image/HoverNote.png';
// import HoverNavSettingImagePath from '../../../image/HoverNavSetting.png';

// import ClickHomeImagePath from '../../../image/ClickHome.png';
import MemberImagePath from '../../Image/ClickMember.png';
import NoteImagePath from '../../Image/ClickNote.png';
import SettingImagePath from '../../Image/ClickNavSetting.png';

// import ClickClassImagePath from '../../../image/ClickClass.png';
// import ClickChartImagePath from '../../../image/ClickChart.png';
// import ClickLockerImagePath from '../../../image/ClickLocker.png';
// import ClickBoardImagePath from '../../../image/ClickBoard.png';
// import ClickNavNoticeImagePath from '../../../image/ClickNavNotice.png';
// import ClickNoteImagePath from '../../../image/ClickNote.png';
// import ClickNavSettingImagePath from '../../../image/ClickNavSetting.png';

const NavigationBar = forwardRef((props, ref) => {
  const { nowPage, setNowPage, defaultURL } = props;

  const navigate = useNavigate();

  const [index, setIndex] = useState(0);
  // const [nowPage, setNowPage] = useState(nowPageURL);
  const [nowPageKey, setNowPageKey] = useState('홈');
  const [isExistPartnerGym, setIsExistPartnerGym] = useState(false);
  const [registrationCount, setRegistrationCount] = useState(0);

  const location = useLocation(); // URL 감지용

  useImperativeHandle(ref, () => ({
  }));

  let NavInfo = [{
    buttonRef: useRef(),
    img: '',
    text: "STEP.1 신청",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/main'],
  },
  {
    buttonRef: useRef(),
    img: '',
    text: "STEP.2 상품",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/product'],
  },
  {
    buttonRef: useRef(),
    img: '',
    text: "STEP.3 약관",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/terms'],
  },
  {
    buttonRef: useRef(),
    img: MemberImagePath,
    text: "인원",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/unit'],
  },
  // {
  //   buttonRef: useRef(),
  //   img: NoteImagePath,
  //   text: "노트",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [],
  //   destination: ['/note'],
  // },
  // {
  //   buttonRef: useRef(),
  //   text: "공지",
  //   imgRef: useRef(),
  //   textRef: useRef(),
  //   subRef: useRef(),
  //   children: [],
  //   destination: ['/board'],
  // },
  {
    buttonRef: useRef(),
    img: SettingImagePath,
    text: "설정",
    imgRef: useRef(),
    textRef: useRef(),
    subRef: useRef(),
    children: [],
    destination: ['/setting'],
  }]


  useEffect(() => {
    let path = location.pathname;

    if (path.includes('/main')) {
      setNowPageKey('STEP.1 신청');
      setIndex(0);
    }
    else if (path.includes('/product')) {
      setNowPageKey('STEP.2 상품');
      setIndex(1)
    }
    else if (path.includes('/terms')) {
      setNowPageKey('STEP.3 약관');
      setIndex(2);
    }
    else if (path.includes('/unit')) {
      setNowPageKey('인원');
      setIndex(3);
    }
    // else if (path.includes('/note')) {
    //   setNowPageKey('노트');
    //   setIndex(4);
    // }
    // else if (path.includes('/board')) {
    //   setNowPageKey('공지');
    //   setIndex(5);
    // }
    else if (path.includes('/setting')) {
      setNowPageKey('설정');
      setIndex(4);
      // setIndex(5);
    }

    setNowPage(defaultURL + location.pathname);
  }, [location])

  useEffect(() => {
    for (let i = 0; i < NavInfo.length; i++) {
      if (index !== i) {
        if (NavInfo[i].textRef.current !== null && NavInfo[i].textRef.current !== undefined) NavInfo[i].textRef.current.style.color = `#FFFFFF`;
        if (NavInfo[i].buttonRef.current !== null && NavInfo[i].buttonRef.current !== undefined) NavInfo[i].buttonRef.current.style.backgroundColor = `#000000`;
      }
    }

    if (NavInfo[index].textRef.current !== null && NavInfo[index].textRef.current !== undefined) NavInfo[index].textRef.current.style.color = `#FFFFFF`;
    if (NavInfo[index].buttonRef.current !== null && NavInfo[index].buttonRef.current !== undefined) NavInfo[index].buttonRef.current.style.backgroundColor = `#6DD49E`;

    if (!nowPage.includes('/unit')){
      window.localStorage.removeItem('UnitApprovalDateSort');
      window.localStorage.removeItem('UnitNowPage');
    }
  }, [index, nowPage, isExistPartnerGym])

  if (!nowPage.includes('/main')
    && !nowPage.includes('/product')
    && !nowPage.includes('/terms')
    && !nowPage.includes('/unit')
    && !nowPage.includes('/note')
    && !nowPage.includes('/setting')) return null;


  function clickButton(index) {
    setNowPageKey(NavInfo[index].text);
    setIndex(index);

    if (NavInfo[index].children.length === 0) navigate(NavInfo[index].destination[0]);
  }

  function focusButton(index) {
    if (NavInfo[index].text === 'STEP.1 신청' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#6DD49E`;
    }
    else if (NavInfo[index].text === 'STEP.2 상품' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#6DD49E`;
    }
    else if (NavInfo[index].text === 'STEP.3 약관' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.background = `#6DD49E`;
    }
    else if (NavInfo[index].text === '인원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.background = `#6DD49E`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.background = `#6DD49E`;
    }
  }

  function blurButton(index) {
    if (NavInfo[index].text === 'STEP.1 신청' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === 'STEP.2 상품' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === 'STEP.3 약관' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === '인원' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === '노트' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
    else if (NavInfo[index].text === '설정' && NavInfo[index].text !== nowPageKey) {
      NavInfo[index].textRef.current.style.color = `#FFFFFF`;
      NavInfo[index].buttonRef.current.style.backgroundColor = `#000000`;
    }
  }

  return (
    <TopLevelWrapper>
      {
        NavInfo.map((data, index) => (
          <TotalWrapper key={index}>
            <Button ref={data.buttonRef} onClick={() => clickButton(index)} onMouseOver={() => focusButton(index)} onMouseLeave={() => blurButton(index)} >
              {data.img !== '' && <Img img={data.img} hoverImg={data.hoverImg} index={index} />}
              <ButtonText ref={data.textRef}>{data.text}</ButtonText>
            </Button>
            { index === 2 && <DivisionLine/> }
          </TotalWrapper>
        ))
      }
    </TopLevelWrapper>
  )
})

export default NavigationBar;

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  margin-right: 20px;

  width: 120px;
  
  gap: 8px;

  background-color: #000000;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;
`;

const CountComponent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: -12px;

  width: 24px;
  height: 24px;

  border-radius: 24px;
  background-color: #FF3131;

  :hover {
    cursor: default;
  }
`;

const Button = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 48px;
  
  border-radius: 30px;

  // margin-bottom: 8px;

  gap: 16px;

  background-color: #000000;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const Img = styled.div`
  display: flex;
  flex-direction: row;

  width: 24px;
  height: 24px;

  justify-content: center;
  align-items: center;

  background: url(${props => props.img}) no-repeat center center;
            
  :hover {
    cursor: pointer;
  }
  
  transition: all 0.3s ease-in-out;
`;

const ButtonText = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 18px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  transition: all 0.3s ease-in-out;
`;

const SubButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 0px;

  border-radius: 24px;

  background-color: #F6F4F2;
  
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const SubWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
  height: 48px;
`;

const SubButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 120px;
  height: 48px;

  background-color: ${props => props.isClicked ? `#FF8B48` : `#F6F4F2`};
  
  font-family: NotoSansKR-Medium;
  font-size: 14px;

  color: ${props => props.isClicked ? `#FFFFFF` : `#BEBEBE`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    color: ${props => props.isClicked ? `#FFFFFF` : `#FF8B48`};
  }
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 20px 0px 12px 0px;

  width: 100%;
  height: 1px;

  border-radius: 2px;
  background-color: #FFFFFF;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }
`;