import React, { useEffect, useState } from "react";
import styled, { keyframes }  from "styled-components";
import MergeCalenderInput from "./MergeCalenderInput";

export default function DateSelectModal(props) {
  const { 
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    close
  } = props;

  const [startValue, setStartValue] = useState(startDate);
  const [endValue, setEndValue] = useState(endDate);

  // 시작날짜 정리 //
  useEffect(() => {
    setStartValue(startDate);
  }, [startDate])

  // 종료날짜 정리 //
  useEffect(() => {
    setEndValue(endDate);
  }, [endDate])

  // 저장하기 클릭시 //
  function onClickSave() {
    setStartDate(startValue);
    setEndDate(endValue);
    close();
  }

  return (
    <TopLevelWrapper>
      <ModalView>
        <ModalInnerView>
          <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#000000' cursor='default'>이벤트 신청 기간</Text>
          <MergeCalenderInput 
            startValue={startValue} 
            setStartValue={setStartValue}
            endValue={endValue}
            setEndValue={setEndValue}/>
          <DivisionLine/>
          <BottomButtonWrapper>
            <ExitButton onClick={close}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#CBCBCB' cursor='pointer'>취소</Text>
            </ExitButton>
            <SaveButton onClick={onClickSave}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>저장하기</Text>
            </SaveButton>
          </BottomButtonWrapper>
        </ModalInnerView>
      </ModalView>
    </TopLevelWrapper>
  );
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);

	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const ModalView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px;

  width: 1002px;
  // height: 632px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const ModalInnerView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 94.41%;
`;

const DivisionLine = styled.div`
  margin: 24px 0px;

  width: 100%;
  height: 2px;

  background-color: #ECECEC;
`;

const BottomButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 16px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 72px;
  height: 38px;

  border:1px solid #DFDFDF;
  border-radius: 15px;

  :hover {
    cursor: pointer;
  }
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 15px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;