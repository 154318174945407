import React, { useState, useRef } from 'react';
import styled from 'styled-components';

export default function TeamNameAndBelongInput(props) {
	const {
    value, 
    setValue, 
    placeholder 
  } = props;
  

	function handleChange(e) {
		setValue(e.target.value);
	}

	function handleFocus(e) {
	}

	function handleBlur(e) {
	}

	return (
    <InputWrapper>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}/>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 22px);
  height: 26px;

  border: 1px solid #B2B2B2;
  border-radius: 5px;
`;

const Input = styled.input`
	font-family: NotoSansKR-Regular;
	font-size: 12px;
	line-height: 20px;

  width: 100%;
  height: 100%;

	outline: none;
  border: 0px solid #000000;
  background-color: transparent;

	color: #FFFFFF;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;