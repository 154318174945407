import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";
import XLSX from 'sheetjs-style';

import FetchModule from "../../../Model/Network/FetchModule";
import SearchInput from "../Component/SearchInput";
import DataColumnBox from "../Model/DataColumnBox";
import DataViewBox from "../Model/DataViewBox";
import Pagenation from "../Model/Pagenation";
import ListDownloadLoading from "../Model/ListDownloadLoading";

import EmptyLogo from '../../../Image/EventerEmptyLogo.png';
import DocumentIcon from '../../../Image/DocumentIcon.png';

export default function UnitPage(props) {

  const navigate = useNavigate();
  const maxTeamCount = 10;

  const [organizerId, setOrganizerId] = useState(0);
  const [teams, setTeams] = useState([]);
  const [value, setValue] = useState('');
  const [totalMemberCount, setTotalMemberCount] = useState(0);
  const [events, setEvents] = useState([]);
  const [division, setDivision] = useState([]);
  const [nowPage, setNowPage] = useState(0);

  const [sortedData, setSortedData] = useState([]);
  const [divideData, setDivideData] = useState([]);

  const [clickedEventId, setClickedEventId] = useState(-1);
  const [clickedSelectOptionId, setClickedSelectOptionId] = useState(-1);
  const [approvalDateSort, setApprovalDateSort] = useState('desc');

  const [isDownload, setIsDownload] = useState(false);

  // 이전 회원 정렬 방법 불러오기 //
  useEffect(() => {
    let approvalDateSort = window.localStorage.getItem('UnitApprovalDateSort');
    let nowPage = window.localStorage.getItem('UnitNowPage');
    
    setApprovalDateSort(approvalDateSort === null ? 'desc' : approvalDateSort);
    setNowPage(parseInt(nowPage === null ? 0 : nowPage));
  }, [])

  // 데이터 불러오기 //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/participant/', 'GET');
        console.log(responseData.data);
        if (responseData.status === 200) {
          let events = responseData.data.events;
          let teams = responseData.data.teams;

          let totalMemberCount = 0;

          for (let i = 0; i < teams.length; i++) {
            totalMemberCount += teams[i].members.length;
          }

          teams.sort((a, b) => a.eventId - b.eventId);

          setOrganizerId(responseData.data.organizerId);
          setTeams(teams);
          setEvents(events);
          setTotalMemberCount(totalMemberCount);
        }
      }
    )();
  }, []);

  // 검색 기능 포함 ( 한글 검색 알고리즘 최신화 ) //
  useEffect(() => {
    let eventTeams = [];

    if (clickedEventId === -1) eventTeams = teams.slice();
    else {
      for (let i = 0; i < teams.length; i++) {
        if (teams[i].eventId === clickedEventId) eventTeams.push(teams[i]);
      }
    }

    let divisionTeams = [];

    if (clickedSelectOptionId === -1) divisionTeams = eventTeams.slice();
    else {
      for (let i = 0; i < eventTeams.length; i++) {
        if (eventTeams[i].selectOptionId === clickedSelectOptionId) divisionTeams.push(eventTeams[i]);
      }
    }

    let teamNameList = [];
    let memberNameList = [];
    let memberPhoneList = [];

    for (let i = 0; i < divisionTeams.length; i++) {
      if (divisionTeams[i].teamName.toLowerCase().includes(value.toLowerCase())
        || match(value.toLowerCase(), divisionTeams[i].teamName.toLowerCase())) teamNameList.push(divisionTeams[i]);
      else {
        for (let j = 0; j < divisionTeams[i].members.length; j++) {
          if (divisionTeams[i].members[j].name.toLowerCase().includes(value.toLowerCase())
            || match(value.toLowerCase(), divisionTeams[i].members[j].name.toLowerCase())) {
            memberNameList.push(divisionTeams[i]);
            break;
          }
          else if (divisionTeams[i].members[j].phone.toLowerCase().includes(value.toLowerCase())) {
            memberPhoneList.push(divisionTeams[i]);
            break;
          }
        }
      }
    }

    let totalList = teamNameList.concat(memberNameList).concat(memberPhoneList);
    let newDataList = [];

    if (approvalDateSort === 'asc') totalList.sort((a, b) => (moment(a.approvalDate).valueOf() - moment(b.approvalDate).valueOf()));
    else totalList.sort((a, b) => (moment(b.approvalDate).valueOf() - moment(a.approvalDate).valueOf()));

    // 페이지 분리 //
    if (totalList.length !== 0) {
      if (totalList.length > maxTeamCount) newDataList = totalList.slice((nowPage * maxTeamCount), (nowPage * maxTeamCount) + maxTeamCount);
      else newDataList = totalList.slice();
    }

    setSortedData(totalList);
    setDivideData(newDataList);

    window.localStorage.setItem('UnitApprovalDateSort', approvalDateSort);
    window.localStorage.setItem('UnitNowPage', nowPage);
  }, [
    nowPage,
    teams,
    value,
    clickedEventId,
    clickedSelectOptionId,
    approvalDateSort,
    match()
  ]);


  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;

    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
      || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }

      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
      , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;

    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }

    return false;
  }

  // 이벤트 정렬 버튼 클릭시 //
  function onClickEventSort(index) {
    if (index === -1) {
      setClickedEventId(-1);
      setClickedSelectOptionId(-1);
    }
    else {
      setDivision(events[index].selectOptions);
      setClickedEventId(events[index].eventId);
      setClickedSelectOptionId(-1);
    }
  }

  // 다음 페이지 클릭시 //
  function onClickNextPage() {
    if (nowPage + 1 < Math.ceil(sortedData.length / maxTeamCount)) setNowPage(nowPage + 1);
  }

  // 이전 페이지 클릭시 //
  function onClickPrevPage() {
    if ((nowPage - 1) >= 0) setNowPage(nowPage - 1);
  }

  // 회원 정보 클릭시 //
  function onClickTeamInfo(data) {
    if (data.teamId === undefined || data.teamId === 0) navigate('/unit/member/' + data.memberId);
    else navigate('/unit/team/' + data.teamId);
  }

  // 참가자 명단 다운로드 클릭시 //
  async function onClickDownloadMemberList() {
    if (isDownload) {
      alert('추출을 진행중입니다!');
      return;
    }
    
    setIsDownload(true);


    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/participant_list/' + organizerId, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {

          let dates = responseData.data.slice();
          let finalList = [];

          dates.sort((a, b) => b.eventId - a.eventId);
          
          for (let i = 0; i < dates.length; i++) {

            let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;
            let salesDetails = '';
            
            for (let j = 0; j < dates[i].salesDetailSet.length; j++) {
              if (j !== 0) salesDetails +=  ', ';

              if (dates[i].salesDetailSet[j].souvenirId !== 0) {
                let size = dates[i].salesDetailSet[j].size;
                let option = dates[i].salesDetailSet[j].option;

                if (size !== undefined && option !== undefined && size !== '' && option !== '') {
                  salesDetails += dates[i].salesDetailSet[j].itemName + ' (' + option + ' - ' +  size + ') ' + ' x ' + dates[i].salesDetailSet[j].count;
                }
                else if (option !== undefined && option !== '') {
                  salesDetails += dates[i].salesDetailSet[j].itemName + ' (' + option + ') ' + ' x ' + dates[i].salesDetailSet[j].count;
                }
                else if (size !== undefined && size !== '') {
                  salesDetails += dates[i].salesDetailSet[j].itemName + ' (' + size + ') ' + ' x ' + dates[i].salesDetailSet[j].count;
                }
                else {
                  salesDetails += dates[i].salesDetailSet[j].itemName + ' x ' + dates[i].salesDetailSet[j].count;
                }
              }
              else salesDetails += salesDetails += dates[i].salesDetailSet[j].itemName;
            }

            // let division = '남/남';

            // if (dates[i].selectOptionId === 26) division = '여/여';
            // else if (dates[i].selectOptionId === 27) division = '남/여';

            // 기본 객체 생성
            let teamInfo = {
              // teamId: dates[i].teamId,
              createdDate: dates[i].createdDate,
              eventName: dates[i].eventName,
              optionName: dates[i].selectOptionName,
              profile: dates[i].profile,
              teamName: dates[i].teamName,
              // division: division,
              address: address,
              belong: dates[i].members[0].belong,
            };

            // 동적으로 members 정보 추가
            dates[i].members.forEach((member, index) => {
              let memberIndex = index + 1; // 1부터 시작하도록 설정
              teamInfo[`memberName${memberIndex}`] = member.name;
              teamInfo[`memberPhone${memberIndex}`] = member.phone;
              teamInfo[`memberSize${memberIndex}`] = member.bloodType;
              teamInfo[`memberGender${memberIndex}`] = member.gender;
              // 필요한 경우 추가 필드 설정 가능
            });
            
            teamInfo.merchantUid = dates[i].merchantUid;
            teamInfo.totalPrice = dates[i].totalPrice;
            teamInfo.salesDetails = salesDetails;

            finalList.push(teamInfo);
          }
          
          let title = '이벤트 신청자 명단.xlsx';

          // finalList.sort((a, b) => a.teamId - b.teamId);
          // console.log(finalList);
          const ws = await XLSX.utils.json_to_sheet(finalList);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Member")
          XLSX.writeFile(wb, title);

          // console.log(finalList)
          setTimeout(() => {
            alert('명단 추출이 완료되었습니다.');
            setIsDownload(false);
          }, 500)
        }
      }
    )();
  }

  useEffect(() => {
    console.log(clickedSelectOptionId);
  }, [clickedSelectOptionId])
  return (
    <TopLevelWrapper>
      { isDownload && <ListDownloadLoading/> }
      <TopBarWrapper>
        <TextWrapper>
          <TitleComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>등록 회원</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>{'(총 ' + totalMemberCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '명)'}</Text>
            </TitleWrapper>
          </TitleComponentWrapper>
          <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>등록된 모든 회원을 볼 수 있어요</Text>
        </TextWrapper>
        <SearchInput value={value} setValue={setValue} placeholder='회원의 이름이나 전화번호를 검색해주세요' />
      </TopBarWrapper>
      <SortBubttonWrapper>
        <TotalLayerWrapper>
          <LayerWrapper style={{ width: '80%' }}>
            <SortButton
              isClicked={clickedEventId === -1}
              onClick={() => onClickEventSort(-1)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={clickedEventId === -1 ? `#FFFFFF` : `#DCD4CF`} cursor='pointer'>전체</Text>
            </SortButton>
            {
              events.map((data, index) => (
                <SortButton
                  key={'EventSortButton-' + index}
                  isClicked={clickedEventId === data.eventId}
                  onClick={() => onClickEventSort(index)}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={clickedEventId === data.eventId ? `#FFFFFF` : `#DCD4CF`} cursor='pointer'>{data.eventTitle}</Text>
                </SortButton>
              ))
            }
          </LayerWrapper>
          <ListDownloadButton onClick={onClickDownloadMemberList}>
            <Icon src={DocumentIcon}/>
            <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#FFFFFF' cursor='pointer'>참가자 명단 다운 받기</Text>
          </ListDownloadButton>
        </TotalLayerWrapper>
        <LayerWrapper style={clickedEventId === -1 ? { opacity: 0, zIndex: -3 } : {}}>
          {
            // division.length > 0 &&
            <SortButton
              style={division.length > 0 ? {opacity: 1} : {opacity: 0}}
              isClicked={clickedSelectOptionId === -1}
              onClick={() => setClickedSelectOptionId(-1)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={clickedSelectOptionId === -1 ? `#FFFFFF` : `#DCD4CF`} cursor='pointer'>전체</Text>
            </SortButton>
          }
          {
            division.map((data, index) => (
              <SortButton
                key={'EventSortButton-' + index}
                isClicked={clickedSelectOptionId === data.selectOptionId}
                onClick={() => setClickedSelectOptionId(data.selectOptionId)}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={clickedSelectOptionId === data.selectOptionId ? `#FFFFFF` : `#DCD4CF`} cursor='pointer'>{data.name}</Text>
              </SortButton>
            ))
          }
        </LayerWrapper>
      </SortBubttonWrapper>
      <DataColumnBox
        approvalDateSort={approvalDateSort}
        setApprovalDateSort={setApprovalDateSort} />
      {
        divideData.length !== 0 ?
          <MemberListWrapper>
            {
              divideData.map((data, index) => (
                <DataViewBox
                  key={'MemberList-' + index}
                  events={events}
                  data={data} 
                  onClickTeamInfo={onClickTeamInfo}/>
              ))
            }
          </MemberListWrapper>
          :
          <EmptyMemberListBox>
            <EmptyImage src={EmptyLogo}/>
            <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#454549' cursor='pointer'>등록된 회원 정보가 없습니다</Text>
          </EmptyMemberListBox>
      }
      <Pagenation
        nowPage={nowPage}
        totalPage={Math.ceil(sortedData.length / maxTeamCount) === 0 ? 1 : Math.ceil(sortedData.length / maxTeamCount)}
        onClickNext={onClickNextPage}
        onClickPrev={onClickPrevPage} />
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-left: 20px;

  width: calc(100% - 20px);
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  gap: 4px;
`;

const TitleComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  gap: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

const SortBubttonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin: 28px 0px 0px 20px;

  width: calc(100% - 20px);

  gap: 10px;
`;

const TotalLayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 10px;
`;

const ListDownloadButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;

  height: 32px;

  gap: 4px;

  border-radius: 10px;
  background-color: #6DD49E;

  :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  width: 19px;
  height: 19px;
`;

const SortButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 5px 20px;

  border: ${props => props.isClicked ? `1px solid #6DD49E` : `1px solid #2F2F32`};
  border-radius: 10px;
  background-color:${props => props.isClicked ? `#6DD49E` : `#17171B`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const MemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 24px;

  width: 100%;
  height: 590px;

  gap: 10px;
`;

const EmptyMemberListBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 590px;

  gap: 10px;

  border-radius: 30px;
  background-color: #17171B;
`;

const EmptyImage = styled.img`
  width: 187px;
  height: 140px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;
