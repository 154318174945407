import { React } from 'react';
import styled from 'styled-components';

export default function LoginSubject(props) {
	return (
		<TopLevelWrapper>
			<CategoryWrapper>
        <Text fontFamily='NotoSansKR-Bold' fontSize={24} color='#FFFFFF' cursor='default'>로그인</Text>
				<PaginationWrapper>
					<Pagination />
					<Pagination />
					<Pagination />
					<Pagination />
				</PaginationWrapper>
			</CategoryWrapper>
			<CategoryExplanationWrapper>
				<Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#FFFFFF' cursor='default'>이벤트 관리자 로그인</Text>
			</CategoryExplanationWrapper>
		</TopLevelWrapper>
	)
}

const TopLevelWrapper = styled.div`
	display: flex;
	flex-direction: column;

  transition: all 0.5s ease-in-out;
`;

const CategoryWrapper = styled.div`
	display: flex;
	flex-direction: row;

	align-items: center;
`;

const PaginationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin: 0 0 0 262px;
	gap: 4px;
	opacity: 0;
`;

const Pagination = styled.div`
	width: 25px;
	height: 8px;

	border-radius: 2px;
	background-color: #33C8FF;
`;

const CategoryExplanationWrapper = styled.div`
	display: flex;
	flex-direction: row;

	margin-top: 13px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;