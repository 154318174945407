import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import styled from "styled-components";
import Lottie from 'lottie-react-web';
import XLSX from 'sheetjs-style';

import LoadingAnimation from '../../Animation/LoadingAnimation.json';
import FetchModule from "../../Model/Network/FetchModule";

export default function FitForceTeamLeague2025ParticipantListPage() {

  const params = useParams();
  const [isLoading, setIsLoading] = useState(false);


  function onClickMakeExcel(eventId) {
    if (isLoading) {
      alert('추출을 진행중입니다!');
      return;
    }
    
    setIsLoading(true);

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/event/participant/' + eventId, 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {

          let dates = responseData.data.slice();
          let finalList = [];

          for (let i = 0; i < dates.length; i++) {

            let address = dates[i].members[0].address + ' ' + dates[i].members[0].addressDetail + ' ' + dates[i].members[0].zipCode;
            let salesDetails = '';

            for (let j = 0; j < dates[i].salesDetailSet.length; j++) {
              // if (j === 0) salesDetails += dates[i].salesDetailSet[j].itemName + ' x ' + dates[i].salesDetailSet[j].count;
              // else salesDetails +=  ', ' + dates[i].salesDetailSet[j].itemName + ' x ' + dates[i].salesDetailSet[j].count;
              if (j !== 0) salesDetails +=  ', ';

              if (dates[i].salesDetailSet[j].souvenirId === 0) salesDetails += dates[i].salesDetailSet[j].itemName + ' 참가비';
              else {
                let size = 'S';
                
                if (dates[i].salesDetailSet[j].souvenirSizeId === 87) size = 'S';
                else if (dates[i].salesDetailSet[j].souvenirSizeId === 88) size = 'M';
                else if (dates[i].salesDetailSet[j].souvenirSizeId === 89) size = 'L';
                else if (dates[i].salesDetailSet[j].souvenirSizeId === 90) size = 'XL';
                else if (dates[i].salesDetailSet[j].souvenirSizeId === 91) size = 'XXL';
                else if (dates[i].salesDetailSet[j].souvenirSizeId === 92) size = 'XXXL';

                salesDetails += dates[i].salesDetailSet[j].itemName + ' (' + size + ') ' + ' x ' + dates[i].salesDetailSet[j].count;
              }
              // if (dates[i].salesDetailSet[j] !== 0) salesDetails += dates[i].salesDetailSet[j].itemName + ' 참가비';
              // else salesDetails += dates[i].salesDetailSet[j].itemName + ' x ' + dates[i].salesDetailSet[j].count;
            }

            let division = '남/남';

            if (dates[i].selectOptionId === 26) division = '여/여';
            else if (dates[i].selectOptionId === 27) division = '남/여';

            // finalList.push({
            //   teamId: dates[i].teamId,
            //   createdDate: dates[i].createdDate,
            //   profile: dates[i].profile,
            //   teamName: dates[i].teamName,
            //   division: division,
            //   address: address,
            //   belong: dates[i].members[0].belong,
            //   memberName1: dates[i].members[0].name,
            //   memberPhone1: dates[i].members[0].phone,
            //   memberSize1: dates[i].members[0].bloodType,
            //   memberGender1: dates[i].members[0].gender,
            //   // memberBirth1: dates[i].members[0].birth,
            //   memberName2: dates[i].members[1].name,
            //   memberPhone2: dates[i].members[1].phone,
            //   memberSize2: dates[i].members[1].bloodType,
            //   memberGender2: dates[i].members[1].gender,
            //   // memberBirth2: dates[i].members[1].birth,
            //   // memberName3: dates[i].members[2].name,
            //   // memberPhone3: dates[i].members[2].phone,
            //   // memberBirth3: dates[i].members[2].birth,
            //   // memberName4: dates[i].members[3].name,
            //   // memberPhone4: dates[i].members[3].phone,
            //   // memberBirth4: dates[i].members[3].birth,
            //   merchantUid: dates[i].merchantUid,
            //   totalPrice: dates[i].totalPrice,
            //   salesDetails: salesDetails
            //   // salesList: salesList
            // })

            // 기본 객체 생성
            let teamInfo = {
              teamId: dates[i].teamId,
              createdDate: dates[i].createdDate,
              profile: dates[i].profile,
              teamName: dates[i].teamName,
              division: division,
              address: address,
              belong: dates[i].members[0].belong,
              // merchantUid: dates[i].merchantUid,
              // totalPrice: dates[i].totalPrice,
              // salesDetails: salesDetails
            };

            // 동적으로 members 정보 추가
            dates[i].members.forEach((member, index) => {
              let memberIndex = index + 1; // 1부터 시작하도록 설정
              teamInfo[`memberName${memberIndex}`] = member.name;
              teamInfo[`memberPhone${memberIndex}`] = member.phone;
              teamInfo[`memberSize${memberIndex}`] = member.bloodType;
              teamInfo[`memberGender${memberIndex}`] = member.gender;
              // 필요한 경우 추가 필드 설정 가능
            });
            
            teamInfo.merchantUid = dates[i].merchantUid;
            teamInfo.totalPrice = dates[i].totalPrice;
            teamInfo.salesDetails = salesDetails;

            finalList.push(teamInfo);
          }
          
          let title = 'Fit Force Team League 2025';

          if (eventId === 58) title += ' - Elite';
          else if (eventId === 59) title += ' - Rxd';
          else if (eventId === 60) title += ' - Scaled';

          finalList.sort((a, b) => a.teamId - b.teamId);
          // console.log(finalList);
          const ws = await XLSX.utils.json_to_sheet(finalList);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Member")
          XLSX.writeFile(wb, title + ' 신청자 명단.xlsx');

          // console.log(finalList)
          setTimeout(() => {
            alert('명단 추출이 완료되었습니다.');
            setIsLoading(false);
          }, 500)
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {
        isLoading ?
          <LottieWrapper>
            <LottieBox>
              <Lottie options={{ animationData: LoadingAnimation }} />
            </LottieBox>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#000000'>명단 추출 진행중...</Text>
          </LottieWrapper>
          :
          <CompleteModal>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#333333'>이벤트 신청자 명단 추출</Text>
            <TextWrapper>
            </TextWrapper>
            <GoAppButton style={{ marginTop: '12px' }}  onClick={() => onClickMakeExcel(83)}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF'>추출하기</Text>
            </GoAppButton>
          </CompleteModal>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  // background-color: #FFFFFF;
  background-color: rgba(0, 0, 0, 0.2);
`;

const LottieWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  gap: 8px;
`;

const LottieBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 60px;
  height: 60px;
`;

const CompleteModal = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 24px 0px 12px 0px;

  width: 80%;
  max-width: 400px;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 90.33%;
`;

const GoAppButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 90.33%;
  height: 40px;

  border-radius: 8px;
  background-color: #6DD49E;
`;


const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize};

  color: ${props => props.color};

  margin: 0 0 0 0;
`;