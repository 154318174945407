import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import lodash from 'lodash';

import FetchModule from "../../../Model/Network/FetchModule";
import useWindowSize from "../../Share/useWindowSize";

import CommonSouvenirEditView from "../Model/CommonSouvenirEditView";
import AdditionalSouvenirEditView from "../Model/AdditionalSouvenirEditView";
import SelectPageUserView from "../Model/SelectPageUserView";

import EmptyLogo from '../../../Image/EventerEmptyLogo.png';

export default function ProductPage(props) {

  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [division, setDivision] = useState('Common');

  // Original Data //
  const [originalCommonSouvenirs, setOriginalCommonSouvenirs] = useState([]);
  const [originalAdditionalSouvenirs, setOriginalAdditionalSouvenirs] = useState([]);
  // Original Data //

  // 변경 데이터 //
  const [events, setEvents] = useState([]);
  const [commonSouvenirs, setCommonSouvenirs] = useState([]);
  const [additionalSouvenirs, setAdditionalSouvenirs] = useState([]);
  // 변경 데이터 //

  // 사진 크기 // 
  const [pictureWidth, setPictureWidth] = useState(343);
  const [pictureHeight, setPictureHeight] = useState(148);

  const [plusWidth, setPlusWidth] = useState(50);
  const [plusHeight, setPlusHeight] = useState(50);
  // 사진 크기 //

  const [scroll, setScroll] = useState({target: { scrollTop: 0 }});
  const [overflow, setOverflow] = useState()

  const { width, height } = useWindowSize();

  const TotalRef = useRef();
  const UserViewRef = useRef();

  // // 사진 크기 결정 //
  // useEffect(() => {
  //   let totalWidth = TotalRef.current.offsetWidth < 607 ? TotalRef.current.offsetWidth : 607;

  //   let pictureWidth = parseInt(totalWidth * 0.4728) - 4;
  //   let pictureHeight = parseInt(pictureWidth * 0.606) - 4;

  //   let plusWidth = parseInt(totalWidth * 0.082);
  //   let plusHeight = parseInt(totalWidth * 0.009);

  //   setPictureWidth(pictureWidth);
  //   setPictureHeight(pictureHeight);

  //   setPlusWidth(plusWidth);
  //   setPlusHeight(plusHeight);
  // }, [TotalRef, width])

  // API Call //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('souvenir/info', 'GET');
        // console.log(responseData.data);
        if (responseData.status === 200) {
          setEvents(responseData.data.events);
          setOriginalCommonSouvenirs(lodash.cloneDeep(responseData.data.commonSouvenirs));
          setOriginalAdditionalSouvenirs(lodash.cloneDeep(responseData.data.additionalSouvenirs));

          setCommonSouvenirs(lodash.cloneDeep(responseData.data.commonSouvenirs));
          setAdditionalSouvenirs(lodash.cloneDeep(responseData.data.additionalSouvenirs));
        }
      }
    )();
  }, [])

  // 저장하기 클릭시 //
  function onClickSave() {
    // alert('신청중에는 수정이 불가능합니다');
    // return;
    if (!isClickedModify) {
      setCommonSouvenirs(lodash.cloneDeep(originalCommonSouvenirs));
      setAdditionalSouvenirs(lodash.cloneDeep(originalAdditionalSouvenirs));

      setIsClickedModify(true);
    }
    else {
      for (let i = 0; i < commonSouvenirs.length; i++) {
        if (commonSouvenirs[i].name === '') {
          alert('기념품 이름을 입력해주세요');
          return;
        }
        else if (commonSouvenirs[i].souvenirPicture === '') {
          alert('기념품 사진을 업로드해주세요');
          return;
        }
      }

      for (let i = 0; i < additionalSouvenirs.length; i++) {
        if (additionalSouvenirs[i].name === '') {
          alert('기념품 이름을 입력해주세요');
          return;
        }
        else if (additionalSouvenirs[i].souvenirPicture === '') {
          alert('기념품 사진을 업로드해주세요');
          return;
        }
      }

      if (isLoading) {
        alert('저장중입니다');
        return;
      }

      setIsLoading(true);

      let requestData = {
        commonSouvenirs: commonSouvenirs,
        additionalSouvenirs: additionalSouvenirs
      }
      console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEventVer2('souvenir/save', 'POST', requestData);

          if (responseData.status === 200) {
            setEvents(responseData.data.events);
            setOriginalCommonSouvenirs(responseData.data.commonSouvenirs);
            setOriginalAdditionalSouvenirs(responseData.data.additionalSouvenirs);

            setCommonSouvenirs(responseData.data.commonSouvenirs);
            setAdditionalSouvenirs(responseData.data.additionalSouvenirs);

            setIsClickedModify(false);
            setIsLoading(false);
          }
          else {
            alert('저장에 실패했습니다\n잠시 후 다시시도해 주세요');
            setIsLoading(false);
          }
        }
      )();
    }
  }

  // 취소하기 클릭시 //
  function onClickExit() {
    setCommonSouvenirs(lodash.cloneDeep(originalCommonSouvenirs));
    setAdditionalSouvenirs(lodash.cloneDeep(originalAdditionalSouvenirs));

    setIsClickedModify(false);
  }

  return (
    <TopLevelWrapper>
      {/* <TopDivisionWrapper>
        <DivisionButton isClicked={division === 'Common'} onClick={() => setDivision('Common')}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>공통 기념품</Text>
        </DivisionButton>
        <DivisionButton isClicked={division === 'Additional'} onClick={() => setDivision('Additional')}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>추가 기념품</Text>
        </DivisionButton>
      </TopDivisionWrapper>
      <TopButtonWrapper>
        <ExitButton isClicked={isClickedModify} onClick={onClickExit}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor={isClickedModify ? 'pointer' : `default`}>취소하기</Text>
        </ExitButton>
        <SaveButton isClicked={isClickedModify} onClick={onClickSave}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>{isClickedModify ? `저장하기` : `수정하기`}</Text>
        </SaveButton>
      </TopButtonWrapper>
      <ViewBox>
        <EditViewWrapper ref={TotalRef}>
          {
            division === 'Common' ?
              <CommonSouvenirEditView
                enabled={isClickedModify}

                plusWidth={plusWidth}
                plusHeight={plusHeight}
                pictureWidth={pictureWidth}
                pictureHeight={pictureHeight}

                souvenirs={commonSouvenirs}
                setSouvenirs={setCommonSouvenirs} />
              :
              <AdditionalSouvenirEditView
                enabled={isClickedModify}

                plusWidth={plusWidth}
                plusHeight={plusHeight}
                pictureWidth={pictureWidth}
                pictureHeight={pictureHeight}

                souvenirs={additionalSouvenirs}
                setSouvenirs={setAdditionalSouvenirs} />
          }
        </EditViewWrapper>
        <UserViewWrapper 
          ref={UserViewRef}
          onScroll={setScroll}>
          <SelectPageUserView
            UserViewRef={UserViewRef}
            scroll={scroll}
            events={events}
            commonSouvenirs={commonSouvenirs}
            additionalSouvenirs={additionalSouvenirs} />
        </UserViewWrapper>
      </ViewBox> */}
      <LogoWrapper>
        <LogoImage src={EmptyLogo} />
        <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#454549' cursor='default'>준비중인 페이지 입니다</Text>
      </LogoWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
  // height: calc(100% - 73px);
`;

const TopDivisionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 10px;
`;

const DivisionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 5px 20px;

  border: 1px solid ${props => props.isClicked ? `#6DD49E` : `#2F2F32`};
  border-radius: 10px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#17171B`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 10px;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 40px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#969696`};

  :hover {
    cursor: pointer;

    background-color: #6DD49E;
  }

  transition: all 0.3s ease-in-out;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 40px;

  border-radius: 8px;
  background-color: #969696;

  opacity: ${props => props.isClicked ? 1 : 0};

  :hover {
    cursor: ${props => props.isClicked ? `pointer` : `default`};
  }

  transition: all 0.3s ease-in-out;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
  // height: calc(100% - 63px);
  height: calc(100% - 95px);
`;

const EditViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 61%;
  height: 100%;
  // height: calc(100% - 63px);

  gap: 10px;

  overflow: scroll;

  &::-webkit-scrollbar {
      width: 2px;
      height: 0px;
    }
  
    &::-webkit-scrollbar-thumb {
    	border: 2px solid transparent;
    	background-color: #FFFFFF;
    	border-radius: 2px;
    }
  
    &::-webkit-scrollbar-track {
    	// background-color: #F9F9F9;
    	border-radius: 200px;
      opacity: 0;
    }
`;

const UserViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 37%;
  // height: calc(100% - 63px);
  height: 100%;

  gap: 10px;

  overflow: scroll;

  &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  
    &::-webkit-scrollbar-thumb {
    	border: 2px solid transparent;
    	background-color: #FFFFFF;
    	border-radius: 2px;
    }
  
    &::-webkit-scrollbar-track {
    	// background-color: #F9F9F9;
    	border-radius: 200px;
      opacity: 0;
    }
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 240px;

  gap: 8px;
`;

const LogoImage = styled.img`
  width: 150px;
  height: 150px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;