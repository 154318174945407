import React from "react";
import styled from "styled-components";

import SouvenirBox from "./SouvenirBox";

import EmptyLogo from '../../../Image/EventerEmptyLogo.png';

export default function CommonSouvenirEditView(props) {
  const {
    enabled,

    plusWidth,
    plusHeight,
    pictureWidth,
    pictureHeight,
              
    souvenirs,
    setSouvenirs
  } = props;

  const newSouvenir = {
    name: '',
    souvenirPicture: '',
    price: 0,
    isUseOption: false,
    // colors: [],
    options: [],
    sizes: []
  }

  const newSize = {
    name: '',
  }

  const newOption = {
    name: '',
    sizes: [],
  }

  // 기념품 추가 클릭시 //
  function onClickAddSouvenir() {
    if (!enabled) return;

    let newSouvenirs = souvenirs.slice();

    newSouvenirs.push(newSouvenir);

    setSouvenirs(newSouvenirs);
  }

  // 기념품 이름 수정시 //
  function onChangeAppliedSouvenirName(data, index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].name = data;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 가격 변경시 //
  function onChangeAppliedSouvenirPrice(data, index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].price = data;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 썸네일 변경시 //
  function onChangeAppliedSouvenirPicture(data, index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].souvenirPicture = data;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 선택시 //
  function onChangeAppliedUseOption(index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].isUseOption = !newSouvenirs[index].isUseOption;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 사이즈 추가시 //
  function onChangeAppliedAddOptionSize(index, optionIndex) {
    let newSouvenirs = souvenirs.slice();

    // newSouvenirs[index].sizes.push(newSize);
    newSouvenirs[index].options[optionIndex].sizes.push(newSize);

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 사이즈 삭제시 //
  function onChangeAppliedDeleteOptionSize(index, optionIndex, sizeIndex) {
    let newSouvenirs = souvenirs.slice();
    let newSizes = newSouvenirs[index].options[optionIndex].sizes.slice();

    newSizes.splice(sizeIndex, 1);
    newSouvenirs[index].options[optionIndex].sizes = newSizes;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 사이즈 이름 변경시 //
  function onChangeAppliedSouvenirOptionSizeName(data, index, optionIndex, sizeIndex) {
    let newSouvenirs = souvenirs.slice();
    let newSizes = newSouvenirs[index].options[optionIndex].sizes.slice();

    newSizes[sizeIndex].name = data;
    newSouvenirs[index].options[optionIndex].sizes = newSizes;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 기본 사이즈 추가시 //
  function onChangeAppliedAddSize(index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].sizes.push(newSize);

    setSouvenirs(newSouvenirs);
  }

  // 기념품 기본 사이즈 삭제시 //
  function onChangeAppliedDeleteSize(index, sizeIndex) {
    let newSouvenirs = souvenirs.slice();
    let newSizes = newSouvenirs[index].sizes.slice();

    newSizes.splice(sizeIndex, 1);
    newSouvenirs[index].sizes = newSizes;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 기본 사이즈 이름 변경시 //
  function onChangeAppliedSouvenirSizeName(data, index, sizeIndex) {
    let newSouvenirs = souvenirs.slice();
    let newSizes = newSouvenirs[index].sizes.slice();

    newSizes[sizeIndex].name = data;
    newSouvenirs[index].sizes = newSizes;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 추가시 //
  function onChangeAppliedAddOption(index) {
    let newSouvenirs = souvenirs.slice();

    newSouvenirs[index].options.push(newOption);

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 삭제시 //
  function onChangeAppliedDeleteOption(index, optionIndex) {
    let newSouvenirs = souvenirs.slice();
    let newOptions = newSouvenirs[index].options.slice();

    newOptions.splice(optionIndex, 1);
    newSouvenirs[index].options = newOptions;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 옵션 이름 변경시 //
  function onChangeAppliedSouvenirOptionName(data, index, optionIndex) {
    let newSouvenirs = souvenirs.slice();
    let newOptions = newSouvenirs[index].options.slice();

    newOptions[optionIndex].name = data;
    newSouvenirs[index].options = newOptions;

    setSouvenirs(newSouvenirs);
  }

  // 기념품 삭제 클릭시 //
  function onClickAppliedDeleteSouvenir(index) {
    let newSouvenirs = souvenirs.slice();

    if (newSouvenirs.length > 1) {
      newSouvenirs.splice(index, 1);
      setSouvenirs(newSouvenirs);
    }
    else setSouvenirs([]);
  }


  return (
    <TopLevelWrapper>
      <ViewBox>
        <ViewInnerBox>
          <ComponentWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>공통 기념품</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#FFFFFF' cursor='default'>모든 참가자에게 제공되는 기념품 목록을 확인하세요.</Text>
            </TitleWrapper>
          </ComponentWrapper>

          {/* <ComponentWrapper>
            {
              souvenirs.map((data, index) => (
                <SouvenirBox
                  key={'EditCommonSouvenir-' + index}
                  enabled={enabled}
                  isCommon={true}

                  plusWidth={plusWidth}
                  plusHeight={plusHeight} 
                  pictureWidth={pictureWidth}
                  pictureHeight={pictureHeight}
                  
                  data={data}
                  index={index}
                  
                  onChangeAppliedSouvenirName={onChangeAppliedSouvenirName}
                  onChangeAppliedSouvenirPicture={onChangeAppliedSouvenirPicture}
                  onChangeAppliedSouvenirPrice={onChangeAppliedSouvenirPrice}
                  onChangeAppliedUseOption={onChangeAppliedUseOption}
                  onChangeAppliedAddOptionSize={onChangeAppliedAddOptionSize}
                  onChangeAppliedDeleteOptionSize={onChangeAppliedDeleteOptionSize}
                  onChangeAppliedSouvenirOptionSizeName={onChangeAppliedSouvenirOptionSizeName}
                  onChangeAppliedAddSize={onChangeAppliedAddSize}
                  onChangeAppliedDeleteSize={onChangeAppliedDeleteSize}
                  onChangeAppliedSouvenirSizeName={onChangeAppliedSouvenirSizeName}
                  onChangeAppliedAddOption={onChangeAppliedAddOption}
                  onChangeAppliedDeleteOption={onChangeAppliedDeleteOption}
                  onChangeAppliedSouvenirOptionName={onChangeAppliedSouvenirOptionName}
                  onClickAppliedDeleteSouvenir={onClickAppliedDeleteSouvenir}/>
              ))
            }
          </ComponentWrapper> */}

          {
            souvenirs.length > 0 ?
              <ComponentWrapper>
                {
                  souvenirs.map((data, index) => (
                    <SouvenirBox
                      key={'EditCommonSouvenir-' + index}
                      enabled={enabled}
                      isCommon={false}

                      plusWidth={plusWidth}
                      plusHeight={plusHeight}
                      pictureWidth={pictureWidth}
                      pictureHeight={pictureHeight}

                      data={data}
                      index={index}

                      onChangeAppliedSouvenirName={onChangeAppliedSouvenirName}
                      onChangeAppliedSouvenirPicture={onChangeAppliedSouvenirPicture}
                      onChangeAppliedSouvenirPrice={onChangeAppliedSouvenirPrice}
                      onChangeAppliedUseOption={onChangeAppliedUseOption}
                      onChangeAppliedAddOptionSize={onChangeAppliedAddOptionSize}
                      onChangeAppliedDeleteOptionSize={onChangeAppliedDeleteOptionSize}
                      onChangeAppliedSouvenirOptionSizeName={onChangeAppliedSouvenirOptionSizeName}
                      onChangeAppliedAddSize={onChangeAppliedAddSize}
                      onChangeAppliedDeleteSize={onChangeAppliedDeleteSize}
                      onChangeAppliedSouvenirSizeName={onChangeAppliedSouvenirSizeName}
                      onChangeAppliedAddOption={onChangeAppliedAddOption}
                      onChangeAppliedDeleteOption={onChangeAppliedDeleteOption}
                      onChangeAppliedSouvenirOptionName={onChangeAppliedSouvenirOptionName}
                      onClickAppliedDeleteSouvenir={onClickAppliedDeleteSouvenir} />
                  ))
                }
              </ComponentWrapper>
              :
              <EmptyBox>
                <EmptyImage src={EmptyLogo} />
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#454549' cursor='pointer'>등록된 기념품 정보가 없습니다</Text>
              </EmptyBox>
          }
          
          {
            enabled && souvenirs.length === 0 &&
            <SouvenirAdditionButton onClick={onClickAddSouvenir}>
              <PlusWrapper>
                <PlusStick width={24} height={3} />
                <PlusStick width={3} height={24} />
              </PlusWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={20} color='#969696' cursor='pointer'>상품 추가</Text>
            </SouvenirAdditionButton>
          }
        </ViewInnerBox>
      </ViewBox>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding: 20px 0px;

  width: 98%;

  border-radius: 20px;
  background-color: #17171B;
`;

const ViewInnerBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 93%;

  gap: 34px;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 16px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const SouvenirAdditionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 98px;

  gap: 12px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

const PlusStick = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #969696;
`;

const EmptySouvenirBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: calc(100% - 4px);
  height: 98px;

  gap: 12px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #2F2F32;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 24px;

  width: 100%;
  height: 340px;

  gap: 10px;

  border-radius: 30px;
  background-color: #17171B;
`;

const EmptyImage = styled.img`
  width: 187px;
  height: 140px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;