import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import styled from "styled-components";
import moment from "moment";

import FetchModule from "../../../Model/Network/FetchModule";
import EventSalesBox from "../Model/EventSalesBox";
import SouvenirSalesBox from "../Model/SouvenirSalesBox";
import TeamNameAndBelongModifyModal from "../Model/TeamNameAndBelongModifyModal";

import DefaultLogo from '../../../Image/DefaultProfile.png';
import EditPencil from '../../../Image/EditPencilIcon.png';
import TrashIcon from '../../../Image/WhiteTrashIcon.png';

export default function UnitTeamPage(props) {
  const navigate = useNavigate();
  const params = useParams();

  const [refresh, setRefresh] = useState(false);
  const [isClickedTeamEdit, setIsClickedTeamEdit] = useState(false);

  const [teamId, setTeamId] = useState(0);
  const [team, setTeam] = useState(undefined);

  const [teamName, setTeamName] = useState('');
  const [teamBelong, setTeamBelong] = useState('');
  const [salesDivision, setSalesDivision] = useState('Ticket');
  const [changeTeamName, setChangeTeamName] = useState('');
  const [changeBelong, setChangeBelong] = useState('');

  const [approvalDate, setApprovalDate] = useState(moment().format('YY.MM.DD'))
  const [event, setEvent] = useState({
    eventTitle: '',
    eventSelectOptionName: '',
    approvalDate: moment().format('YY.MM.DD'),
    eventPrice: 0,
    totalPrice: 0,
    merchantUid: ''
  })
  const [sales, setSales] = useState([]);

  useEffect(() => {
    let teamId = parseInt(params.teamId);

    setTeamId(teamId);

    let requestData = {
      teamId: teamId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEventVer2('team/detail', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          let data = responseData.data;

          setTeam(data);

          setTeamName(data.teamName);
          setChangeTeamName(data.teamName);
          
          if (data.members.length > 0) {
            setTeamBelong(data.members[0].belong);
            setChangeBelong(data.members[0].belong);
          }

          setEvent({
            eventTitle: data.eventTitle,
            eventSelectOptionName: data.eventSelectOptionName,
            approvalDate: moment(data.approvalDate).format('YY.MM.DD'),
            eventPrice: data.eventPrice,
            totalPrice: data.totalPrice,
            merchantUid: data.merchantUid
          });

          let newSales = [];

          for (let i = 0; i < data.members.length; i++) {
            if (data.members[i].bloodType !== '') {
              let optionName = '';
              let sizeName = '';

              if (data.members[i].bloodType.includes('-')) {
                optionName = data.members[i].bloodType.split('-')[0];
                sizeName = data.members[i].bloodType.split('-')[1];
              }
              else sizeName = data.members[i].bloodType;
              
              newSales.push({
                count: 1,
                itemName: data.commonSouvenirName,
                merchantUid: data.merchantUid,
                salesItemId: 0,
                souvenirDivision: 'Common',
                souvenirOptionId: 0,
                souvenirOptionName: optionName,
                souvenirSizeId: 0,
                souvenirSizeName: sizeName,
                price: 0,
                totalPrice: 0
              })
            }
          }

          for (let i = 0; i < data.salesDetails.length; i++) {
            if (data.salesDetails[i].souvenirId !== 0) newSales.push(data.salesDetails[i]);
          }
          
          setApprovalDate(moment(data.approvalDate).format('YY.MM.DD'));
          setSales(newSales);
        }
        else if (responseData.status === 201) {
          alert('팀 정보가 존재하지 않습니다');
        }
        else if (responseData.status === 202) {
          alert('조회 권한이 없습니다');
          navigator('/unit');
        }
        else if (responseData.status === 401) {
          navigate('/login');
        }
      }
    )();
  }, [refresh]);

  // 프로필 사진 결정 //
  function decideTeamProfile() {
    if (team === undefined
      || team.profile === null
      || team.profile === undefined
      || team.profile === '') return DefaultLogo;
    else return team.profile;
  }

  // 팀 이름 결정 //
  function decideTeamName() {
    if (team === undefined
      || team.teamName === null
      || team.teamName === undefined
      || team.teamName === '') return '';
    else return team.teamName;
  }

  // 팀 소속 결정 //
  function decideTeamBelong() {
    if (team === undefined
      || team.members === null
      || team.members === undefined
      || team.members.length === 0) return '';
    else return team.members[0].belong;
  }

  // 팀원 성별 결정 //
  function decideMemberGender(gender) {
    if (gender === 'male') return '남성';
    else return '여성';
  }

  // 팀원 전화번호 결정 //
  function decideMemberPhone(phone) {
    return phone.slice(0, 3) + '-' + phone.slice(3, 7) + '-' + phone.slice(7, 11);
  }

  // 회원 닉네임 결정 //
  function decideMemberNickName(nickName) {
    if (nickName === undefined) return '미가입';
    else return nickName;
  }

  // 팀 이름 및 회원정보 변경 모달에서 취소버튼 클릭시 //
  function onClickExitInTeamNameAndBelongModifyModal() {
    setChangeTeamName(teamName);
    setChangeBelong(teamBelong);
    setIsClickedTeamEdit(false);
  }

  // 팀 이름 및 회원정보 변경 모달에서 저장버튼 클릭시 //
  function onClickSaveInTeamNameAndBelongModifyModal() {
    if (changeTeamName === '') {
      alert('팀 이름을 정확히 입력해주세요');
      return;
    }
    else if (changeBelong === '') {
      alert('소속을 정확히 입력해주세요');
      return;
    }

    let requestData = {
      teamId: teamId,
      changeTeamName: changeTeamName,
      changeBelong: changeBelong
    }
    
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEventVer2('team/save_admin', 'POST', requestData);
        
        if (responseData.status === 200) {
          setRefresh(!refresh);
          setIsClickedTeamEdit(false);
        }
        else if (responseData.status === 401) {
          navigate('/login');
        }
        else {
          alert('정보 저장에 실패했습니다.\n잠시 후 다시시도 해주세요');
        }
      }
    )();
  }

  return (
    <TopLevelWrapper>
      {
        isClickedTeamEdit &&
        <TeamNameAndBelongModifyModal
          teamName={teamName}
          belong={teamBelong}
          changeTeamName={changeTeamName}
          setChangeTeamName={setChangeTeamName}
          changeBelong={changeBelong}
          setChangeBelong={setChangeBelong}
          onClickExit={onClickExitInTeamNameAndBelongModifyModal}
          onClickSave={onClickSaveInTeamNameAndBelongModifyModal}/>
      }
      <TeamInfoWrapper>
        <ProfileBoxWrapper>
          <ProfileBox src={decideTeamProfile()} />
        </ProfileBoxWrapper>
        <TeamInfoBox>
          <InfoWrapper>
            <LayerWrapper>
              <TeamTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FFFFFF' cursor='default'>팀이름</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FFFFFF' cursor='default'>:</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>{decideTeamName()}</Text>
              </TeamTextWrapper>
              <TeamTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FFFFFF' cursor='default'>소속</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#FFFFFF' cursor='default'>:</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FFFFFF' cursor='default'>{decideTeamBelong()}</Text>
                <EditButtonWrapper onClick={() => setIsClickedTeamEdit(true)}>
                  <EditButton src={EditPencil} />
                </EditButtonWrapper>
              </TeamTextWrapper>
            </LayerWrapper>
            <ColumnWrapper>
              <Text style={{ marginRight: '159px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>이름</Text>
              <Text style={{ marginRight: '34px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>성별</Text>
              <Text style={{ marginRight: '88px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>전화번호</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>아이디</Text>
            </ColumnWrapper>
            {
              team !== undefined &&
              <MemberBoxWrapper>
                {
                  team.members.map((member, index) => (
                    <MemberBox key={'Members-' + index}>
                      <MemberTextWrapper>
                        <Text style={{ marginRight: '146px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{member.name}</Text>
                        <Text style={{ marginRight: '34px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{decideMemberGender(member.gender)}</Text>
                        <Text style={{ marginRight: '42px' }} fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{decideMemberPhone(member.phone)}</Text>
                        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{decideMemberNickName(member.nickName)}</Text>
                      </MemberTextWrapper>
                      {/* <TrashButtonWrapper>
                        <TrashButton src={TrashIcon} />
                      </TrashButtonWrapper> */}
                    </MemberBox>
                  ))
                }
              </MemberBoxWrapper>
            }
            {/* <MemberAdditionButton>
              <PlusWrapper>
                <PlusStick width={14} height={2} />
                <PlusStick width={2} height={14} />
              </PlusWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>팀원 추가</Text>
            </MemberAdditionButton> */}
          </InfoWrapper>
        </TeamInfoBox>
      </TeamInfoWrapper>

      <SalesDivisionBoxWapper>
        <SalesDivisionBox isClicked={salesDivision === 'Ticket'} onClick={() => setSalesDivision('Ticket')}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={salesDivision === 'Ticket' ? '#FFFFFF' : '#777777'}>신청 이벤트</Text>
        </SalesDivisionBox>
        <SalesDivisionBox isClicked={salesDivision === 'Souvenir'} onClick={() => setSalesDivision('Souvenir')}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color={salesDivision === 'Souvenir' ? '#FFFFFF' : '#777777'}>구입 물품</Text>
        </SalesDivisionBox>
      </SalesDivisionBoxWapper>

        {
          salesDivision === 'Ticket' ?
            <SalesWrapper>
              <EventSalesBox event={event}/>
            </SalesWrapper>
            :
            <SalesWrapper>
              <SouvenirSalesBox sales={sales} approvalDate={approvalDate}/>
            </SalesWrapper>
        }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 100%;
`;

const ProfileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 20.42%;
`;

const TeamInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: 20px;

  width: 76.89%;
  min-height: 240px;
  
  border-radius: 24px;
  background-color: #17171B;
`;

const ProfileBox = styled.img`
  width: 100%;

  aspect-ratio: 1/1;
  object-fit: cover;
  
  border-radius: 24px;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 94.43%;
  // width: 90.33%;
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 16px;

  // width: 100%;
  width: 97.49%;

  gap: 40px;
`;

const TeamTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;
`;

const EditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

const EditButton = styled.img`
  width: 19px;
  height: 19px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 16px;

  // width: 100%;
  width: 97.49%;
`;

const MemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;

  width: 100%;

  gap: 8px;
`;

const MemberBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 10px;

  width: calc(100% - 20px);
  height: 38px;

  border-radius: 8px;
  background-color: #2F2F32;
`;

const MemberTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;
`;

const TrashButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-right: 10px;

  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const TrashButton = styled.img`
  width: 24px;
  height: 24px;
`;

const MemberAdditionButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 10px;

  width: calc(100% - 4px);
  height: 34px;

  gap: 12px;

  border: 2px dashed #2F2F32;
  border-radius: 8px;
  background-color: #17171B;

  :hover {
    cursor: pointer;
  }
`;

const PlusWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const PlusStick = styled.div`
  position: absolute;
  
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 8px;
  background-color: #FFFFFF;
`;

const SalesDivisionBoxWapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  margin-top: 16px;

  width: 100%;

  gap: 10px;
`;

const SalesDivisionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 0px 20px;
  
  height: 38px;

  border: ${props => props.isClicked ? '1px solid #5BD98A' : '1px solid #2F2F32'};
  border-radius: 10px;
  background-color: ${props => props.isClicked ? '#6DD49E' : '#17171B'};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const SalesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: 12px;

  width: 100%;

  gap: 24px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;