import React, { useRef } from 'react';
import styled from 'styled-components';

export default function MemberInputBox(props) {
	const { value, setValue, index, placeholder } = props;

	const InputRef = useRef();

	function handleClick(e) {
	}

	function handleChange(e) {
		setValue(e.target.value, index);
	}

	function handleFocus(e) {
    setValue("");
	}

	function handleBlur(e) {
	}

	return (
		<Input
			ref={InputRef}
			placeholder={placeholder}
			value={value}
			type='text'
			onClick={handleClick}
			onChange={handleChange}
			onFocus={handleFocus}
			onBlur={handleBlur} />
	)
}

const Input = styled.input`
	font-family: NotoSansKR-Bold;
	font-size: 15px;
	line-height: 20px;

	width: calc(100% - 24px);
	height: 48px;

	padding: 0 12px 0 12px;
	margin: 0 0 0 0;

	outline: none;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
	background-color: #F9F9F9;
	
	color: #000000;

	::placeholder {
		color: #CBCBCB;
	}
	
  transition: all 0.3s ease-in-out;
`;