import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import styled from 'styled-components';

import StateDeeplinkRoutePage from './Page/StateDeeplinkRoutePage';
import TestRoutingPage from './Page/TestRoutingPage';
import NotFoundPage from './Page/NotFoundPage';
import DeeplinkRoutePage from './Page/DeeplinkRoutePage';
import EventInfoPage from './Page/EventInfoPage';
import EventAgreePage from './Page/EventAgreePage';
import EventApplicationPage from './Page/EventApplicationPage';
import RankingPage from './Page/RankingPage';
import MemberDetailPage from './Page/MemberDetailPage';
import LoginPage from './Page/LoginPage';
import AdminPage from './Page/AdminPage';
import ModifyRankingPage from './Page/ModifyRankingPage';
import EventSelectPage from './Page/EventSelectPage';
import EventApplicationInfocheckPage from './Page/EventApplicationInfoCheckPage';
import PaymentCompletePage from './Page/PaymentCompletePage';
import FreePaymentCompletePage from './Page/FreePaymentCompletePage';
import FinalPaymentPage from './Page/FinalPaymentPage';
import InfoPage from './Page/InfoPage';
import CompletePaymentPage from './Page/CompletePaymentPage';
import RequestPaymentPage from './Page/RequestPaymentPage';
import AdminPaymentPage from './Page/AdminPaymentPage';

// Marvel //
import MarvelEventDetailPage from './Page/EventDetailPage/Marvel/MarvelEventDetailPage';
// Marvel //

// BattleCrew //
import BattleCrewEvent2DetailPage from './Page/EventDetailPage/BattleCrew/BattleCrewEvent2DetailPage';
import BattleCrewEvent3DetailPage from './Page/EventDetailPage/BattleCrew/BattleCrewEvent3DetailPage';
// BattleCrew //

// FET Online //
import FETOnlineJANUARYDetailPage from './Page/EventDetailPage/FarEastThrowdownOnline/FETOnlineJANUARYDetailPage';
import FETOnlineFEBRUARYDetailPage from './Page/EventDetailPage/FarEastThrowdownOnline/FETOnlineFEBRUARYDetailPage';
import FETOnlineMARCHDetailPage from './Page/EventDetailPage/FarEastThrowdownOnline/FETOnlineMARCHDetailPage';
import FETOnlineAPRILDetailPage from './Page/EventDetailPage/FarEastThrowdownOnline/FETOnlineAPRILDetailPage';

import JanuaryCustomRankPage from './Page/CustomRankingPage/FETOnline/JanuaryCustomRankPage';
import CustomRankDetailPage from './Page/CustomRankingPage/FETOnline/CustomRankDetailPage';
import FebruaryCustomRankPage from './Page/CustomRankingPage/FETOnline/FebruaryCustomRankPage';
import MarchCustomRankPage from './Page/CustomRankingPage/FETOnline/MarchCustomRankPage';
import AprilCustomRankPage from './Page/CustomRankingPage/FETOnline/AprilCustomRankPage';
// FET Online //

// SUFF //
import SUFFIndividualDetailPage from './Page/EventDetailPage/SUFF/SUFFIndividualDetailPage';
import SUFFRxdTeamDetailPage from './Page/EventDetailPage/SUFF/SUFFRxdTeamDetailPage';
import SUFFScaleTeamDetailPage from './Page/EventDetailPage/SUFF/SUFFScaleTeamDetailPage';

import SUFFIndividualCustomRankPage from './Page/CustomRankingPage/SUFF/SUFFIndividualCustomRankPage';
import SUFFRxdTeamCustomRankPage from './Page/CustomRankingPage/SUFF/SUFFRxdTeamCustomRankPage';
import SUFFScaleTeamCustomRankPage from './Page/CustomRankingPage/SUFF/SUFFScaleTeamCustomRankPage';
// SUFF //

// FET SWEAT CHALLENGE //
import FETSweatChanllengeDetailPage from './Page/EventDetailPage/FETSweatChallenge/FETSweatChallengeDetailPage';
import SweatChanllengeCustomRankPage from './Page/CustomRankingPage/FETSweatChallenge/SweatChallengeCustomRankPage';
// FET SWEAT CHALLENGE //

// Battle Crew Event 3 //
import BattleCrew3EventInfoPage from './BattleCrew3/BattleCrew3EventInfoPage';
import BattleCrew3EventAgreePage from './BattleCrew3/BattleCrew3EventAgreePage';
import BattleCrew3EventSelectPage from './BattleCrew3/BattleCrew3EventSelectPage';
import BattleCrew3EventApplicationPage from './BattleCrew3/BattleCrew3EventApplicationPage';
import BattleCrew3EventApplicationInfocheckPage from './BattleCrew3/BattleCrew3EventApplicationCheckPage';
import BattleCrew3EventCompletePage from './BattleCrew3/BattleCrew3EventCompletePage';
import BattleCrew3EventDetailPage from './BattleCrew3/BattleCrew3EventDetailPage';
// Battle Crew Event 3 //

// The Performance Workshop //
import TPWorkshopInfoPage from './ThePerformanceWorkshop/Page/TPWorkshopInfoPage';
import TPWorkshopAgreePage from './ThePerformanceWorkshop/Page/TPWorkshopAgreePage';
import TPWorkshopSelectPage from './ThePerformanceWorkshop/Page/TPWorkshopSelectPage';
import TPWorkshopApplicationPage from './ThePerformanceWorkshop/Page/TPWorkshopApplicationPage';
import TPWorkshopApplicationInfoCheckPage from './ThePerformanceWorkshop/Page/TPWorkshopApplicationCheckPage';
import TPWorkshopCompletePage from './ThePerformanceWorkshop/Page/TPWorkshopCompletePage';
import FinalSUFFIndividualDetailPage from './FinalSUFF/Page/FinalSUFFIndividualDetailPage';
import FinalSUFFRxdTeamDetailPage from './FinalSUFF/Page/FinalSUFFRxdTeamDetailPage';
import FinalSUFFScaleTeamDetailPage from './FinalSUFF/Page/FinalSUFFScaleTeamDetailPage';
import FinalSUFFIndividualRankingPage from './FinalSUFF/Page/FinalSUFFIndividualRankingPage';
import FinalSUFFRxdTeamRankingPage from './FinalSUFF/Page/FinalSUFFRxdTeamRankingPage';
import FinalSUFFScaleTeamRankingPage from './FinalSUFF/Page/FinalSUFFScaleTeamRankingPage';
import TPWorkshopDetailInfoPage from './ThePerformanceWorkshop/Page/TPWorkshopDetailInfoPage';
import AccountTransferPage from './Page/AccountTransferPage';
import FinalSUFFAdminPage from './FinalSUFF/Page/FinalSUFFAdminPage';
// The Performance Workshop //

// GirlFit //
import GirlFitInfoPage from './Event/GirlFit/GirlFitInfoPage';
import GirlFitAgreePage from './Event/GirlFit/GirlFitAgreePage';
import GirlFitSelectPage from './Event/GirlFit/GirlFitSelectPage';
import GirlFitApplicationPage from './Event/GirlFit/GirlFitApplicationPage';
import GirlFitApplicationInfoCheckPage from './Event/GirlFit/GirlFitApplicationCheckPage';
import GirlFitCompletePage from './Event/GirlFit/GirlFitCompletePage';
import GirlFitIndividualDetailPage from './Event/GirlFit/GirlFitIndividualDetailPage';
import GirlFitTeamDetailPage from './Event/GirlFit/GirlFitTeamDetailPage';
import GirlFitIndividualRankingPage from './Event/GirlFit/GirlFitIndividualRanking';
import GirlFitTeamRankingPage from './Event/GirlFit/GirlFitTeamRankingPage';
import BattleCrew3AdminPage from './BattleCrew3/BattleCrew3AdminPage';
import GirlFitAdminPage from './Event/GirlFit/GirlFitAdminPage';
import GirlCrushInfoPage from './Event/GirlCrush/GirlCrushInfoPage';
import GirlCrushAgreePage from './Event/GirlCrush/GirlCrushAgreePage';
import GirlCrushSelectPage from './Event/GirlCrush/GirlCrushSelectPage';
import GirlCrushApplicationPage from './Event/GirlCrush/GirlCrushApplicationPage';
import GirlCrushApplicationInfoCheckPage from './Event/GirlCrush/GirlCrushApplicationCheckPage';
import GirlCrushCompletePage from './Event/GirlCrush/GirlCrushCompletePage';
import GirlCrushDetailPage from './Event/GirlCrush/GirlCrushDetailPage';
import GirlCrushRankingPage from './Event/GirlCrush/GirlCrushRankingPage';
import BattleCrew3FinalDetailPage from './Event/BattleCrew3Final/BattleCrew3FinalDetail';
import BattleCrew3FinalRankingPage from './Event/BattleCrew3Final/BattleCrew3FinalRankingPage';
// GirlFit //

// HYROX //
import HyroxInfoPage from './Event/Hyrox/HyroxInfoPage';
// HYROX //

// CrossFit Games Open //
import CrossfitGamesOpenInfoPage from './Event/CrossfitGamesOpen/CrossfitGamesOpenInfoPage';
// CrossFit Games Open //

// Fit Force Team League //
import FitForceTeamLeagueTestInfoPage from './Event/FitForceTeamLeague/FitForceTeamLeagueTestInfoPage';
import FitForceTeamLeagueInfoPage from './Event/FitForceTeamLeague/FitForceTeamLeagueInfoPage';
import FitForceTeamLeagueAgreePage from './Event/FitForceTeamLeague/FitForceTeamLeagueAgreePage';
import FitForceTeamLeagueSelectPage from './Event/FitForceTeamLeague/FitForceTeamLeagueSelectPage';
import FitForceTeamLeagueApplicationPage from './Event/FitForceTeamLeague/FitForceTeamLeagueApplicationPage';
import FitForceTeamLeagueApplicationInfoCheckPage from './Event/FitForceTeamLeague/FitForceTeamLeagueApplicationCheckPage';
import FitForceTeamLeaguePaymentPage from './Event/FitForceTeamLeague/FitForceTeamLeaguePaymentPage';
import FitForceTeamLeagueCompletePage from './Event/FitForceTeamLeague/FitForceTeamLeagueCompletePage';
import FitForceTeamLeagueDetail1ReplacePage from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail1ReplacePage';
import FitForceTeamLeagueDetail2ReplacePage from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail2ReplacePage';
import FitForceTeamLeagueDetail3ReplacePage from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail3ReplacePage';
import FitForceTeamLeagueGetParticipantListPage from './Event/FitForceTeamLeague/FitForceTeamLeagueGetParticipantListPage';
import FitForceTeamLeagueDetail1Page from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail1Page';
import FitForceTeamLeagueDetail2Page from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail2Page';
import FitForceTeamLeagueDetail3Page from './Event/FitForceTeamLeague/FitForceTeamLeagueDetail3Page';
import FitForceTeamLeagueRankingPage from './Event/FitForceTeamLeague/FitForceTeamLeagueRankingPage';
// Fit Force Team League //

// Believer //
import BelieverInfoPage from './Event/Believer/BelieverInfoPage';
import BelieverAgreePage from './Event/Believer/BelieverAgreePage';
import BelieverSelectPage from './Event/Believer/BelieverSelectPage';
import BelieverApplicationPage from './Event/Believer/BelieverApplicationPage';
import BelieverApplicationInfoCheckPage from './Event/Believer/BelieverApplicationCheckPage';
import BelieverPaymentPage from './Event/Believer/BelieverPaymentPage';
import BelieverTestInfoPage from './Event/Believer/BelieverTestInfoPage';
import BelieverCompletePage from './Event/Believer/BeliverCompletePage';
import BelieverDetailReplacePage from './Event/Believer/BelieverDetailReplacePage';
import BelieverApplicationStatePage from './Event/Believer/BelieverApplicationStatePage';
import BelieverGetParticipantListPage from './Event/Believer/BelieverGetParticipantListPage';
import BelieverDetailPage from './Event/Believer/BelieverDetailPage';
import BelieverRankingPage from './Event/Believer/BelieverRankingPage';
// Believer //

// AFK //
import AFKInfoTestPage from './Event/AFK/AFKInfoTestPage';
import AFKInfoPage from './Event/AFK/AFKInfoPage';
import AFKAgreePage from './Event/AFK/AFKAgreePage';
import AFKSelectPage from './Event/AFK/AFKSelectPage';
import AFKApplicationPage from './Event/AFK/AFKApplicationPage';
import AFKApplicationInfoCheckPage from './Event/AFK/AFKApplicationCheckPage';
import AFKPaymentPage from './Event/AFK/AFKPaymentPage';
import AFKCompletePage from './Event/AFK/AFKCompletePage';
import AFKApplicationStatePage from './Event/AFK/AFKApplicationStatePage';
import AFKDetailPage from './Event/AFK/AFKDetailPage';
// AFK //

// LinkCoach DeadLift //
import LinkCoachDeadLiftInfoPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftInfoPage';
import LinkCoachDeadLiftAgreePage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftAgreePage';
import LinkCoachDeadLiftSelectPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftSelectPage';
import LinkCoachDeadLiftApplicationPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftApplicationPage';
import LinkCoachDeadLiftApplicationInfoCheckPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftApplicationCheckPage';
import LinkCoachDeadLiftPaymentPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftPaymentPage';
import LinkCoachDeadLiftCompletePage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftCompletePage';
import LinkCoachDeadLiftApplicationStatePage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftApplicationStatePage';
import LinkCoachDeadLiftDetailPage from './Event/LinkCoachDeadLift/LinkCoachDeadLiftDeatilPage';
// LinkCoach DeadLift //

// R2C //
import R2CWomanRxdDetailPage from './Event/R2C/R2CWomanRxdDetailPage';
import R2CWomanScaledDetailPage from './Event/R2C/R2CWomanScaledDetailPage';
import R2CManRxdDetailPage from './Event/R2C/R2CManRxdDetailPage';
import R2CManScaledDetailPage from './Event/R2C/R2CManScaledDetailPage';
import R2CRankPage from './Event/R2C/R2CRankPage';
// R2C //

// Final Fit Force Team League //
import FinalFitForceTeamLeagueInfoPage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueInfoPage';
import FinalFitForceTeamLeagueApplicationInfoCheckPage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueApplicationCheckPage';
import FinalFitForceTeamLeagueRequestPayment from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueRequestPayment';
import FinalFitForceTeamLeaguePaymentCompletePage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeaguePaymentCompletePage';
import FinalFitForceTeamLeagueApplicationStatePage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueApplicationStatePage';
import FinalFitForceTeamLeagueDetail1Page from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueDetail1Page';
import FinalFitForceTeamLeagueDetail2Page from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueDetail2Page';
import FinalFitForceTeamLeague4PeopleRankingPage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeague4PeopleRankingPage';
import FinalFitForceTeamLeagueRankingPage from './Event/FinalFitForceTeamLeague/FinalFitForceTeamLeagueRankingPage';
// Final Fit Force Team League //

// Summit Camp //
import SummitCampInfoPage from './Event/SUMMITCamp/SummitCampInfoPage';
import SummitCampAgreePage from './Event/SUMMITCamp/SummitCampAgreePage';
import SummitCampSelectPage from './Event/SUMMITCamp/SummitCampSelectPage';
import SummitCampApplicationPage from './Event/SUMMITCamp/SummitCampApplicationPage';
import SummitCampApplicationInfoCheckPage from './Event/SUMMITCamp/SummitCampApplicationCheckPage';
import SummitCampRequestPaymentPage from './Event/SUMMITCamp/SummitCampRequestPaymentPage';
import SummitCampPaymentCompletePage from './Event/SUMMITCamp/SummitCampCompletePaymentPage';
import SummitCampApplicationStatePage from './Event/SUMMITCamp/SummitCampApplicationStatePage';
import SummitCampCompletePage from './Event/SUMMITCamp/SummitCampCompletePage';
// Summit Camp //

// Show Your Performance //
import ShowYourPerformanceInfoPage from './Event/ShowYourPerformance/ShowYourPerformanceInfoPage';
import ShowYourPerformanceAgreePage from './Event/ShowYourPerformance/ShowYourPerformanceAgreePage';
import ShowYourPerformanceSelectPage from './Event/ShowYourPerformance/ShowYourPerformanceSelectPage';
import ShowYourPerformanceApplicationPage from './Event/ShowYourPerformance/ShowYourPerformanceApplicationPage';
import ShowYourPerformanceApplicationInfoCheckPage from './Event/ShowYourPerformance/ShowYourPerformanceApplicationInfoCheckPage';
import ShowYourPerformanceRequestPaymentPage from './Event/ShowYourPerformance/ShowYourPerformanceRequestPaymentPage';
import ShowYourPerformanceCompletePage from './Event/ShowYourPerformance/ShowYourPerformanceCompletePage';
import ShowYourPerformanceDetailPage from './Event/ShowYourPerformance/ShowYourPerformanceDetailPage';
import ShowYourPerformanceRankingPage from './Event/ShowYourPerformance/ShowYourPerformanceRankingPage';
// Show Your Performance //

// Gorilla Throwdown //
import GorillaThrowdownDetailPage from './Event/GorillaThrowdown/GorillaThrowdownDetailPage';
import GorillaThrowdownRankingPage from './Event/GorillaThrowdown/GorillaThrowdownRankingPage';
// Gorilla Throwdown //

// Team Of Four //
import TeamOfFourInfoPage from './Event/TeamOfFour/TeamOfFourInfoPage';
import TeamOfFourAgreePage from './Event/TeamOfFour/TeamOfFourAgreePage';
import TeamOfFourApplicationPage from './Event/TeamOfFour/TeamOfFourApplicationPage';
import TeamOfFourSelectPage from './Event/TeamOfFour/TeamOfFourSelectPage';
import TeamOfFourApplicationCheckPage from './Event/TeamOfFour/TeamOfFourApplicationCheckPage';
import TeamOfFourCompletePage from './Event/TeamOfFour/TeamOfFourCompletePage';
import TeamOfFourModifyPage from './Event/TeamOfFour/TeamOfFourModifyPage';
import TeamOfFourParticipantListPage from './Event/TeamOfFour/TeamOfFourParticipantPage';
import TeamOfFourDetailPage from './Event/TeamOfFour/TeamOfFourDetailPage';
import TeamOfFourRankingPage from './Event/TeamOfFour/TeamOfFourRankingPage';
// Team Of Four //

// Girl Crush Season2 //
import GirlCrushSeason2InfoPage from './Event/GirlCrushSeason2/GirlCrushSeason2InfoPage';
import GirlCrushSeason2AgreePage from './Event/GirlCrushSeason2/GirlCrushSeason2AgreePage';
import GirlCrushSeason2SelectPage from './Event/GirlCrushSeason2/GirlCrushSeason2SelectPage';
import GirlCrushSeason2ApplicationPage from './Event/GirlCrushSeason2/GirlCrushSeason2ApplicationPage';
import GirlCrushSeason2ApplicationCheckPage from './Event/GirlCrushSeason2/GirlCrushSeason2ApplicationCheckPage';
import GirlCrushSeason2CompletePage from './Event/GirlCrushSeason2/GirlCrushSeason2CompletePage';
import GirlCrushSeason2ModifyPage from './Event/GirlCrushSeason2/GirlCrushSeason2ModifyPage';
import GirlCrushSeason2ParticipantListPage from './Event/GirlCrushSeason2/GirlCrushSeason2ParticipantPage';
import GirlCrushSeason2DetailPage from './Event/GirlCrushSeason2/GirlCrushDetailPage';
import GirlCrushSeason2RankingPage from './Event/GirlCrushSeason2/GirlCrushSeason2RankingPage';
// Girl Crush Season2 //

// The Mentality //
import TheMentalityInfoPage from './Event/TheMentality/TheMentalityInfoPage';
import TheMentalityAgreePage from './Event/TheMentality/TheMentalityAgreePage';
import TheMentalitySelectPage from './Event/TheMentality/TheMentalitySelectPage';
import TheMentalityApplicationPage from './Event/TheMentality/TheMentalityApplicationPage';
import TheMentalityApplicationCheckPage from './Event/TheMentality/TheMentalityApplicationCheckPage';
import TheMentalityComplete1Page from './Event/TheMentality/TheMentalityComplete1Page';
import TheMentalityModifyPage from './Event/TheMentality/TheMentalityModifyPage';
import TheMentalityComplete2Page from './Event/TheMentality/TheMentalityComplete2Page';
import TheMentalityParticipantListPage from './Event/TheMentality/TheMentalityParticipantListPage';
import TheMentalityDetail1Page from './Event/TheMentality/TheMentalityDetail1Page';
import TheMentalityDetail2Page from './Event/TheMentality/TheMentalityDetail2Page';
import TheMentality1RankingPage from './Event/TheMentality/TheMentality1RankingPage';
import TheMentality2RankingPage from './Event/TheMentality/TheMentality2RankingPage';
// The Mentality //

// 2025 URBANFIT INVITATIONAL //
import UrbanfitInvitationalInfoPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalInfoPage';
import UrbanfitInvitationalAgreePage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalAgreePage';
import UrbanfitInvitationalSelectPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalSelectPage';
import UrbanfitInvitationalApplicationPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalApplicationPage';
import UrbanfitInvitationalApplicationCheckPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalApplicationCheckPage';
import UrbanfitInvitationalCompletePage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalCompletePage';
import UrbanfitInvitationalModifyPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalModifyPage';
import UrbanfitInvitationalParticipantListPage from './Event/2025UrbanfitInvitational/UrbanfitInvitationalParticipantPage';
import UrbanfitInvitationalComplete1Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalComplete1Page';
import UrbanfitInvitationalComplete2Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalComplete2Page';
import UrbanfitInvitationalComplete3Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalComplete3Page';
import UrbanfitInvitationalDetail1Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalDetail1Page';
import UrbanfitInvitationalRanking1Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalRank1Page';
import UrbanfitInvitationalDetail2Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalDetail2Page';
import UrbanfitInvitationalDetail3Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalDetail3Page';
import UrbanfitInvitationalRanking2Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalRank2Page';
import UrbanfitInvitationalRanking3Page from './Event/2025UrbanfitInvitational/UrbanfitInvitationalRank3Page';
// 2025 URBANFIT INVITATIONAL //

// Fit Force Team League 2025 //
import FitForceTeamLeague2025InfoPage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025InfoPage';
import FitForceTeamLeague2025ApplicationPage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025ApplicationPage';
import FitForceTeamLeague2025ApplicationCheckPage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025ApplicationCheckPage';
import FitForceTeamLeague2025CompletePage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025CompletePage';
import FitForceTeamLeague2025ApplicationModifyPage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025ApplicationModifyPage';
import FitForceTeamLeague2025ParticipantListPage from './Event/FitForceTeamLeague2025/FitForceTeamLeague2025ParticipantPage';
// FitForce Team League 2025 //

// EVENTER //
import NavigationBar from './Eventer/Share/NavigationBar';
import TopBar from './Eventer/Share/TopBar';

import EventerLoginPage from './Eventer/Login/Page/EventerLoginPage';
import MainPage from './Eventer/Main/Page/MainPage';

import NewEventPage from './Eventer/UserView/Info/Page/EventInfoPage';
import ProductPage from './Eventer/Product/Page/ProductPage';
import AgreementPage from './Eventer/UserView/Ageement/Page/AgreementPage';
import SelectEventPage from './Eventer/UserView/SelectEvent/Page/SelectEventPage';
import ApplicationPage from './Eventer/UserView/Application/Page/ApplicationPage';
import UnitPage from './Eventer/Unit/Page/UnitPage';
import TermsPage from './Eventer/Tems/Page/TemsPage';
import SettingPage from './Eventer/Setting/Page/SettingPage';
import UnitTeamPage from './Eventer/UnitDetail/Page/UnitTeamPage';
import ApplicationCheckPage from './Eventer/UserView/ApplicationCheckPage.js/Page/ApplicationCheckPage';
import CompletePage from './Eventer/UserView/Complete/Page/CompletePage';
import ApplicationModifyPage from './Eventer/UserView/Modify/Page/ApplicationModifyPage';
import UnitMemberPage from './Eventer/UnitDetail/Page/UnitMemberPage';
import NotePage from './Eventer/Note/Page/NotePage';
// EVENTER //


export default function App() {

  const defaultURL = 'https://event.link-zone.io';
  // const defaultURL = 'http://localhost:3000';

  const [nowPage, setNowPage] = useState('/');
  
  // 페이지 변환 기준점 확인 //
  function checkEventerPage() {
    if (nowPage.includes('/main')
      || nowPage.includes('/product')
      || nowPage.includes('/terms')
      || nowPage.includes('/unit')
      || nowPage.includes('/note')
      || nowPage.includes('/setting')) return true;
    else return false;
  }

  // 페이지 높이 결정 //
  function decideContainerHeight() {
    if (checkEventerPage()) return '100vh';
    else return 'auto';
  }

  // 페이지 최대 크기 결정 //
  function decideContentWrapperMaxWidth() {
    if (checkEventerPage()) return '1194px';
    else return 'auto';
  }

  // 페이지 높이 결정 //
  function decideContentWrapperHeight() {
    if (checkEventerPage()) return 'calc(100% - 83px)';
    else return '100%';
  }


  // 배경색 결정 //
  function decidePageBackgroundColor() {
    if (checkEventerPage()) return '#000000';
    else return '#FFFFFF';
  }

  return (
    <Container 
      height={decideContainerHeight()}
      backgroundColor={decidePageBackgroundColor()}>
      <Router>
      {/* { decideViewNavigationBar() && <TopBar/> } */}
      <TopBar nowPage={nowPage}/>
      <ContentWrapper 
        maxWidth={decideContentWrapperMaxWidth()}
        height={decideContentWrapperHeight()}>
        {/* { decideViewNavigationBar() && <NavigationBar nowPage={nowPage} setNowPage={setNowPage}/> } */}
        <NavigationBar defaultURL={defaultURL} nowPage={nowPage} setNowPage={setNowPage}/>
        <Routes>
          {/* 차후에 detail페이지 eventId도 받아야함 -> 현재는 organizerId만 받음 */}
          {/* <Route path='/info/:organizerId/:token' element={<EventInfoPage />} /> */}
          <Route path='/info/:organizerId/:token' element={<InfoPage/>} />
          <Route path='/info_test/:organizerId/:token' element={<TestRoutingPage/>} />
          <Route path='/info/legacy/:organizerId' element={<EventInfoPage/>} />
          <Route path='/member/detail' element={<MemberDetailPage/>} />
          <Route path='/agree/:organizerId' element={<EventAgreePage/>} />
          <Route path='/select/:organizerId' element={<EventSelectPage/>} />
          <Route path='/application/:eventId/:isTeamFight/:isModify' element={<EventApplicationPage/>} />
          <Route path='/check/:eventId/:isTeamFight/:isModify' element={<EventApplicationInfocheckPage/>} />
          {/* <Route path='/payment/complete/:status' element={<PaymentCompletePage/>} /> */}
          <Route path='/free/payment/complete' element={<FreePaymentCompletePage/>} />
          {/* <Route path='/login' element={<LoginPage/>}/> */}
          <Route path='/admin' element={<AdminPage/>}/>
          <Route path='/modify/ranking/:eventId' element={<ModifyRankingPage/>}/>

          <Route path='/payment/:merchantUid/:orderName/:amount/:token' element={<FinalPaymentPage/>} />

          <Route path='/ranking/:organizerId/:eventId/:sectionLength/:section/:eventName/:eventDate' element={<RankingPage/>} />

          <Route path='/account_transfer/:eventId/:token' element={<AccountTransferPage/>} />

          <Route path='/payment/admin' element={<AdminPaymentPage/>} />
          <Route path='/payment/request/:itemName/:merchantUid/:token' element={<RequestPaymentPage/>} />
          <Route path='/payment/complete/:status/:error/:merchantUid' element={<CompletePaymentPage/>} />

          {/* Marvel */}
          <Route path='/detail/1/:token' element={<MarvelEventDetailPage/>} /> 
          {/* Marvel */}

          {/* Battle Crew2 */}
          <Route path='/detail/2/:token' element={<BattleCrewEvent2DetailPage/>} />
          <Route path='/detail/3/:token' element={<BattleCrewEvent3DetailPage/>} />
          {/* Battle Crew2 */}

          {/* Far East Throwdown Online */}
          <Route path='/detail/4/:token' element={<FETOnlineJANUARYDetailPage/>} />
          <Route path='/detail/5/:token' element={<FETOnlineJANUARYDetailPage/>} />
          <Route path='/detail/6/:token' element={<FETOnlineFEBRUARYDetailPage/>} />
          <Route path='/detail/7/:token' element={<FETOnlineMARCHDetailPage/>} />
          <Route path='/detail/8/:token' element={<FETOnlineAPRILDetailPage/>} />
          {/* Far East Throwdown Online */}

          {/* SUFF */}
          <Route path='/detail/9/:token' element={<SUFFIndividualDetailPage/>}/>
          <Route path='/detail/10/:token' element={<SUFFRxdTeamDetailPage/>}/>
          <Route path='/detail/11/:token' element={<SUFFScaleTeamDetailPage/>}/>
          {/* SUFF */}

          {/* FET Sweat Chanllenge */}
          <Route path='/detail/12/:token' element={<FETSweatChanllengeDetailPage/>}/>
          {/* FET Sweat Chanllenge */}

          {/* Far East Throwdown Online */}
          <Route path='/rank/4' element={<JanuaryCustomRankPage/>}/>
          <Route path='/rank/5' element={<JanuaryCustomRankPage/>}/>
          <Route path='/rank/6' element={<FebruaryCustomRankPage/>}/>
          <Route path='/rank/7' element={<MarchCustomRankPage/>}/>
          <Route path='/rank/8' element={<AprilCustomRankPage/>}/>
          {/* Far East Throwdown Online */}


          {/* SUFF */}
          <Route path='/rank/9' element={<SUFFIndividualCustomRankPage/>}/>
          <Route path='/rank/10' element={<SUFFRxdTeamCustomRankPage/>}/>
          <Route path='/rank/11' element={<SUFFScaleTeamCustomRankPage/>}/>
          {/* SUFF */}

          {/* FET Sweat Chanllenge */}
          <Route path='/rank/12' element={<SweatChanllengeCustomRankPage/>}/>
          {/* FET Sweat Chanllenge */}

          <Route path='/rank/detail' element={<CustomRankDetailPage/>}/>
          {/* Far East Throwdown Online */}

          {/* Battle Crew 3 */}
          <Route path='/battlecrew3/admin' element={<BattleCrew3AdminPage/>} />
          <Route path='/info/battlecrew3' element={<BattleCrew3EventInfoPage/>} />
          <Route path='/agree/battlecrew3' element={<BattleCrew3EventAgreePage/>} />
          <Route path='/select/battlecrew3' element={<BattleCrew3EventSelectPage/>} />
          <Route path='/application/battlecrew3/:eventId/:eventDay' element={<BattleCrew3EventApplicationPage/>} />
          <Route path='/check/battlecrew3/:eventId/:eventDay' element={<BattleCrew3EventApplicationInfocheckPage/>} />
          <Route path='/complete/battlecrew3/:eventTitle/:eventDay/:teamName/:membersName' element={<BattleCrew3EventCompletePage/>} />
          <Route path='/detail/13/:token' element={<BattleCrew3EventDetailPage/>}/>
          <Route path='/detail/14/:token' element={<BattleCrew3EventDetailPage/>}/>
          <Route path='/detail/15/:token' element={<BattleCrew3EventDetailPage/>}/>
          {/* Battle Crew 3 */}

          {/* The Performance Workshop */}
          <Route path='/info/ThePerformanceWorkshop' element={<TPWorkshopInfoPage/>} />
          <Route path='/agree/ThePerformanceWorkshop' element={<TPWorkshopAgreePage/>} />
          <Route path='/select/ThePerformanceWorkshop' element={<TPWorkshopSelectPage/>} />
          <Route path='/application/ThePerformanceWorkshop/:eventId' element={<TPWorkshopApplicationPage/>} />
          <Route path='/check/ThePerformanceWorkshop/:eventId' element={<TPWorkshopApplicationInfoCheckPage/>} />
          <Route path='/complete/ThePerformanceWorkshop' element={<TPWorkshopCompletePage/>} />
          <Route path='/detail/16/:token' element={<TPWorkshopDetailInfoPage/>} />
          <Route path='/detail/17/:token' element={<TPWorkshopDetailInfoPage/>} />
          {/* The Performance Workshop */}

          {/* Fianl SUFF */}
          <Route path='/detail/19/:token' element={<FinalSUFFIndividualDetailPage/>} />
          <Route path='/detail/20/:token' element={<FinalSUFFRxdTeamDetailPage/>} />
          <Route path='/detail/21/:token' element={<FinalSUFFScaleTeamDetailPage/>} />
          <Route path='/rank/19' element={<FinalSUFFIndividualRankingPage/>}/>
          <Route path='/rank/20' element={<FinalSUFFRxdTeamRankingPage/>} />
          <Route path='/rank/21' element={<FinalSUFFScaleTeamRankingPage/>} />
          <Route path='/final_SUFF/admin' element={<FinalSUFFAdminPage/>} />
          {/* Final SUFF */}

          {/* GirlFit */}
          <Route path='/info/girlfit' element={<GirlFitInfoPage/>} />
          <Route path='/agree/girlfit' element={<GirlFitAgreePage/>} />
          <Route path='/select/girlfit' element={<GirlFitSelectPage/>} />
          <Route path='/application/girlfit/:eventId' element={<GirlFitApplicationPage/>} />
          <Route path='/check/girlfit/:eventId' element={<GirlFitApplicationInfoCheckPage/>} />
          <Route path='/complete/girlfit/:eventId/:teamName/:memberNames' element={<GirlFitCompletePage/>} />
          <Route path='/detail/22/:token' element={<GirlFitIndividualDetailPage/>} />
          <Route path='/detail/23/:token' element={<GirlFitIndividualDetailPage/>} />
          <Route path='/detail/24/:token' element={<GirlFitTeamDetailPage/>} />
          <Route path='/rank/22' element={<GirlFitIndividualRankingPage/>} />
          <Route path='/rank/23' element={<GirlFitIndividualRankingPage/>} />
          <Route path='/rank/24' element={<GirlFitTeamRankingPage/>} />
          <Route path='/girlfit/admin' element={<GirlFitAdminPage/>} />
          {/* GirlFit */}

          {/* GirlCrush */}
          <Route path='/info/girlcrush' element={<GirlCrushInfoPage/>} />
          <Route path='/agree/girlcrush' element={<GirlCrushAgreePage/>} />
          <Route path='/select/girlcrush' element={<GirlCrushSelectPage/>} />
          <Route path='/application/girlcrush/:eventId' element={<GirlCrushApplicationPage/>} />
          <Route path='/check/girlcrush/:eventId/:isFirst' element={<GirlCrushApplicationInfoCheckPage/>} />
          <Route path='/complete/girlcrush/:eventId/:teamName/:memberNames/:isFirst' element={<GirlCrushCompletePage/>} />
          {/* <Route path='/detail/25/:token' element={<GirlCrushDetailPage/>} /> */}
          <Route path='/detail/27/:token' element={<GirlCrushDetailPage/>} />
          <Route path='/rank/girlcrush' element={<GirlCrushRankingPage/>} />
          {/* GirlCrush */}

          {/* Battle Crew 3 Final */}
          <Route path='/detail/28/:token' element={<BattleCrew3FinalDetailPage/>} />
          <Route path='/rank/battlecrew3_final' element={<BattleCrew3FinalRankingPage/>} />
          {/* Battle Crew 3 Final */}

          {/* HYROX */}
          <Route path='/info/hyrox/' element={<HyroxInfoPage/>} />
          {/* HYROX */}

          {/* CrossFit Games Open */}
          <Route path='/info/crossfitgamesopen/' element={<CrossfitGamesOpenInfoPage/>} />
          {/* CrossFit Games Open */}

          {/* Fit Force Team League */}
          <Route path='/info_test/fitforceteamleague/' element={<FitForceTeamLeagueTestInfoPage/>} />
          <Route path='/info/fitforceteamleague/' element={<FitForceTeamLeagueInfoPage/>} />
          <Route path='/agree/fitforceteamleague/:eventId' element={<FitForceTeamLeagueAgreePage/>} />
          <Route path='/select/fitforceteamleague/' element={<FitForceTeamLeagueSelectPage/>} />
          <Route path='/application/fitforceteamleague/:eventId' element={<FitForceTeamLeagueApplicationPage/>}/>
          <Route path='/check/fitforceteamleague/:eventId' element={<FitForceTeamLeagueApplicationInfoCheckPage/>}/>
          <Route path='/payment/fitforceteamleague/:merchantUid/:orderName/:amount/:token' element={<FitForceTeamLeaguePaymentPage/>}/>
          <Route path='/complete/fitforceteamleague/:eventId/:teamName/:memberNames/:isFirst' element={<FitForceTeamLeagueCompletePage/>} />

          <Route path='/detail/34/:token' element={<FitForceTeamLeagueDetail1Page/>}/>
          <Route path='/detail/35/:token' element={<FitForceTeamLeagueDetail2Page/>}/>
          <Route path='/detail/36/:token' element={<FitForceTeamLeagueDetail3Page/>}/>
          
          <Route path='/application/state/34/:token' element={<FitForceTeamLeagueDetail1ReplacePage/>}/>
          <Route path='/application/state/35/:token' element={<FitForceTeamLeagueDetail2ReplacePage/>}/>
          <Route path='/application/state/36/:token' element={<FitForceTeamLeagueDetail3ReplacePage/>}/>
          {/* <Route path='/detail/40/:token' element={<FitForceTeamLeagueDetail1Page/>}/>
          <Route path='/detail/41/:token' element={<FitForceTeamLeagueDetail2Page/>}/>
          <Route path='/detail/42/:token' element={<FitForceTeamLeagueDetail3Page/>}/> */}
          <Route path='/rank/fitforceteamleague/:eventId' element={<FitForceTeamLeagueRankingPage/>} />
          <Route path='/rank/finalfitforceteamleague4people/:eventId' element={<FinalFitForceTeamLeague4PeopleRankingPage/>} />
          <Route path='/participant/fitforceteamleague/:eventId' element={<FitForceTeamLeagueGetParticipantListPage/>} />
          {/* Fit Force Team League */}

          {/* Believer */}
          <Route path='/info_test/believer/' element={<BelieverTestInfoPage/>} />
          <Route path='/info/believer/' element={<BelieverInfoPage/>} />
          <Route path='/agree/believer/' element={<BelieverAgreePage/>} />
          <Route path='/select/believer/' element={<BelieverSelectPage/>} />
          <Route path='/application/believer/:eventId' element={<BelieverApplicationPage/>} />
          <Route path='/check/believer/:eventId' element={<BelieverApplicationInfoCheckPage/>} />
          <Route path='/payment/believer/:merchantUid/:orderName/:amount/:token' element={<BelieverPaymentPage/>}/>
          <Route path='/complete/believer/:eventId/:teamName/:memberNames/:isFirst' element={<BelieverCompletePage/>}/>
          {/* <Route path='/detail/37/:token' element={<BelieverDetailReplacePage/>}/> */}
          <Route path='/detail/37/:token' element={<BelieverDetailPage/>}/>
          <Route path='/rank/believer' element={<BelieverRankingPage/>}/>
          <Route path='/application/state/37/:token' element={<BelieverApplicationStatePage/>}/>
          <Route path='/participant/believer' element={<BelieverGetParticipantListPage/>} />
          {/* Believer */}

          {/* AFK */}
          <Route path='/info_test/afk/' element={<AFKInfoTestPage/>} />
          <Route path='/info/afk/' element={<AFKInfoPage/>} />
          <Route path='/agree/afk/' element={<AFKAgreePage/>} />
          <Route path='/select/afk/' element={<AFKSelectPage/>} />
          <Route path='/application/afk/:eventId' element={<AFKApplicationPage/>} />
          <Route path='/check/afk/:eventId' element={<AFKApplicationInfoCheckPage/>} />
          <Route path='/payment/afk/:merchantUid/:orderName/:amount/:token' element={<AFKPaymentPage/>} />
          <Route path='/application/state/38/:token' element={<AFKApplicationStatePage/>}/>
          <Route path='/complete/afk/:eventId/:teamName/:memberNames/:isFirst' element={<AFKCompletePage/>} />
          <Route path='/detail/38/:token' element={<AFKDetailPage/>} />
          {/* AFK */}

          {/* Link Coach Dead Lift */}
          <Route path='/info/linkcoach_deadlift/' element={<LinkCoachDeadLiftInfoPage/>} />
          <Route path='/agree/linkcoach_deadlift/' element={<LinkCoachDeadLiftAgreePage/>} />
          <Route path='/select/linkcoach_deadlift/' element={<LinkCoachDeadLiftSelectPage/>} />
          <Route path='/application/linkcoach_deadlift/:eventId' element={<LinkCoachDeadLiftApplicationPage/>} />
          <Route path='/check/linkcoach_deadlift/:eventId' element={<LinkCoachDeadLiftApplicationInfoCheckPage/>} />
          <Route path='/payment/linkcoach_deadlift/:eventId/:merchantUid/:token' element={<LinkCoachDeadLiftPaymentPage/>} />
          <Route path='/complete/linkcoach_deadlift/:eventId' element={<LinkCoachDeadLiftCompletePage/>} />
          <Route path='/application/state/39/:token' element={<LinkCoachDeadLiftApplicationStatePage/>} />
          <Route path='/detail/39/:token' element={<LinkCoachDeadLiftDetailPage/>} />
          {/* Link Coach Dead Lift */}

          {/* R2C */}
          <Route path='/detail/43/:token' element={<R2CWomanRxdDetailPage/>}/>
          <Route path='/detail/44/:token' element={<R2CWomanScaledDetailPage/>}/>
          <Route path='/detail/45/:token' element={<R2CManRxdDetailPage/>}/>
          <Route path='/detail/46/:token' element={<R2CManScaledDetailPage/>}/>
          <Route path='/rank/r2c/:eventId' element={<R2CRankPage/>}/>
          {/* R2C */}

          {/* Fit Force Team League 본선 */}
          <Route path='/info/finalfitforceteamleague/' element={<FinalFitForceTeamLeagueInfoPage/>} />
          <Route path='/check/finalfitforceteamleague/:eventId' element={<FinalFitForceTeamLeagueApplicationInfoCheckPage/>} />
          <Route path='/payment/finalfitforceteamleague/:merchantUid/:token' element={<FinalFitForceTeamLeagueRequestPayment/>} />
          <Route path='/complete/finalfitforceteamleague/:status/:error/:merchantUid' element={<FinalFitForceTeamLeaguePaymentCompletePage/>} />
          <Route path='/application_state/finalfitforceteamleague/:eventId/:teamName/:memberNames/:isFirst/:token' element={<FinalFitForceTeamLeagueApplicationStatePage/>} />
          <Route path='/detail/47/:token' element={<FinalFitForceTeamLeagueDetail1Page/>} />
          <Route path='/detail/48/:token' element={<FinalFitForceTeamLeagueDetail2Page/>} />
          <Route path='/rank/finalfitforceteamleague/:eventId' element={<FinalFitForceTeamLeagueRankingPage/>} />
          {/* <Route path='/detail//:token' element={<FinalFitForceTeamLeagueDetailPage/>} /> */}
          {/* Fit Force Team League 본선 */}

          {/* Summit Camp */}
          <Route path='/info/summitcamp/' element={<SummitCampInfoPage/>} />
          <Route path='/agree/summitcamp/' element={<SummitCampAgreePage/>} />
          <Route path='/select/summitcamp/' element={<SummitCampSelectPage/>} />
          <Route path='/application/summitcamp/:eventId' element={<SummitCampApplicationPage/>} />
          <Route path='/check/summitcamp/:eventId' element={<SummitCampApplicationInfoCheckPage/>} />
          <Route path='/payment/summitcamp/:itemName/:merchantUid/:token' element={<SummitCampRequestPaymentPage/>} />
          {/* <Route path='/complete/summitcamp/:status/:error/:merchantUid' element={<SummitCampPaymentCompletePage/>} /> */}
          {/* <Route path='/application/state/:eventId/:token' element={<SummitCampApplicationStatePage/>} /> */}
          <Route path='/complete/summitcamp/:eventId' element={<SummitCampCompletePage/>} />
          <Route path='/detail/50/:token' element={<SummitCampApplicationStatePage/>} />
          <Route path='/detail/51/:token' element={<SummitCampApplicationStatePage/>} />
          {/* Summit Camp */}

          {/* Show Your Performance */}
          <Route path='/info/show_your_performance/' element={<ShowYourPerformanceInfoPage/>} />
          <Route path='/agree/show_your_performance/' element={<ShowYourPerformanceAgreePage/>} />
          <Route path='/select/show_your_performance/' element={<ShowYourPerformanceSelectPage/>} />
          <Route path='/application/show_your_performance/' element={<ShowYourPerformanceApplicationPage/>} />
          <Route path='/check/show_your_performance/' element={<ShowYourPerformanceApplicationInfoCheckPage/>} />
          <Route path='/payment/show_your_performance/:itemName/:merchantUid/:token' element={<ShowYourPerformanceRequestPaymentPage/>} />
          <Route path='/complete/show_your_performance/' element={<ShowYourPerformanceCompletePage/>}/>
          <Route path='/detail/52/:token' element={<ShowYourPerformanceDetailPage/>} />
          <Route path='/rank/show_your_performance/' element={<ShowYourPerformanceRankingPage/>} />
          {/* Show Your Performance */}

          {/* Gorilla Throwdown */}
          <Route path='/detail/53/:token' element={<GorillaThrowdownDetailPage/>} />
          <Route path='/rank/gorilla_throwdown' element={<GorillaThrowdownRankingPage/>} />
          {/* Gorilla Throwdown */}

          {/* Team Of Four */}
          <Route path='/info/2024_team_of_4' element={<TeamOfFourInfoPage/>} />
          <Route path='/agree/2024_team_of_4' element={<TeamOfFourAgreePage/>} />
          <Route path='/select/2024_team_of_4' element={<TeamOfFourSelectPage/>} />
          <Route path='/application/2024_team_of_4' element={<TeamOfFourApplicationPage/>} />
          <Route path='/check/2024_team_of_4' element={<TeamOfFourApplicationCheckPage/>} />
          <Route path='/complete/2024_team_of_4' element={<TeamOfFourCompletePage/>} />
          <Route path='/modify/2024_team_of_4' element={<TeamOfFourModifyPage/>} />
          <Route path='/participant/2024_team_of_4' element={<TeamOfFourParticipantListPage/>} />
          {/* <Route path='/detail/54/:token' element={<TeamOfFourCompletePage/>} /> */}
          <Route path='/detail/54/:token' element={<TeamOfFourDetailPage/>} />
          <Route path='/rank/team_of_4' element={<TeamOfFourRankingPage/>} />
          {/* Team Of Four */}

          {/* GirlCrush Season2 */}
          <Route path='/info/girl_crush_season2' element={<GirlCrushSeason2InfoPage/>} />
          <Route path='/agree/girl_crush_season2' element={<GirlCrushSeason2AgreePage/>} />
          <Route path='/select/girl_crush_season2' element={<GirlCrushSeason2SelectPage/>} />
          <Route path='/application/girl_crush_season2' element={<GirlCrushSeason2ApplicationPage/>} />
          <Route path='/check/girl_crush_season2' element={<GirlCrushSeason2ApplicationCheckPage/>} />
          <Route path='/complete/girl_crush_season2' element={<GirlCrushSeason2CompletePage/>} />
          <Route path='/modify/girl_crush_season2' element={<GirlCrushSeason2ModifyPage/>} />
          {/* <Route path='/detail/55/:token' element={<GirlCrushSeason2CompletePage/>} /> */}
          <Route path='/detail/55/:token' element={<GirlCrushSeason2DetailPage/>} />
          <Route path='/rank/girl_crush_season2' element={<GirlCrushSeason2RankingPage/>} />
          <Route path='/participant/girl_crush_season2' element={<GirlCrushSeason2ParticipantListPage/>} />
          {/* GirlCrush Season2 */}

          {/* The Mentality */}
          <Route path='/info/the_mentality' element={<TheMentalityInfoPage/>} />
          <Route path='/agree/the_mentality' element={<TheMentalityAgreePage/>} />
          <Route path='/select/the_mentality' element={<TheMentalitySelectPage/>} />
          <Route path='/application/the_mentality/:eventId' element={<TheMentalityApplicationPage/>} />
          <Route path='/check/the_mentality/:eventId' element={<TheMentalityApplicationCheckPage/>} />
          <Route path='/complete/the_mentality/56' element={<TheMentalityComplete1Page/>} />
          <Route path='/complete/the_mentality/57' element={<TheMentalityComplete2Page/>} />
          <Route path='/modify/the_mentality/:eventId' element={<TheMentalityModifyPage/>} />
          {/* <Route path='/detail/56/:token' element={<TheMentalityComplete1Page/>} />
          <Route path='/detail/57/:token' element={<TheMentalityComplete2Page/>} /> */}
          <Route path='/detail/56/:token' element={<TheMentalityDetail1Page/>} />
          <Route path='/detail/57/:token' element={<TheMentalityDetail2Page/>} />

          <Route path='/application/state/56/:token' element={<TheMentalityComplete1Page/>} />
          <Route path='/application/state/57/:token' element={<TheMentalityComplete2Page/>} />
          <Route path='/rank/the_mentality/56' element={<TheMentality1RankingPage/>} />
          <Route path='/rank/the_mentality/57' element={<TheMentality2RankingPage/>} />
          <Route path='/participant/the_mentality' element={<TheMentalityParticipantListPage/>} />
          {/* The Mentality */}

          {/* 2025 URBANFIT INVITATIONAL */}
          <Route path='/info/urbanfit_invitational' element={<UrbanfitInvitationalInfoPage/>} />
          <Route path='/agree/urbanfit_invitational' element={<UrbanfitInvitationalAgreePage/>} />
          <Route path='/select/urbanfit_invitational' element={<UrbanfitInvitationalSelectPage/>} />
          <Route path='/application/urbanfit_invitational/:eventId' element={<UrbanfitInvitationalApplicationPage/>} />
          <Route path='/check/urbanfit_invitational/:eventId' element={<UrbanfitInvitationalApplicationCheckPage/>} />
          <Route path='/complete/urbanfit_invitational/:eventId' element={<UrbanfitInvitationalCompletePage/>} />
          <Route path='/modify/urbanfit_invitational/:eventId' element={<UrbanfitInvitationalModifyPage/>} />
          <Route path='/detail/58/:token' element={<UrbanfitInvitationalDetail1Page/>} />
          <Route path='/detail/59/:token' element={<UrbanfitInvitationalDetail2Page/>} />
          <Route path='/detail/60/:token' element={<UrbanfitInvitationalDetail3Page/>} />
          <Route path='/application/state/58/:token' element={<UrbanfitInvitationalComplete1Page/>} />
          <Route path='/application/state/59/:token' element={<UrbanfitInvitationalComplete2Page/>} />
          <Route path='/application/state/60/:token' element={<UrbanfitInvitationalComplete3Page/>} />
          <Route path='/rank/urbanfit_invitational/58' element={<UrbanfitInvitationalRanking1Page/>} />
          <Route path='/rank/urbanfit_invitational/59' element={<UrbanfitInvitationalRanking2Page/>} />
          <Route path='/rank/urbanfit_invitational/60' element={<UrbanfitInvitationalRanking3Page/>} />
          <Route path='/participant/urbanfit_invitational' element={<UrbanfitInvitationalParticipantListPage/>} />
          {/* 2025 URBANFIT INVITATIONAL */}

          {/* Fit Force Team League 2025 */}
          <Route path='/info/fitforceteamleague2025' element={<FitForceTeamLeague2025InfoPage/>} />
          <Route path='/application/fitforceteamleague2025/:eventId/:selectOptionId' element={<FitForceTeamLeague2025ApplicationPage/>} />
          <Route path='/check/fitforceteamleague2025/:eventId/:selectOptionId' element={<FitForceTeamLeague2025ApplicationCheckPage/>} />
          <Route path='/complete/fitforceteamleague2025' element={<FitForceTeamLeague2025CompletePage/>} />
          <Route path='/detail/83/:token' element={<FitForceTeamLeague2025CompletePage/>} />
          <Route path='/application/state/83/:token' element={<FitForceTeamLeague2025CompletePage/>} />
          <Route path='/modify/fitforceteamleague2025/:eventId/:selectOptionId' element={<FitForceTeamLeague2025ApplicationModifyPage/>} />
          <Route path='/participant/fitforceteamleague2025' element={<FitForceTeamLeague2025ParticipantListPage/>} />
          {/* Fit Force Team League 2025 */}

          {/* Suff 2025 */}
          <Route path='/detail/:eventId/:token' element={<CompletePage/>} />
          {/* Suff 2025 */}

          {/* URL 형식으로 앱 불러오는 라우팅 페이지 */}
          <Route path='/view/:url_key' element={<DeeplinkRoutePage/>} />
          <Route path='/state/:eventId' element={<StateDeeplinkRoutePage/>} />
          {/* URL 형식으로 앱 불러오는 라우팅 페이지 */}

          {/* 이벤터 로그인 페이지 */}
          <Route path='/v2/login' element={<EventerLoginPage/>} />
          {/* 이벤터 로그인 페이지 */}

          {/* 이벤터 메인 페이지 */}
          <Route path='/main' element={<MainPage/>} />
          {/* 이벤터 메인 페이지 */}

          {/* 이벤터 상품 페이지 */}
          <Route path='/product' element={<ProductPage/>} />
          {/* 이벤터 상품 페이지 */}

          {/* 이벤터 약관 페이지 */}
          <Route path='/terms' element={<TermsPage/>} />
          {/* 이벤터 약관 페이지 */}

          {/* 이벤터 유닛 페이지 (참가자) */}
          <Route path='/unit' element={<UnitPage/>} />
          {/* 아밴터 유닛 페이지 (참가자) */}

          {/* 이벤터 유닛 자세히 보기 페이지 (참가자) */}
          <Route path='/unit/member/:memberId' element={<UnitMemberPage/>} />
          <Route path='/unit/team/:teamId' element={<UnitTeamPage/>} />
          {/* 아밴터 유닛 자세히 보기 페이지 (참가자) */}

          {/* 노트 페이지 */}
          <Route path='/note' element={<NotePage/>} />
          {/* 노트 페이지 */}

          {/* 이벤터 설정 페이지 */}
          <Route path='/setting' element={<SettingPage/>} />
          {/* 이벤터 설정 페이지 */}

          {/* 이벤터로 만든 사용자용 페이지 */}
          <Route path='/info/v2/:organizerId' element={<NewEventPage/>} />
          <Route path='/agree/v2/:organizerId' element={<AgreementPage/>} />
          <Route path='/select/v2/:organizerId' element={<SelectEventPage/>} />
          <Route path='/application/v2/:eventId/:selectOptionId' element={<ApplicationPage/>} />
          <Route path='/check/v2/:eventId/:selectOptionId' element={<ApplicationCheckPage/>} />
          <Route path='/complete/v2/:eventId' element={<CompletePage/>} />
          <Route path='/application/state/:eventId/:token' element={<CompletePage/>} />
          <Route path='/modify/v2/:eventId/:selectOptionId' element={<ApplicationModifyPage/>} />
          {/* 이벤터로 만든 사용자용 페이지 */}
          
          <Route path='/login' element={<LoginPage/>} />
          {/* URL이 매치되지 않았을 경우 */}
          {/* <Route path='*' element={<EventInfoPage />} /> */}
          <Route path='*' element={<NotFoundPage/>} />
        </Routes>
        </ContentWrapper>
      </Router>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: ${props => props.height};

  overflow-x: hidden;

  background-color: ${props => props.backgroundColor};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-start;

  width: 100%;
  max-width: ${props => props.maxWidth};

  // height: 100%;
  height: ${props => props.height};
`;              