import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import EditView from "../Model/EditView";
import UserView from "../Model/UserView";
import FetchModule from "../../../Model/Network/FetchModule";
import SettingView from "../Model/SettingView";
import useWindowSize from "../../Share/useWindowSize";

export default function MainPage(props) {

  const [isClickedModify, setIsClickedModify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [organizerId, setOrganizerId] = useState(undefined);
  const [isSubmit, setIsSubmit] = useState(false);

  // Original Data //
  const [originalTitle, setOriginalTitle] = useState('');
  const [originalMainPicture, setOriginalMainPicture] = useState('');
  const [originalBannerPicture, setOriginalBannerPicture] = useState('');
  const [originalContent, setOriginalContent] = useState('');
  const [originalPeriod, setOriginalPeriod] = useState('');
  const [originalStartDate, setOriginalStartDate] = useState(moment());
  const [originalStartTime, setOriginalStartTime] = useState('00:00');
  const [originalEndDate, setOriginalEndDate] = useState(moment());
  const [originalEndTime, setOriginalEndTime] = useState('00:00');
  const [originalPlace, setOriginalPlace] = useState('');
  const [originalSubjectivity, setOriginalSubjectivity] = useState('');
  const [originalSponsor, setOriginalSponsor] = useState('');
  const [originalSupport, setOriginalSupport] = useState('');
  const [originalSouvenirPicture, setOriginalSouvenirPicture] = useState('');
  const [originalTickets, setOriginalTickets] = useState([]);
  // Original Data //

  // 변경 데이터 //
  const [title, setTitle] = useState('');
  const [mainPicture, setMainPicture] = useState('');
  const [bannerPicture, setBannerPicture] = useState('');
  const [content, setContent] = useState('');
  const [period, setPeriod] = useState('');
  const [startDate, setStartDate] = useState(moment());
  const [startTime, setStartTime] = useState('00:00');
  const [endDate, setEndDate] = useState(moment());
  const [endTime, setEndTime] = useState('00:00');
  const [place, setPlace] = useState('');
  const [subjectivity, setSubjectivity] = useState('');
  const [sponsor, setSponsor] = useState('');
  const [support, setSupport] = useState('');
  const [souvenirPicture, setSouvenirPicture] = useState('');
  const [tickets, setTickets] = useState([]);
  // 변경 데이터 //

  // 사진 크기 // 
  const [mainPictureSize, setMainPictureSize] = useState(200);
  const [bannerPictureWidth, setBannerPictureWidth] = useState(343);
  const [bannerPictureHeight, setBannerPictureHeight] = useState(148);
  const [commonSouvenirWidth, setCommonSouvenirWidth] = useState(200);
  const [commonSouvenirHeight, setCommonSouvenirHeight] = useState(200);

  const [plusWidth, setPlusWidth] = useState(50);
  const [plusHeight, setPlusHeight] = useState(50);
  // 사진 크기 //

  const { width, height } = useWindowSize();

  const TotalRef = useRef();

  // 사진 크기 결정 //
  useEffect(() => {
    let totalWidth = TotalRef.current.offsetWidth < 607 ? TotalRef.current.offsetWidth : 607;

    let mainPictureSize = parseInt(totalWidth * 0.3294) - 4;
    let bannerPictureWidth = parseInt(totalWidth * 0.565) - 4;
    let bannerPictureHeight = parseInt(bannerPictureWidth * 0.4314) - 4;
    let commonSouvenirWidth = parseInt(totalWidth * 0.3294) - 4;
    let commonSouvenirHeight = parseInt(commonSouvenirWidth * (1350 / 1080));

    let plusWidth = parseInt(totalWidth * 0.082);
    let plusHeight = parseInt(totalWidth * 0.009);

    setMainPictureSize(mainPictureSize);
    setBannerPictureWidth(bannerPictureWidth);
    setBannerPictureHeight(bannerPictureHeight);
    setCommonSouvenirWidth(commonSouvenirWidth);
    setCommonSouvenirHeight(commonSouvenirHeight);

    setPlusWidth(plusWidth);
    setPlusHeight(plusHeight);
  }, [TotalRef, width])

  // API Call //
  useEffect(() => {
    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('tournament/info', 'GET');
        console.log(responseData.data);
        if (responseData.status === 200) {
          if (responseData.data !== undefined) {
            let data = responseData.data;

            setOrganizerId(data.organizerId);
            setIsSubmit(responseData.data.isSubmit);

            setOriginalTitle(data.title);
            setOriginalMainPicture(data.mainPicture);
            setOriginalBannerPicture(data.bannerPicture);
            setOriginalContent(data.content);
            setOriginalPeriod(data.period);
            setOriginalPlace(data.place);
            setOriginalSubjectivity(data.subjectivity);
            setOriginalSponsor(data.sponsor);
            setOriginalSupport(data.support);
            setOriginalSouvenirPicture(data.souvenirPicture);

            setOriginalStartDate(moment(data.startDate));
            setOriginalStartTime(moment(data.startDate).format('HH:mm'));
            setOriginalEndDate(moment(data.endDate));
            setOriginalEndTime(moment(data.endDate).format('HH:mm'));

            for (let i = 0; i < data.tickets.length; i++) {
              for (let j = 0; j < data.tickets[i].priceOptions.length; j++) {
                data.tickets[i].priceOptions[j].appliedDate = moment(data.tickets[i].priceOptions[j].appliedDate);
              }
            }

            setOriginalTickets(data.tickets);

            setTitle(data.title);
            setMainPicture(data.mainPicture);
            setBannerPicture(data.bannerPicture);
            setContent(data.content);
            setPeriod(data.period);
            setPlace(data.place);
            setSubjectivity(data.subjectivity);
            setSponsor(data.sponsor);
            setSupport(data.support);
            setSouvenirPicture(data.souvenirPicture);

            setStartDate(moment(data.startDate));
            setStartTime(moment(data.startDate).format('HH:mm'));
            setEndDate(moment(data.endDate));
            setEndTime(moment(data.endDate).format('HH:mm'));

            setTickets(data.tickets);
          }
        }
      }
    )();
  }, [])

  // 저장하기 클릭시 //
  function onClickSave() {
    // alert('신청중에는 수정이 불가능합니다');
    // return;

    if (isSubmit) {
      alert('승인된 경우 수정이 불가능합니다.');
      return;
    }
    else if (organizerId !== undefined && moment().isAfter(startDate)) {
      alert('신청이 진행중인경우 수정이 불가능합니다');
      return;
    }

    if (!isClickedModify) {
      setTitle(originalTitle);
      setMainPicture(originalMainPicture);
      setBannerPicture(originalBannerPicture);
      setContent(originalContent);
      setPeriod(originalPeriod);
      setStartDate(originalStartDate);
      setStartTime(originalStartTime);
      setEndDate(originalEndDate);
      setEndTime(originalEndTime);
      setPlace(originalPlace);
      setSubjectivity(originalSubjectivity);
      setSponsor(originalSponsor);
      setSupport(originalSupport);
      setSouvenirPicture(originalSouvenirPicture);
      setTickets(originalTickets);

      setIsClickedModify(true);
    }
    else {
      if (title === '') {
        alert('이벤트 이름을 입력해주세요');
        return;
      }
      else if (mainPicture === '') {
        alert('정방향 이미지를 업로드해주세요');
        return;
      }
      else if (bannerPicture === '') {
        alert('가로형 이미지를 업로드해주세요');
        return;
      }
      else if (tickets.length === 0) {
        alert('이벤트 티켓을 추가해주세요');
        return;
      }
      else {
        for (let i = 0; i < tickets.length; i++) {
          if (tickets[i].name === '') {
            alert('이벤트 티켓 이름을 입력해주세요');
            return;
          }
          else if (tickets[i].entryQualification === '') {
            alert('참가 자격을 입력해주세요');
            return;
          }
          else if (tickets[i].teamMemberCount === '' || tickets[i].teamMemberCount === 0) {
            alert('참가 인원을 입력해주세요');
            return;
          }
          else if (tickets[i].isUseParticipantLimit && (tickets[i].participantLimit === '' || tickets[i].participantLimit === 0)) {
            alert('선착순 참가 인원을 입력해주세요');
            return;
          }
          else if (tickets[i].place === '') tickets[i].place = 'Online';
        }
      }

      if (isLoading) {
        alert('저장중입니다');
        return;
      }

      setIsLoading(true);

      let requestData = {
        organizerId: organizerId,
        title: title,
        mainPicture: mainPicture,
        bannerPicture: bannerPicture,
        content: content,
        period: period,
        startDate: startDate.format('YYYY-MM-DD') + 'T' + startTime + ':00',
        endDate: endDate.format('YYYY-MM-DD') + 'T' + endTime + ':00',
        place: place,
        subjectivity: subjectivity,
        sponsor: sponsor,
        support: support,
        souvenirPicture: souvenirPicture,
        tickets: tickets
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEventVer2('tournament/save', 'POST', requestData);

          if (responseData.status === 200) {
            let data = responseData.data;

            setOrganizerId(data.organizerId);

            setOriginalTitle(data.title);
            setOriginalMainPicture(data.mainPicture);
            setOriginalBannerPicture(data.bannerPicture);
            setOriginalContent(data.content);
            setOriginalPeriod(data.period);
            setOriginalPlace(data.place);
            setOriginalSubjectivity(data.subjectivity);
            setOriginalSponsor(data.sponsor);
            setOriginalSupport(data.support);
            setOriginalSouvenirPicture(data.souvenirPicture);

            setOriginalStartDate(moment(data.startDate));
            setOriginalStartTime(moment(data.startDate).format('HH:mm'));
            setOriginalEndDate(moment(data.endDate));
            setOriginalEndTime(moment(data.endDate).format('HH:mm'));

            for (let i = 0; i < data.tickets.length; i++) {
              for (let j = 0; j < data.tickets[i].priceOptions.length; j++) {
                data.tickets[i].priceOptions[j].appliedDate = moment(data.tickets[i].priceOptions[j].appliedDate);
              }
            }

            setOriginalTickets(data.tickets);

            setTitle(data.title);
            setMainPicture(data.mainPicture);
            setBannerPicture(data.bannerPicture);
            setContent(data.content);
            setPeriod(data.period);
            setPlace(data.place);
            setSubjectivity(data.subjectivity);
            setSponsor(data.sponsor);
            setSupport(data.support);
            setSouvenirPicture(data.souvenirPicture);

            setStartDate(moment(data.startDate));
            setStartTime(moment(data.startDate).format('HH:mm'));
            setEndDate(moment(data.endDate));
            setEndTime(moment(data.endDate).format('HH:mm'));

            setTickets(data.tickets);
            setIsClickedModify(false);
            setIsLoading(false);
          }
          else {
            alert('저장에 실패했습니다\n잠시 후 다시시도해주세요');
            setIsLoading(false);
            return;
          }
        }
      )();
    }
  }

  // 취소하기 클릭시 //
  function onClickExit() {
    //  console.log("???");
    // if (organizerId !== undefined) {
      setTitle(originalTitle);
      setMainPicture(originalMainPicture);
      setBannerPicture(originalBannerPicture);
      setContent(originalContent);
      setPeriod(originalPeriod);
      setStartDate(originalStartDate);
      setStartTime(originalStartTime);
      setEndDate(originalEndDate);
      setEndTime(originalEndTime);
      setPlace(originalPlace);
      setSubjectivity(originalSubjectivity);
      setSponsor(originalSponsor);
      setSupport(originalSupport);
      setSouvenirPicture(originalSouvenirPicture);
      setTickets(originalTickets);
    // }

    setIsClickedModify(false);
  }

  return (
    <TopLevelWrapper>
      <TopButtonWrapper>
        <ExitButton isClicked={isClickedModify} onClick={onClickExit}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor={isClickedModify ? 'pointer' : `default`}>취소하기</Text>
        </ExitButton>
        <SaveButton isClicked={isClickedModify} onClick={onClickSave}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' cursor='pointer'>{isClickedModify ? `저장하기` : `수정하기`}</Text>
        </SaveButton>
      </TopButtonWrapper>
      <ViewBox>
        <EditViewWrapper ref={TotalRef}>
          {
            isClickedModify ?
              <EditView
                mainPictureSize={mainPictureSize}
                bannerPictureWidth={bannerPictureWidth}
                bannerPictureHeight={bannerPictureHeight}
                commonSouvenirWidth={commonSouvenirWidth}
                commonSouvenirHeight={commonSouvenirHeight}
                plusWidth={plusWidth}
                plusHeight={plusHeight}
                
                title={title}
                setTitle={setTitle}
                mainPicture={mainPicture}
                setMainPicture={setMainPicture}
                bannerPicture={bannerPicture}
                setBannerPicture={setBannerPicture}
                content={content}
                setContent={setContent}
                period={period}
                setPeriod={setPeriod}
                startDate={startDate}
                setStartDate={setStartDate}
                startTime={startTime}
                setStartTime={setStartTime}
                endDate={endDate}
                setEndDate={setEndDate}
                endTime={endTime}
                setEndTime={setEndTime}
                place={place}
                setPlace={setPlace}
                subjectivity={subjectivity}
                setSubjectivity={setSubjectivity}
                sponsor={sponsor}
                setSponsor={setSponsor}
                support={support}
                setSupport={setSupport}
                souvenirPicture={souvenirPicture}
                setSouvenirPicture={setSouvenirPicture}
                tickets={tickets}
                setTickets={setTickets} />
              :
              <SettingView
                mainPictureSize={mainPictureSize}
                bannerPictureWidth={bannerPictureWidth}
                bannerPictureHeight={bannerPictureHeight}
                commonSouvenirWidth={commonSouvenirWidth}
                commonSouvenirHeight={commonSouvenirHeight}
                plusWidth={plusWidth}
                plusHeight={plusHeight}

                title={title}
                setTitle={setTitle}
                mainPicture={mainPicture}
                setMainPicture={setMainPicture}
                bannerPicture={bannerPicture}
                setBannerPicture={setBannerPicture}
                content={content}
                setContent={setContent}
                period={period}
                setPeriod={setPeriod}
                startDate={startDate}
                setStartDate={setStartDate}
                startTime={startTime}
                setStartTime={setStartTime}
                endDate={endDate}
                setEndDate={setEndDate}
                endTime={endTime}
                setEndTime={setEndTime}
                place={place}
                setPlace={setPlace}
                subjectivity={subjectivity}
                setSubjectivity={setSubjectivity}
                sponsor={sponsor}
                setSponsor={setSponsor}
                support={support}
                setSupport={setSupport}
                souvenirPicture={souvenirPicture}
                setSouvenirPicture={setSouvenirPicture}
                tickets={tickets}
                setTickets={setTickets} />
          }
        </EditViewWrapper>
        <UserViewWrapper>
          <UserView
            title={title}
            mainPicture={mainPicture}
            bannerPicture={bannerPicture}
            content={content}
            period={period}
            startDate={startDate}
            startTime={startTime}
            endDate={endDate}
            endTime={endTime}
            place={place}
            subjectivity={subjectivity}
            sponsor={sponsor}
            support={support}
            souvenirPicture={souvenirPicture}
            tickets={tickets} />
        </UserViewWrapper>
      </ViewBox>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 100%;
  // height: calc(100% - 73px);
`;

const TopButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center;

  width: 100%;

  gap: 10px;
`;

const SaveButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 40px;

  border-radius: 8px;
  background-color: ${props => props.isClicked ? `#6DD49E` : `#969696`};

  :hover {
    cursor: pointer;

    background-color: #6DD49E;
  }

  transition: all 0.3s ease-in-out;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 92px;
  height: 40px;

  border-radius: 8px;
  background-color: #969696;

  opacity: ${props => props.isClicked ? 1 : 0};

  :hover {
    cursor: ${props => props.isClicked ? `pointer` : `default`};
  }

  transition: all 0.3s ease-in-out;
`;

const ViewBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
  // height: calc(100% - 133px);
  height: calc(100% - 63px);
  // height: 100%;
`;

const EditViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 61%;
  height: 100%;
  // height: calc(100% - 63px);

  gap: 10px;

  overflow: scroll;

  &::-webkit-scrollbar {
      width: 2px;
      height: 0px;
    }
  
    &::-webkit-scrollbar-thumb {
    	border: 2px solid transparent;
    	background-color: #FFFFFF;
    	border-radius: 2px;
    }
  
    &::-webkit-scrollbar-track {
    	// background-color: #F9F9F9;
    	border-radius: 200px;
      opacity: 0;
    }
`;

const UserViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 37%;
  // height: calc(100% - 63px);
  height: 100%;

  gap: 10px;

  overflow: scroll;

  &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }
  
    &::-webkit-scrollbar-thumb {
    	border: 2px solid transparent;
    	background-color: #FFFFFF;
    	border-radius: 2px;
    }
  
    &::-webkit-scrollbar-track {
    	// background-color: #F9F9F9;
    	border-radius: 200px;
      opacity: 0;
    }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;