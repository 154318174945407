import React, { useState } from "react";
import styled from "styled-components";

import TimeInput from "../Component/TimeInput";

import CalenderIcon from '../../../Image/GrayCalenderIcon.png';
import DateSelectModal from "./DateSelectModal";

export default function DateSelectView(props) {
  const { 
    startDate, 
    setStartDate, 
    startTime,
    setStartTime,
    endDate, 
    setEndDate,
    endTime,
    setEndTime
  } = props;

  const [isClickedModifyDate, setIsClickedModifyDate] = useState(false);

  return (
    <TopLevelWrapper>
      {/* { isClickedModifyDate && <DateSelectModal/> } */}
      { isClickedModifyDate && <DateSelectModal startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} close={() => setIsClickedModifyDate(false)}/> }
      <DateComponentWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>시작일</Text>
        <DateButton onClick={() => setIsClickedModifyDate(true)}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{startDate.format('YYYY.MM.DD')}</Text>
          <CalenderImage src={CalenderIcon}/>
        </DateButton>
        <TimeInput value={startTime} setValue={setStartTime} placeholder='00:00'/>
      </DateComponentWrapper>
      <Text fontFamily='NotoSansKR-Medium' fontSize={20} color='#FFFFFF' cursor='default'>~</Text>
      <DateComponentWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>종료일</Text>
        <DateButton onClick={() => setIsClickedModifyDate(true)}>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{endDate.format('YYYY.MM.DD')}</Text>
          <CalenderImage src={CalenderIcon}/>
        </DateButton>
        <TimeInput value={endTime} setValue={setEndTime} placeholder='00:00'/>
      </DateComponentWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const DateComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  gap: 4px;
`;

const DateButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 131px;
  height: 28px;

  gap: 8px;

  border: 2px dashed #909090;
  border-radius: 8px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const CalenderImage = styled.img`
  width: 18px;
  height: 18px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;