import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import lodash, { compact } from 'lodash';
// import InputBox from '../../Component/InputBox';
// import BloodTypeBox from '../../Component/EventApplicationComponent/BloodTypeBox';

import GenderBox from '../../Application/Component/GenderBox';
import MemberInputBox from '../../Application/Component/MemberInputBox';
import AddressBox from '../../Application/Component/AddressBox';
import LogoFileInput from '../../Application/Component/LogoFileInput';
import TeamNameInputBox from '../../Application/Component/TeamNameInputBox';
import NumberInputBox from '../../Application/Component/NumberInputBox';
import LoadingAnimation from '../../Application/Component/LoadingAnimation';
import FindMemberInputBox from '../../Application/Component/FindMemberInputBox';
import SelectSizeBox from '../../Application/Component/SelectSizeBox';
import FetchModule from '../../../../Model/Network/FetchModule';



export default function ApplicationModifyPage() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [profileSize, setProfileSize] = useState(120);
  const [plusIconSize, setPlusIconSize] = useState(32);
  const [imgBase64, setImgBase64] = useState('');

  const [teamId, setTeamId] = useState(0);
  const [isModify, setIsModify] = useState(params.isModify === 'true' ? true : false);
  const [teamName, setTeamName] = useState('');
  const [merchantUid, setMerchantUid] = useState(undefined);
  const [belong, setBelong] = useState('');
  const [isCompleteTeamProfileAndTeamName, setIsCompleteTeamProfileAndTeamName] = useState(false);
  const [memberId, setMemberId] = useState(0);
  const [souvenirs, setSouvenirs] = useState([]);
  const [teamMemberCount, setTeamMemberCount] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  const [memberList, setMemberList] = useState([{
    name: '',
    email: '',
    birth: '',
    gender: 'female',
    bloodType: '',
    belong: '',
    phone: '',
    zipCode: '',
    address: '',
    addressDetail: '',
    // representative (대표자) or member (팀원) //
    division: 'representative'
  }]);

  const [isTeam, setIsTeam] = useState(false);

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      window.removeEventListener('resize', handleResize);

      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [])

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    let finalSize = windowSize.width * 0.2898;
    let iconSize = windowSize.width * 0.0772;

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('개인정보 입력'), isAvailableGoback: false, getTeamData: true }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }

    setProfileSize(finalSize);
    setPlusIconSize(iconSize);
  }, [windowSize])

  // 받아온 데이터 정리 //
  useEffect(() => {
    setIsLoading(true);

    // let eventData = JSON.parse(window.localStorage.getItem('EventData'));
    let eventId = params.eventId;
    let selectOptionId = params.selectOptionId;

    // let isTeamFight = parseInt(params.eventId) === 24 ? true : false;

    // setIsTeam(true);
    // console.log(eventData);

    let requestData = {
      eventId: eventId,
      selectOptionId: selectOptionId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEventVer2('event/application/', 'POST', requestData);
        // console.log(responseData.data);
        if (responseData.status === 200) {
          if (responseData.data.teamMemberCount > 1) {
            setIsTeam(true);
            setTeamId(responseData.data.teamId);
            setTeamName(responseData.data.teamName);
            setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
            setImgBase64(responseData.data.profile === '' ? undefined : responseData.data.profile);
            setBelong(responseData.data.members[0].belong);

            let newList = responseData.data.members;

            // 팀장 앞으로 빼기 //
            let tmp = undefined;
            let flag = false;

            for (let i = 1; i < newList.length; i++) {
              if (newList[i].division === 'representative') {
                // alert(newList[i].division + '\n' + newList[i].name + '\n' + newList[i].phone);
                tmp = newList[i];
                newList.splice(i, 1);
                flag = true;
                break;
              }
            }

            if (flag) {
              newList.unshift(tmp);
            }

            for (let i = 0; i < newList.length; i++) {
              if (newList[i].bloodType !== '') {
                let souvenirs = lodash.cloneDeep(responseData.data.souvenirs);

                for (let j = 0; j < souvenirs.length; j++) {
                  if (souvenirs[j].isUseOption) {
                    let optionText = newList[i].bloodType;
                    let sizeText = '';

                    if (newList[i].bloodType.includes(' - ')) {
                      optionText = newList[i].bloodType.split(' - ')[0];
                      sizeText = newList[i].bloodType.split(' - ')[1];
                    }

                    for (let h = 0; h < souvenirs[j].options.length; h++) {
                      if (optionText === souvenirs[j].options[h].name) {
                        let option = souvenirs[j].options[h];

                        for (let z = 0; z < souvenirs[j].options[h].sizes.length; z++) {
                          if (sizeText === souvenirs[j].options[h].size[z].name) option.size = souvenirs[j].options[h].size[z];
                        }

                        newList[i].commonSouvenirs = option;
                        break;
                      }
                    }
                  }
                  else {
                    let sizeText = newList[i].bloodType;

                    for (let h = 0; h < souvenirs[j].sizes.length; h++) {
                      if (sizeText === souvenirs[j].sizes[h].name) {
                        souvenirs[j].size = souvenirs[j].sizes[h];
                        newList[i].commonSouvenirs = [];
                        newList[i].commonSouvenirs.push(souvenirs[j]);
                        break;
                      }
                    }
                  }
                }
              }
            }

            // console.log(responseData.data);
            // 이미 저장된 데이터가 팀원수보다 적을경우 팀원칸 늘려주기 //
            if (responseData.data.teamMemberCount > newList.length) {
              for (let i = newList.length; i < responseData.data.teamMemberCount; i++) {
                newList.push({
                  name: '',
                  email: '',
                  birth: '',
                  gender: 'female',
                  bloodType: '',
                  phone: '',
                  zipCode: '',
                  address: '',
                  addressDetail: '',
                  belong: '',
                  // representative (대표자) or member (팀원) //
                  division: 'member',
                  instaId: ''
                })
              }
            }
            // 이미 저장된 데이터가 팀원수보다 많을 경우 뒤에서부터 자르기 //
            else if (responseData.data.teamMemberCount < newList.length) {
              for (let i = newList.length; i > responseData.data.teamMemberCount; i--) {
                // 팀장은 자르는 목록에서 제외 //
                if (newList[i - 1].division !== 'representative') {
                  newList.splice(i - 1, 1);
                }
              }
            }

            // console.log(newList);
            setMemberList(newList);
            setSouvenirs(responseData.data.souvenirs);
            setTeamMemberCount(responseData.data.teamMemberCount);
          }
          else {
            // setBelong(responseData.data.members[0].belong);
            setIsTeam(false);

            let newList = [];

            newList.push(responseData.data);

            if (newList[0].bloodType !== '') {
              let souvenirs = lodash.cloneDeep(responseData.data.souvenirs);

              for (let j = 0; j < souvenirs.length; j++) {
                if (souvenirs[j].isUseOption) {
                  let optionText = newList[0].bloodType;
                  let sizeText = '';

                  if (newList[0].bloodType.includes(' - ')) {
                    optionText = newList[0].bloodType.split(' - ')[0];
                    sizeText = newList[0].bloodType.split(' - ')[1];
                  }

                  for (let h = 0; h < souvenirs[j].options.length; h++) {
                    if (optionText === souvenirs[j].options[h].name) {
                      let option = souvenirs[j].options[h];

                      for (let z = 0; z < souvenirs[j].options[h].sizes.length; z++) {
                        if (sizeText === souvenirs[j].options[h].size[z].name) option.size = souvenirs[j].options[h].size[z];
                      }

                      newList[0].commonSouvenirs = option;
                      break;
                    }
                  }
                }
                else {
                  let sizeText = newList[0].bloodType;

                  for (let h = 0; h < souvenirs[j].sizes.length; h++) {
                    if (sizeText === souvenirs[j].sizes[h].name) {
                      souvenirs[j].size = souvenirs[j].sizes[h];
                      newList[0].commonSouvenirs = [];
                      newList[0].commonSouvenirs.push(souvenirs[j]);
                      break;
                    }
                  }
                }
              }
            }

            setMemberId(responseData.data.memberId);
            setMemberList(newList);
            setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
            setSouvenirs(responseData.data.souvenirs);
            setTeamMemberCount(responseData.data.teamMemberCount);
          }

          setIsLoading(false);
        }
        else setIsLoading(false);
      }
    )();

  }, [params])


  // 팀사진과 이름 다 추가한 경우 //
  useEffect(() => {
    if (teamName !== '' && (imgBase64 !== '' && imgBase64 !== undefined)) setIsCompleteTeamProfileAndTeamName(true);
    else setIsCompleteTeamProfileAndTeamName(false);
    // if (teamName !== '') setIsCompleteTeamProfileAndTeamName(true);
    // else setIsCompleteTeamProfileAndTeamName(false);
    // setIsCompleteTeamProfileAndTeamName(true);
  }, [teamName, imgBase64])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    if (responseData.goBack) navigate(-1);
    else if (responseData.teamData) {
      // setTeamData(responseData.teamData);
      setImgBase64(responseData.teamData.teamLogo);
      setTeamName(responseData.teamData.teamName);
    }
  }

  // 참가자 이름 변경시 //
  function onChangeMemberName(value, index) {
    let newData = memberList.slice();

    newData[index].name = value;
    setMemberList(newData);
  }

  // 참가자 이메일 변경시 //
  function onChangeMemberEmail(value, index) {
    let newData = memberList.slice();

    newData[index].email = value;
    setMemberList(newData);
  }

  // 참가자 연락처 변경시 //
  function onChangeMemberPhone(value, index) {
    let newData = memberList.slice();

    newData[index].phone = value;
    setMemberList(newData);
  }

  // 참가자 소속 변경시 //
  function onChangeMemberBelong(value, index) {
    let newData = memberList.slice();

    newData[index].belong = value;
    setMemberList(newData);
  }

  // 참가자 생년월일 변경시 //
  function onChangeMemberBirth(value, index) {
    let newData = memberList.slice();

    newData[index].birth = value;
    setMemberList(newData);
  }

  // 참가자 성별 변경시 //
  function onChangeMemberGender(value, index) {
    // 정보 변경일때 팀장은 변경할 수 없음 //
    if (isModify && index === 0) return;

    let newData = memberList.slice();

    newData[index].gender = value;
    setMemberList(newData);
  }

  // 참가자 혈액형 변경시 //
  function onChangeMemberBloodType(value, index) {
    let newData = memberList.slice();

    newData[index].bloodType = value;
    setMemberList(newData);
  }

  // 참가자 우편번호 변경시 //
  function onChangeZipCode(value) {
    let newData = memberList.slice();

    newData[0].zipCode = value;
    setMemberList(newData);
  }

  // 참가자 주소 변경시 //
  function onChangeAddress(value) {
    let newData = memberList.slice();

    newData[0].address = value;
    setMemberList(newData);
  }

  // 참가자 상세주소 변경시 //
  function onChangeAddressDetail(value) {
    let newData = memberList.slice();

    newData[0].addressDetail = value;
    setMemberList(newData);
  }

  // 참가자 인스타 아이디 변경시 //
  function onChangeInstagramId(value, index) {
    let newData = memberList.slice();

    newData[index].instaId = value;
    setMemberList(newData);
  }

  // 길드 이름 변경시 //
  function onChangeTeamBelong(value, index) {
    setBelong(value);
  }

  // 참가자 티셔츠 사이즈 변경시 //
  function onChangeMemberTshirtsSize(value, index) {
    let newData = memberList.slice();
    let shirts = newData[index].belong.split('-')[0] === '' ? 'undefined' : newData[index].belong.split('-')[0];

    newData[index].belong = shirts + '-' + value;
    setMemberList(newData);
  }

  // 이전으로 돌아가기 클릭시 //
  function onClickPrevButton() {
    navigate(-1);
  }

  // 공통기념품 선택되었는지 여부 확인 //
  function checkCommonSouvenirOption(index) {

    // if (memberList[index].commonSouvenirs === undefined) return false;
    if (souvenirs.length === 0) return true;
    else if (memberList[index].commonSouvenirs === undefined) return false;
    else {
      let commonSouvenirs = memberList[index].commonSouvenirs;
      let isExist = true;

      for (let i = 0; i < commonSouvenirs.length; i++) {
        if (commonSouvenirs[i].options.length > 0
          && commonSouvenirs[i].option === undefined) {
          isExist = false;
          break;
        }
        else if (commonSouvenirs[i].sizes.length > 0
          && commonSouvenirs[i].size === undefined) {
          isExist = false;
          break;
        }
      }

      return isExist;
    }
  }

  // 다음으로 넘어가기 클릭시 //
  function onClickNextButton() {
    setIsLoading(true);
    // var eventData = JSON.parse(window.localStorage.getItem('EventData'));

    // 팀전일 경우 //
    if (isTeam) {
      if (teamName.length < 2) {
        alert(t('팀 이름은 2글자 이상이어야 합니다'));
        setIsLoading(false);
        return;
      }
      else if (imgBase64 === null || imgBase64 === undefined || imgBase64 === '') {
        alert(t('팀 이미지를 설정해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].phone.length !== 11) {
        alert(t('전화번호를 알맞게 기입해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].address === '') {
        alert(t('주소를 입력해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].addressDetail === '') {
        alert(t('상세주소를 기입해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].belong === '') {
        alert('체육관 이름을 입력해주세요');
        setIsLoading(false);
        return;
      }
      else if (!checkCommonSouvenirOption(0)) {
        alert('기념품 옵션을 선택해주세요!');
        setIsLoading(false);
        return;
      }

      for (let i = 1; i < memberList.length; i++) {
        if (memberList[i].name === '') {
          alert(t('참가자 이름을 알맞게 기재해주세요'));
          setIsLoading(false);
          return;
        }
        else if (memberList[i].birth.length !== 6) {
          alert(t('생년월일(YYMMDD)을 알맞게 기재해주세요'));
          setIsLoading(false);
          return;
        }
        else if (memberList[i].phone.length > 11 || memberList[i].phone.length < 11) {
          alert(t('전화번호를 알맞게 기입해주세요!'));
          setIsLoading(false);
          return;
        }
        // else if (memberList[i].belong === '' || memberList[i].belong === 'None') {
        //   alert('기념품 (티셔츠) 사이즈를 선택해주세요!');
        //   setIsLoading(false);
        //   return;
        // }
        else if (memberList[i].instaId === '') {
          alert('참가자 인스타 아이디를 기입해주세요');
          setIsLoading(false);
          return;
        }
        else if (!checkCommonSouvenirOption(i)) {
          alert('기념품 옵션을 선택해주세요!');
          setIsLoading(false);
          return;
        }
      }

      let members = [];

      for (let i = 0; i < memberList.length; i++) {

        for (let j = 0; j < members.length; j++) {
          if (members[j].phone === memberList[i].phone) {
            alert(t('참가자의 전화번호는 중복될 수 없습니다.\n올바른 전화번호를 적어주세요'));
            setIsLoading(false);
            return;
          }
        }

        if (memberList[i].commonSouvenirs !== undefined) {
          let totalText = '';

          for (let j = 0; j < memberList[i].commonSouvenirs.length; j++) {
            if (memberList[i].commonSouvenirs[j].isUseOption) {
              if (memberList[i].commonSouvenirs[j].option.size === undefined) totalText = memberList[i].commonSouvenirs[j].option.name;
              else totalText = memberList[i].commonSouvenirs[j].option.name + ' - ' + memberList[i].commonSouvenirs[j].option.size.name;
            }
            else {
              if (memberList[i].commonSouvenirs[j].size !== undefined) totalText = memberList[i].commonSouvenirs[j].size.name;
            }
          }

          memberList[i].bloodType = totalText;
        }


        members.push({
          memberId: memberList[i].memberId,
          teamId: teamId,
          eventId: params.eventId,
          name: memberList[i].name,
          email: memberList[i].email === '' ? 'None' : memberList[i].email,
          birth: memberList[i].birth,
          bloodType: memberList[i].bloodType,
          phone: memberList[i].phone,
          zipCode: memberList[i].zipCode === '' ? 'None' : memberList[i].zipCode,
          address: memberList[i].address === '' ? 'None' : memberList[i].address,
          addressDetail: memberList[i].addressDetail === '' ? 'None' : memberList[i].addressDetail,
          division: memberList[i].division,
          gender: memberList[i].gender,
          belong: memberList[i].belong,
          instaId: memberList[i].instaId,
          selectOptionId: memberList[i].selectOptionId
        })
      }

      let teamData = {
        teamId: teamId,
        eventId: params.eventId,
        teamName: teamName,
        profile: imgBase64,
        isAdmin: false,
        members: members,
        merchantUid: merchantUid
      }
      // console.log('-----Team-----');
      // console.log(teamData);
      // setIsLoading(false);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('team/save', 'POST', teamData);
          // console.log("???");
          // console.log(responseData);
          if (responseData.status === 200) {
            // navigate('/check/the_mentality/' + params.eventId);
            navigate('/complete/v2/' + params.eventId);
            // if (isModify) navigate(-1);
            // else navigate('/check/girlfit/' + params.eventId);
          }
          else if (responseData.status === 201) {
            alert('이미 사용중인 팀명입니다!');
            setIsLoading(false);
          }
        }
      )();
    }
    // 개인전인 경우 //
    else {
      if (memberList[0].birth.length !== 6) {
        alert(t('생년월일(YYMMDD)을 알맞게 기재해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].address === '') {
        alert(t('체육관 주소를 기입해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].addressDetail === '') {
        alert(t('상세주소를 기입해주세요'));
        setIsLoading(false);
        return;
      }
      else if (memberList[0].belong === '') {
        alert('체육관 이름을 입력해주세요');
        setIsLoading(false);
        return;
      }
      else if (!checkCommonSouvenirOption(0)) {
        alert('기념품 옵션을 선택해주세요!');
        setIsLoading(false);
        return;
      }


      if (memberList[0].commonSouvenirs !== undefined) {
        let totalText = '';

        for (let j = 0; j < memberList[0].commonSouvenirs.length; j++) {
          if (memberList[0].commonSouvenirs[j].isUseOption) {
            if (memberList[0].commonSouvenirs[j].option.size === undefined) totalText = memberList[0].commonSouvenirs[j].option.name;
            else totalText = memberList[0].commonSouvenirs[j].option.name + ' - ' + memberList[0].commonSouvenirs[j].option.size.name;
          }
          else {
            if (memberList[0].commonSouvenirs[j].size !== undefined) totalText = memberList[0].commonSouvenirs[j].size.name;
          }
        }

        memberList[0].bloodType = totalText;
      }

      let requestData = {
        memberId: memberList[0].memberId,
        teamId: memberId,
        eventId: params.eventId,
        name: memberList[0].name,
        email: memberList[0].email === '' ? 'None' : memberList[0].email,
        birth: memberList[0].birth,
        bloodType: memberList[0].bloodType,
        phone: memberList[0].phone,
        zipCode: memberList[0].zipCode === '' ? 'None' : memberList[0].zipCode,
        address: memberList[0].address === '' ? 'None' : memberList[0].address,
        addressDetail: memberList[0].addressDetail === '' ? 'None' : memberList[0].addressDetail,
        division: memberList[0].division,
        gender: memberList[0].gender,
        belong: memberList[0].belong,
        instaId: memberList[0].instaId,
        merchantUid: merchantUid
      }
      // console.log(requestData);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('member/save', 'POST', requestData);

          if (responseData.status === 200) {
            // navigate('/check/2024_team_of_4/');
            navigate('/complete/v2/' + params.eventId);
            // if (isModify) navigate(-1);
            // else navigate('/check/girlfit/' + params.eventId);
          }
        }
      )();
    }

    setTimeout(() => setIsLoading(false), 1000);
  }

  // 연락처 검색 버튼 클릭시 //
  function onClickFindUserInfo(phone, index) {
    if (phone.length !== 11) {
      alert('전화번호를 알맞게 기입해주세요!');
      return;
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('member/match/' + phone, 'POST');
        // alert(responseData.status);
        // console.log(responseData.data === undefined);
        if (responseData.status === 200) {
          if (responseData.data === undefined) alert('링크코치에 가입된 회원이 아닙니다\n정보를 기입해주세요');
          else {
            let birth = responseData.data.socialNum.split('-')[0];
            let genderNumStr = responseData.data.socialNum.split('-')[1].replaceAll('*');
            let gender = 'male';

            if (genderNumStr === '1' || genderNumStr === '3') gender = 'male';
            else if (genderNumStr === '2' || genderNumStr === '4') gender = 'female';
            let newData = memberList.slice();

            newData[index].birth = birth;
            newData[index].gender = gender;
            newData[index].name = responseData.data.userName;

            setMemberList(newData);
          }
        }
        else {
          alert('링크코치에 가입된 회원이 아닙니다\n정보를 기입해주세요');
        }
      }
    )();
  }

  // // 컴포넌트 크기 계산 //
  // function decideInfoComponentHeight(index) {
  //   if (index === 0) {
  //     if (isCompleteTeamProfileAndTeamName) return 1560;
  //     else return 0;
  //   }
  //   else if (index === 1) {
  //     if (isCompleteTeamProfileAndTeamName
  //       && memberList[0].zipCode !== ''
  //       && memberList[0].address !== ''
  //       && memberList[0].addressDetail !== ''
  //       && memberList[0].bloodType !== ''
  //       && memberList[0].birth !== ''
  //       && memberList[0].belong !== ''
  //       && !memberList[0].belong.includes('undefined')) return 1080;
  //     return 0;
  //   }
  //   else if (index === 2) {
  //     if (isCompleteTeamProfileAndTeamName
  //       && memberList[0].zipCode !== ''
  //       && memberList[0].address !== ''
  //       && memberList[0].addressDetail !== ''
  //       && memberList[0].bloodType !== ''
  //       && memberList[0].birth !== ''
  //       && memberList[0].belong !== ''
  //       && !memberList[0].belong.includes('undefined')
  //       && memberList[1].phone !== ''
  //       && memberList[1].name !== ''
  //       && memberList[1].birth !== ''
  //       && memberList[1].belong !== ''
  //       && !memberList[1].belong.includes('undefined')) return 1080;
  //     // ) return 1080;
  //     else return 0;
  //   }
  //   else if (index === 3) {
  //     if (isCompleteTeamProfileAndTeamName
  //       && memberList[0].zipCode !== ''
  //       && memberList[0].address !== ''
  //       && memberList[0].addressDetail !== ''
  //       && memberList[0].bloodType !== ''
  //       && memberList[0].belong !== ''
  //       && !memberList[0].belong.includes('undefined')
  //       && memberList[1].phone !== ''
  //       && memberList[1].name !== ''
  //       && memberList[1].birth !== ''
  //       // && memberList[1].belong !== ''
  //       // && !memberList[1].belong.includes('undefined')
  //       && memberList[2].phone !== ''
  //       && memberList[2].name !== ''
  //       && memberList[2].birth !== ''
  //       // && memberList[2].belong !== ''
  //       // && !memberList[2].belong.includes('undefined')) return 443;
  //     ) return 443;
  //     else return 0;
  //   }
  //   // else if (index === 4) {
  //   //   if (isCompleteTeamProfileAndTeamName
  //   //     && memberList[0].zipCode !== ''
  //   //     && memberList[0].address !== ''
  //   //     && memberList[0].addressDetail !== ''
  //   //     && memberList[0].belong !== ''
  //   //     && !memberList[0].belong.includes('undefined')
  //   //     && memberList[1].phone !== ''
  //   //     && memberList[1].name !== ''
  //   //     && memberList[1].belong !== ''
  //   //     && !memberList[1].belong.includes('undefined')
  //   //     && memberList[2].phone !== ''
  //   //     && memberList[2].name !== ''
  //   //     && memberList[2].belong !== ''
  //   //     && !memberList[2].belong.includes('undefined')
  //   //     && memberList[3].phone !== ''
  //   //     && memberList[3].name !== ''
  //   //     && memberList[3].belong !== '') return 696;
  //   //   else return 0;
  //   // }
  // }


  // 컴포넌트 크기 계산 //
  function decideInfoComponentHeight(index) {
    return 'auto';
    // if (index === 0) {
    //   if (isCompleteTeamProfileAndTeamName) return 'auto';
    //   else return '0px';
    // }
    // else if (index === 1) {
    //   if (isCompleteTeamProfileAndTeamName
    //     && memberList[0].zipCode !== ''
    //     && memberList[0].address !== ''
    //     && memberList[0].addressDetail !== ''
    //     && memberList[0].bloodType !== ''
    //     && memberList[0].birth !== ''
    //     && checkCommonSouvenirOption(0)) return 'auto';
    //   else return '0px';
    // }
    // else if (index === 2) {
    //   if (isCompleteTeamProfileAndTeamName
    //     && memberList[0].zipCode !== ''
    //     && memberList[0].address !== ''
    //     && memberList[0].addressDetail !== ''
    //     && memberList[0].bloodType !== ''
    //     && memberList[0].birth !== ''
    //     && checkCommonSouvenirOption(0)
    //     && memberList[1].phone !== ''
    //     && memberList[1].name !== ''
    //     && memberList[1].birth !== ''
    //     && checkCommonSouvenirOption(1)) return 'auto';
    //   else return '0px';
    // }
    // else if (index === 3) {
    //   if (isCompleteTeamProfileAndTeamName
    //     && memberList[0].zipCode !== ''
    //     && memberList[0].address !== ''
    //     && memberList[0].addressDetail !== ''
    //     && memberList[0].bloodType !== ''
    //     && checkCommonSouvenirOption(0)
    //     && memberList[1].phone !== ''
    //     && memberList[1].name !== ''
    //     && memberList[1].birth !== ''
    //     && checkCommonSouvenirOption(1)
    //     && memberList[2].phone !== ''
    //     && memberList[2].name !== ''
    //     && memberList[2].birth !== ''
    //     && checkCommonSouvenirOption(2)) return 'auto';
    //   else return '0px';
    // }
    // else if (index === 4) {
    //   if (isCompleteTeamProfileAndTeamName
    //     && memberList[0].zipCode !== ''
    //     && memberList[0].address !== ''
    //     && memberList[0].addressDetail !== ''
    //     && checkCommonSouvenirOption(0)
    //     && memberList[1].phone !== ''
    //     && memberList[1].name !== ''
    //     && checkCommonSouvenirOption(1)
    //     && memberList[2].phone !== ''
    //     && memberList[2].name !== ''
    //     && checkCommonSouvenirOption(2)
    //     && memberList[3].phone !== ''
    //     && memberList[3].name !== ''
    //     && checkCommonSouvenirOption(3)) return 'auto';
    //   else return '0px';
    // }
  }

  // 다음 버튼 생성 여부 결정 //
  function decideNextButton() {
    // if (isCompleteTeamProfileAndTeamName
    //   && memberList[0].zipCode !== ''
    //   && memberList[0].address !== ''
    //   && memberList[0].addressDetail !== ''
    //   && memberList[0].birth !== ''
    //   && memberList[0].bloodType !== ''
    //   && checkCommonSouvenirOption(0)
    //   && memberList[1].phone !== ''
    //   && memberList[1].name !== ''
    //   && memberList[1].birth !== ''
    //   && checkCommonSouvenirOption(1)
    //   && memberList[2].phone !== ''
    //   && memberList[2].name !== ''
    //   && memberList[2].birth !== ''
    //   && checkCommonSouvenirOption(2)
    //   && memberList[3].phone !== ''
    //   && memberList[3].name !== ''
    //   && memberList[3].birth !== ''
    //   && checkCommonSouvenirOption(3)
    // ) return true;
    // else return false;
    return true;
  }

  // 티셔츠 종류 데이터 변경 //
  function onChangeColor(souvenirData, color, index) {
    let newData = memberList.slice();

    if (newData[index].commonSouvenirs === undefined) {
      let newSouvenir = lodash.cloneDeep(souvenirData);

      newSouvenir.color = color;

      newData[index].commonSouvenirs = [];
      newData[index].commonSouvenirs.push(newSouvenir);
    }
    else {
      let isExist = false;

      for (let i = 0; i < newData[index].commonSouvenirs.length; i++) {
        if (newData[index].commonSouvenirs[i].souvenirId === color.souvenirId) {
          newData[index].commonSouvenirs[i].color = color;
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        let newSouvenir = lodash.cloneDeep(souvenirData);

        newSouvenir.color = color;

        newData[index].commonSouvenirs = [];
        newData[index].commonSouvenirs.push(newSouvenir);
      }
    }

    setMemberList(newData);
  }

  // 티셔츠 종류 데이터 변경 //
  function onChangeSize(souvenirData, size, index) {
    let newData = memberList.slice();

    if (newData[index].commonSouvenirs === undefined) {
      let newSouvenir = lodash.cloneDeep(souvenirData);

      newSouvenir.size = size;

      newData[index].commonSouvenirs = [];
      newData[index].commonSouvenirs.push(newSouvenir);
    }
    else {
      let isExist = false;

      for (let i = 0; i < newData[index].commonSouvenirs.length; i++) {
        if (newData[index].commonSouvenirs[i].souvenirId === size.souvenirId) {
          newData[index].commonSouvenirs[i].size = size;
          isExist = true;
          break;
        }
      }

      if (!isExist) {
        let newSouvenir = lodash.cloneDeep(souvenirData);

        newSouvenir.size = size;

        newData[index].commonSouvenirs = [];
        newData[index].commonSouvenirs.push(newSouvenir);
      }
    }

    setMemberList(newData);
  }

  return (
    <TopLevelWrapper>
      {isLoading && <LoadingAnimation />}
      {
        isTeam &&
        <ComponentWrapper>
          <TitleWrapper isView={true} style={{ marginTop: `24px` }}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('팀 이름과 팀 프로필 사진을 정해주세요')}</Text>
          </TitleWrapper>
          <TeamInfoWrapper>
            <ProfileBoxWrapper>
              <LogoFileInput
                imgBase64={imgBase64}
                setImgBase64={setImgBase64}
                size={profileSize}
                plusIconSize={plusIconSize} />
            </ProfileBoxWrapper>
            <TeamNameWrapper>
              <InputBoxWrapper>
                <TeamNameInputBox value={teamName} setValue={setTeamName} placeholder={t('7글자를 초과할 수 없습니다.')} />
              </InputBoxWrapper>
            </TeamNameWrapper>
          </TeamInfoWrapper>
        </ComponentWrapper>
      }
      <TitleWrapper isView={isCompleteTeamProfileAndTeamName}>
        <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('개인정보를 입력해 주세요')}</Text>
      </TitleWrapper>
      {
        memberList.map((data, index) => (
          <ComponentWrapper key={index} height={decideInfoComponentHeight(index)}>
            <SectionWrapper style={index === 0 ? { marginTop: `12px` } : { marginTop: `0px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('연락처')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.phone}</Text>
                  </InputViewBox>
                  :
                  index === 0 ?
                    <InputBoxWrapper>
                      <TeamLeaderView>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.phone}</Text>
                      </TeamLeaderView>
                    </InputBoxWrapper>
                    :
                    <InputBoxWrapper>
                      <FindMemberInputBox value={data.phone} setValue={onChangeMemberPhone} placeholder={t('연락처을 작성해주세요')} index={index} onClickFindUserInfo={onClickFindUserInfo} />
                    </InputBoxWrapper>
              }
            </SectionWrapper>
            <SectionWrapper style={{ marginTop: `18px` }} >
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : (t('팀원') + String.fromCharCode(index + 64) + ' ') : (t('참가자') + ' ')}{t('이름')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.name}</Text>
                  </InputViewBox>
                  :
                  index === 0 ?
                    <InputBoxWrapper>
                      <TeamLeaderView>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.name}</Text>
                      </TeamLeaderView>
                    </InputBoxWrapper>
                    :
                    <InputBoxWrapper>
                      <MemberInputBox value={data.name} setValue={onChangeMemberName} placeholder={t('이름을 기재해주세요')} index={index} />
                    </InputBoxWrapper>
              }
              <ExplanationWrapper>
                <Text style={{ marginRight: `3px` }} fontFamily='NotoSansKR-Medium' fontSize={12} color='#4F4F4F'>{t('실명이 아닐 경우')}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#FF3131'>{t('대회 참가에 불이익')}</Text>
                <Text fontFamily='NotoSansKR-Medium' fontSize={12} color='#4F4F4F'>{t('이 있을 수 있습니다.')}</Text>
              </ExplanationWrapper>
            </SectionWrapper>
            {
              index === 0 &&
              <SectionWrapper style={{ marginTop: `18px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자') + ' '}{t('이메일')}</Text>
                {
                  (isModify && index === 0) ?
                    <InputViewBox>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.email}</Text>
                    </InputViewBox>
                    :
                    index === 0 ?
                      <InputBoxWrapper>
                        <TeamLeaderView>
                          <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.email}</Text>
                        </TeamLeaderView>
                      </InputBoxWrapper>
                      :
                      <InputBoxWrapper>
                        <MemberInputBox value={data.email} setValue={onChangeMemberEmail} placeholder={t('이메일을 작성해주세요')} index={index} />
                      </InputBoxWrapper>
                }
              </SectionWrapper>
            }
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('생년월일')}</Text>
              {
                (isModify && index === 0) ?
                  <InputViewBox>
                    <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#000000'>{data.birth}</Text>
                  </InputViewBox>
                  :
                  <InputBoxWrapper>
                    <NumberInputBox value={data.birth} setValue={onChangeMemberBirth} placeholder={t('생년월일을 작성해주세요')} index={index} />
                  </InputBoxWrapper>
              }
            </SectionWrapper>
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('성별')}</Text>
              <InputBoxWrapper>
                <GenderBox value={data.gender} setValue={onChangeMemberGender} index={index} />
              </InputBoxWrapper>
            </SectionWrapper>
            {
              souvenirs.map((souvenirData, souvenirIndex) => (
                <SouvenirSectionWrapper key={'Souvenir-' + souvenirIndex}>
                  <SectionWrapper style={{ marginTop: `18px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{`공통 기념품`}</Text>
                    <SelectSizeBox
                      souvenirData={souvenirData}
                      value={data}
                      setSize={onChangeSize}
                      setColor={onChangeColor}
                      index={index} />
                  </SectionWrapper>
                  <TShirtsSize src={souvenirData.souvenirPicture} />
                </SouvenirSectionWrapper>
              ))
            }
            {
              index === 0 &&
              <SectionWrapper style={{ marginTop: `18px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('체육관 주소')}</Text>
                <InputBoxWrapper>
                  <AddressBox
                    windowSize={windowSize}
                    zipCode={data.zipCode}
                    onChangeZipCode={onChangeZipCode}
                    address={data.address}
                    onChangeAddress={onChangeAddress}
                    addressDetail={data.addressDetail}
                    onChangeAddressDetail={onChangeAddressDetail} />
                </InputBoxWrapper>
              </SectionWrapper>
            }
            {
              index === 0 &&
              <SectionWrapper style={{ marginTop: `18px` }}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('체육관 이름')}</Text>
                {
                  // <InputBoxWrapper>
                  //   <MemberInputBox value={data.bloodType} setValue={onChangeMemberBloodType} placeholder='체육관 이름을 입력해주세요' index={index} />
                  // </InputBoxWrapper>
                  <InputBoxWrapper>
                    <MemberInputBox value={data.belong} setValue={onChangeMemberBelong} placeholder={t('체육관 이름을 입력해주세요')} index={index} />
                  </InputBoxWrapper>
                }
              </SectionWrapper>
            }
            <SectionWrapper style={{ marginTop: `18px` }}>
              <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : (t('참가자') + ' ')}{t('인스타그램 아이디')}</Text>
              {
                <InputBoxWrapper>
                  <MemberInputBox value={data.instaId} setValue={onChangeInstagramId} placeholder={t('인스타그램 아이디를 입력해주세요')} index={index} />
                </InputBoxWrapper>
              }
            </SectionWrapper>
            <DivisionLine />
          </ComponentWrapper>
        ))
      }
      {
        decideNextButton() &&
        <NextButtonWrapper>
          <NewButton onClick={onClickNextButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('저장')}</Text>
          </NewButton>
        </NextButtonWrapper>
      }
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // justify-content: flex-start;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  // height: 100%;
  min-height: 100vh;

  background-color: #F9F9F9;
  // background-color: red;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  // height: ${props => props.height}px;
  height: ${props => props.height};

  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // margin-top: 8px;
  margin-top: 24px;

  width: 90.33%;

  opacity: ${props => props.isView ? 1 : 0};

  transition: all 0.3s ease-in-out;
`;

const TeamInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 24px;

  width: 90.33%;
`;

const ProfileBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin-top: 8px;
  border-radius: 8px;
  background-color: #D9D9D9;

  :hover {
    cursor: pointer;
  }
`;

const TeamNameWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 62.29%;
`;

const SouvenirSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InputBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  margin-top: 8px;
`;

const FemaleFixedBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: calc(100% - 2px);
  height: 48px;

  border: 1px solid #DFDFDF;
  border-radius: 8px;
  background-color: #F9F9F9;
`;

const TeamLeaderView = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

	width: calc(100% - 24px);
	// height: 40px;
	height: 48px;

	padding: 0 12px 0 12px;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  // background-color: #FFFFFF;
  background-color: #F9F9F9;
`;

const BelongExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 87.96%;
  width: 100%;
`;

const InputViewBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;

  margin-top: 8px;
  
	width: calc(100% - 24px);
	// height: 40px;
	height: 48px;

	padding: 0 12px 0 12px;

	border: 1px solid #E8E8E8;
	border-radius: 8px;
  // background-color: #E0E0E0;
  background-color: #F9F9F9;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  flex-wrap: wrap;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  margin-top: 8px;
`;

const NextButtonWrapper = styled.div`
  // position: fixed;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  // padding: 0px 0px 34px 0px;
  // padding-bottom: 15px;
  margin: 52px 0px 0px 0px;

  width: 100%;
  height: 68px;

  background-color: #FFFFFF;
`;

const NextInnerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 60px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const TShirtsSize = styled.img`
  margin-top: 8px;

  width: 100%;

  object-fit: contain;
`;

const DivisionLine = styled.div`
  width: 90.33%;
  height: 1px;

  // margin: 36px 0px 36px 0px;
  margin: 36px 0px 24px 0px;

  background-color: #E0E0E0;
`;

const NewButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #6DD49E;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;