import React, { useState } from "react";
import styled from "styled-components";

import OptionIcon from '../../../Image/OptionIcon.png';

export default function EventSalesBox(props) {
  const { event } = props;

  // const [isHover, setIsHover] = useState(false);

  return (
    <TotalSalesWrapper>
        <SalesColumnWrapper>
          <SalesTextWrapper style={{ marginLeft: '20px' }} width={172}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>신청</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={102}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>선택 옵션</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={122}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>신청 일자</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>결제 금액</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={172}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>카드</Text>
          </SalesTextWrapper>
        </SalesColumnWrapper>

        <SalesInfoBox /*onMouseOver={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}*/>
          <SalesTextWrapper width={172}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{event.eventTitle}</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={102}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{event.eventSelectOptionName === '' ? '없음' : event.eventSelectOptionName}</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={122}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{event.approvalDate}</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={82}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{event.eventPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
          </SalesTextWrapper>
          <SalesTextWrapper width={472}>
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='pointer'>{event.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
          </SalesTextWrapper>
          {/* <OptionBox>
            <OptionView src={OptionIcon}/>
          </OptionBox> */}
        </SalesInfoBox>
      </TotalSalesWrapper>
  )
}

const TotalSalesWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 24px;
`;

const SalesColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const SalesInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 20px;

  width: calc(100% - 40px);
  height: 52px;

  gap: 8px;

  border-radius: 30px;
  background-color: #2F2F32;

  :hover {
    cursor: pointer;
  }
`;

const SalesTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: 21px;

  overflow: hidden;
`;

const OptionBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;

  // opacity: ${props => props.isHover ? 1 : 0};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const OptionView = styled.img`
  width: 21px;
  height: 18px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;