import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import FetchModule from '../../Model/Network/FetchModule';

import NoneProfile from '../../Image/NoneProfile.png';
import CancellationModal from '../../Model/EventApplicationModel/CancellationModal';
import LoadingAnimation from '../../Model/EventApplicationModel/LoadingAnimation';

import { useTranslation } from 'react-i18next';
import PaymentSelectModal from '../../Model/EventApplicationModel/PaymentSelectModal';

export default function FitForceTeamLeague2025ApplicationCheckPage() {

  const { t } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();

  const [isTeam, setIsTeam] = useState(false);
  const [isModify, setIsModify] = useState(params.isModify === 'true' ? true : false);
  const [teamData, setTeamData] = useState({
    teamName: '',
    teamLogo: '',
  });
  const [eventData, setEventData] = useState({
    title: '',
    selectedEvent: {
      eventTitle: ''
    },
    commonSouvenir: {
      title: ''
    },
    additionalSouvenirList: [],
    totalPrice: 0
  });
  const [tournamentTitle, setTournamentTitle] = useState('');
  const [eventTitle, setEventTitle] = useState('');
  const [eventMemberData, setEventMemberData] = useState([]);
  const [memberNames, setMemberNames] = useState('');
  const [merchantUid, setMerchantUid] = useState(undefined);
  const [openLink, setOpenLink] = useState(undefined);
  const [check, setCheck] = useState(false);
  const [eventId, setEventId] = useState(params.eventId);
  const [teamId, setTimeId] = useState(undefined);
  const [memberId, setMemberId] = useState(undefined);
  const [myPhone, setMyPhone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isClickedCancellation, setIsClickedCancellation] = useState(false);
  // const [sales, setSales] = useState({ totalPrice: 0 });
  const [commonSouvenir, setCommonSouvenir] = useState(undefined);
  const [additionalSouvenirs, setAdditionalSouvenirs] = useState([]);

  const [sales, setSales] = useState({totalPrice: 0, salesItems: []});

  const [isSelectPaymentMethod, setIsSelectPaymentMethod] = useState(false);

  // FET 가격 //
  const [amount, setAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [orderName, setOrderName] = useState('');

  // 시작시 스크롤 맨 위로 //
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Message Detect Save And Delete //
  useEffect(() => {
    // Android //
    document.addEventListener('message', handleMassage);

    // IOS //
    window.addEventListener('message', handleMassage);

    return () => {
      // Android //
      document.removeEventListener('message', handleMassage);

      // IOS //
      window.removeEventListener('message', handleMassage);
    }
  }, [handleMassage])

  // 페이지 이름 변경내역 업로드 및 받아온 데이터 정리 //
  // useEffect(() => {
  //   setIsLoading(true);

  //   // let eventData = JSON.parse(window.localStorage.getItem('EventData'));

  //   try {
  //     window.ReactNativeWebView.postMessage(JSON.stringify({ pageName: t('신청정보 확인'), isAvailableGoback: false }));
  //   }
  //   catch (e) {
  //     console.log("Web Message Post Error : " + e);
  //   }

  //   // let isTeamFight = parseInt(params.eventId) === 24 ? true : false;
  //   let isTeamFight = true;

  //   setIsTeam(isTeamFight);

  //   let requestData = {
  //     eventId: params.eventId
  //   }

  //   if (isTeamFight) {
  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataOfEvent('team/', 'POST', requestData);
  //         // console.log(responseData);
  //         // console.log(responseData.data);
  //         if (responseData.status === 200) {
  //           var teamData = {
  //             teamName: responseData.data.teamName,
  //             teamLogo: responseData.data.profile === '' ? undefined : responseData.data.profile
  //           }

  //           setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
  //           setMyPhone(responseData.data.myPhone);
  //           setTimeId(responseData.data.teamId);
  //           setTournamentTitle(responseData.data.tournamentTitle);
  //           setEventTitle(responseData.data.eventTitle);
  //           setTeamData(teamData);
  //           setSales(responseData.data.sales);

  //           // 기념품 없으니 패스 //
  //           if (responseData.data.sales !== null && responseData.data.sales !== undefined) {
  //             var newSales = responseData.data.sales;
  //             var newCommonSouvenirs = undefined;
  //             var newAdditionalSouvenirs = [];

  //             for (var i = 0; i < newSales.salesDetails.length; i++) {
  //               // 구매내역이 공통 기념품일 경우 //
  //               if (newSales.salesDetails[i].commonSouvenirId !== 0) {
  //                 newCommonSouvenirs = {
  //                   title: newSales.salesDetails[i].itemName,
  //                   count: newSales.salesDetails[i].count
  //                 }
  //               }
  //               // 구매내역이 추가 기념품일 경우 //
  //               else if (newSales.salesDetails[i].additionalSouvenirId !== 0) {
  //                 var newSouvenir = {
  //                   title: newSales.salesDetails[i].itemName,
  //                   count: newSales.salesDetails[i].count
  //                 }

  //                 newAdditionalSouvenirs.push(newSouvenir);
  //               }
  //             }

  //             setCommonSouvenir(newCommonSouvenirs);
  //             setAdditionalSouvenirs(newAdditionalSouvenirs);
  //           }
  //           else {
  //             var newCommonSouvenirs = undefined;
  //             var newAdditionalSouvenirs = [];

  //             if (eventData.commonSouvenir !== undefined) {
  //               newCommonSouvenirs = {
  //                 title: eventData.commonSouvenir.title,
  //                 count: eventData.commonSouvenir.count
  //               }
  //             }

  //             if (eventData.additionalSouvenirList.length !== 0) {
  //               for (i = 0; i < eventData.additionalSouvenirList.length; i++) {
  //                 var newSouvenir = {
  //                   title: eventData.additionalSouvenirList[i].title + ' ' + eventData.additionalSouvenirList[i].gender + ' ' + eventData.additionalSouvenirList[i].size,
  //                   count: eventData.additionalSouvenirList[i].count
  //                 }

  //                 newAdditionalSouvenirs.push(newSouvenir);
  //               }
  //             }

  //             setCommonSouvenir(newCommonSouvenirs);
  //             setAdditionalSouvenirs(newAdditionalSouvenirs);
  //           }

  //           let newList = responseData.data.members;
  //           let tmp = undefined;
  //           let flag = false;

  //           for (let i = 1; i < newList.length; i++) {
  //             if (newList[i].division === 'representative') {
  //               tmp = newList[i];
  //               newList.splice(i, 1);
  //               flag = true;
  //               break;
  //             }
  //           }

  //           if (flag) {
  //             newList.unshift(tmp);
  //           }

  //           let memberNames = '';

  //           for (let i = 0; i < newList.length; i++) {
  //             if (i === newList.length - 1) memberNames += newList[i].name;
  //             else memberNames += newList[i].name + ', ';
  //           }

  //           setMemberNames(memberNames);
  //           setEventMemberData(newList);
  //         }
  //       }
  //     )();
  //   }
  //   else {
  //     const fetchModule = new FetchModule();
  //     (
  //       async () => {
  //         const responseData = await fetchModule.postDataOfEvent('member/', 'POST', requestData);
  //         // console.log(responseData.data);
  //         if (responseData.status === 200) {

  //           var newList = [];//responseData.data;

  //           newList.push(responseData.data);
  //           setEventTitle(responseData.data.eventTitle);
  //           setMemberId(responseData.data.memberId);

  //           setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
  //           setMyPhone(responseData.data.myPhone);
  //           setSales(responseData.data.sales);

  //           setEventMemberData(newList);
  //           setMemberNames(responseData.data.name);
  //         }
  //       }
  //     )();
  //   }

  //   setEventData(eventData);
  //   setIsLoading(false);
  // }, [params]);
   // 받아온 데이터 정리 //
   useEffect(() => {
    setIsLoading(true);

    // let eventData = JSON.parse(window.localStorage.getItem('EventData'));
    let eventId = params.eventId;
    let selectOptionId = params.selectOptionId;

    // let isTeamFight = parseInt(params.eventId) === 24 ? true : false;

    setIsTeam(true);
    // console.log(eventData);

    let requestData = {
      eventId: eventId,
      selectOptionId: selectOptionId
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEventVer2('event/application/', 'POST', requestData);
        console.log(responseData.data);
        if (responseData.status === 200) {

          let teamData = {
            teamName: responseData.data.teamName,
            teamLogo: responseData.data.profile === '' ? undefined : responseData.data.profile
          }

          setEventTitle(responseData.data.eventTitle);
          setTeamData(teamData);
          // setTeamId(responseData.data.teamId);
          // setTeamName(responseData.data.teamName);
          setMerchantUid(responseData.data.merchantUid === '' ? undefined : responseData.data.merchantUid);
          // setImgBase64(responseData.data.profile === '' ? undefined : responseData.data.profile);
          // setBelong(responseData.data.members[0].belong);

          let newList = responseData.data.members;

          // 팀장 앞으로 빼기 //
          let tmp = undefined;
          let flag = false;

          for (let i = 1; i < newList.length; i++) {
            if (newList[i].division === 'representative') {
              // alert(newList[i].division + '\n' + newList[i].name + '\n' + newList[i].phone);
              tmp = newList[i];
              newList.splice(i, 1);
              flag = true;
              break;
            }
          }

          if (flag) {
            newList.unshift(tmp);
          }
          // console.log(responseData.data);
          // 이미 저장된 데이터가 팀원수보다 적을경우 팀원칸 늘려주기 //
          if (responseData.data.teamMemberCount > newList.length) {
            for (let i = newList.length; i < responseData.data.teamMemberCount; i++) {
              newList.push({
                name: '',
                email: '',
                birth: '',
                gender: 'female',
                bloodType: '',
                phone: '',
                zipCode: '',
                address: '',
                addressDetail: '',
                belong: '',
                // representative (대표자) or member (팀원) //
                division: 'member'
              })
            }
          }
          // 이미 저장된 데이터가 팀원수보다 많을 경우 뒤에서부터 자르기 //
          else if (responseData.data.teamMemberCount < newList.length) {
            for (let i = newList.length; i > responseData.data.teamMemberCount; i--) {
              // 팀장은 자르는 목록에서 제외 //
              if (newList[i - 1].division !== 'representative') {
                newList.splice(i - 1, 1);
              }
            }
          }
          
          setEventMemberData(newList);
          // setSouvenirs(responseData.data.souvenirs);
          // setTeamMemberCount(responseData.data.teamMemberCount);
          setSales(responseData.data.sales);
          setIsLoading(false);
        }
        else setIsLoading(false);
      }
    )();
  }, [params])

  // useEffect(() => {
  //   if (merchantUid === undefined || openLink === undefined) return;

  //   var requestData = {
  //     merchantUid: merchantUid
  //   }

  //   const fetchModule = new FetchModule();
  //   (
  //     async () => {
  //       const responseData = await fetchModule.postDataOfEvent('sales/check', 'POST', requestData);
  //       alert(responseData.status);
  //       alert(responseData.error);
  //       alert(responseData.data);
  //       alert(merchantUid);
  //       if (responseData.status === 200) {
  //         if (responseData.data) {
  //           try {
  //             window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
  //           }
  //           catch (e) {
  //             console.log("Web Message Post Error : " + e);
  //           }
  //         }
  //         else {
  //           alert('결제가 완료되지 않았습니다.\n잠시후 다시시도해주세요');
  //         }
  //       }
  //       else {
  //         alert('결제가 완료되지 않았습니다.\n잠시후 다시시도해주세요');
  //       }
  //     }
  //   )();

  //   setTimeout(() => setIsLoading(false), 1000);

  // }, [merchantUid, openLink, check])

  // React Native에서 웹으로 보내는 데이터 handler //
  function handleMassage(e) {
    let responseData = JSON.parse(e.data);

    // alert(e.data);
    if (responseData.goBack) navigate(-1);
    else if (responseData.focusOn) {
      // setCheck(!check);
      // alert(responseData.focusOn);

      let requestData = {
        merchantUid: merchantUid
      }

      // alert(requestData.merchantUid);
      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfEvent('sales/check', 'POST', requestData);
          // alert(responseData.status);
          // alert(responseData.error);
          // alert(responseData.data);
          // alert(merchantUid);
          
          if (responseData.status === 200) {
            if (responseData.data) navigate('/complete/fitforceteamleague2025');
            else alert(t('결제가 완료되지 않았습니다.\n잠시후 다시시도해주세요'));
            // if (responseData.data) {
            //   // navigate('/complete/fitforceteamleague/' + eventId + '/' + teamData.teamName.replace('?', '') + '/' + memberNames + '/true');
            //   navigate('/complete/firforceteamleague2025/' + params.eventId);
            //   // try {
            //   //   window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
            //   // }
            //   // catch (e) {
            //   //   console.log("Web Message Post Error : " + e);
            //   // }
            // }
            // else {
            //   alert(t('결제가 완료되지 않았습니다.\n잠시후 다시시도해주세요'));
            // }
          }
          else {
            alert(t('결제가 완료되지 않았습니다.\n잠시후 다시시도해주세요'));
          }
        }
      )();
      setTimeout(() => setIsLoading(false), 1000);
    }
    else if (responseData.teamData) {
      // alert(responseData.teamData);
      setTeamData(responseData.teamData);
    }
  }

  // 총 계산 금액 결정 //
  function decideFinalPayment() {
    // if (parseInt(params.eventId) === 36) return '220,000';
    // else return '70,000';
    return sales.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // 이전 페이지로 이동 클릭시 //
  function onClickPrevButton() {
    navigate(-1);
  }
// // 다음 페이지로 이동 클릭시 //
// function onClickNextButton() {

//   // if (moment('2023-08-14T11:59:59').isAfter(moment())) {
//   //   alert('참가신청 가능 시간이 아닙니다.');
//   //   return;
//   // }

//   // setIsLoading(true);
//   // let eventData = JSON.parse(window.localStorage.getItem('EventData'));
//   // let totalPrice = 0;
//   let details = [];
//   // let itemName = (eventTitle + ((isSoldOut && !isReservePersonnelSoldOut) ? ' [대기 인원]' : '') + ' (' + eventMemberData.length + t('인') + ')');
//   let itemName = '2025 얼번핏 인비테이셔널';
//   let eventId = parseInt(params.eventId);

//   if (eventId === 58) itemName += ' - Elite';
//   else if (eventId === 59) itemName += ' - Rxd';
//   else if (eventId === 60) itemName += ' - Scaled';

//   // 참가비 내역 추가 //
//   details.push({
//     merchantUid: '',
//     eventId: eventId,
//     commonSouvenirId: 0,
//     additionalSouvenirId: 0,
//     // itemName: '참가비',
//     itemName: itemName,
//     price: (80000),
//     count: 1,
//     totalPrice: (80000)
//   })

//   // totalPrice += (120000);

//   if (eventData.additionalSouvenirList !== undefined) {
//     for (let i = 0; i < eventData.additionalSouvenirList.length; i++) {
//       console.log(eventData.additionalSouvenirList[i])
//       // 참가비 내역 추가 //
//       details.push({
//         merchantUid: '',
//         eventId: 0,
//         commonSouvenirId: 0,
//         additionalSouvenirId: eventData.additionalSouvenirList[i].id,
//         // itemName: '참가비',
//         itemName: eventData.additionalSouvenirList[i].title + ' ' + eventData.additionalSouvenirList[i].gender + ' ' + eventData.additionalSouvenirList[i].size,
//         price: eventData.additionalSouvenirList[i].price,
//         count: 1,
//         totalPrice: eventData.additionalSouvenirList[i].price
//       })
//     }
//   }
//   // console.log(eventData);
//   let requestData = {
//     merchantUid: '',
//     merchantKey: '',
//     userId: '',
//     teamId: teamId,
//     memberId: memberId,
//     organizerId: 31,
//     // totalPrice: parseInt(params.eventId) === 36 ? 220000 : 70000,
//     // totalPrice: totalPrice,
//     totalPrice: eventData.totalPrice,
//     // totalPrice: 150,
//     status: 0,
//     approvalDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
//     salesDetails: details
//   }
//   // console.log(requestData);
//   const fetchModule = new FetchModule();
//   (
//     async () => {
//       const responseData = await fetchModule.postDataOfEvent('sales/merchant/', 'POST', requestData);
//       // console.log(responseData);
//       if (responseData.status === 200) {
//         setMerchantUid(responseData.data);
        
//         window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/request/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

//         // console.log('http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/' + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', ''))
        
//         // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 201) {
//         alert(t('이미 신청내역이 존재합니다.'));
//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 202) {
//         alert(t('올바르지 않은 접근입니다'));
//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 203) {
//         alert('참가 신청 가능인원이 초과되었습니다.');
//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 204) {
//         alert(t('신청 에러입니다. 잠시후 다시 시도해주세요'));
//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 205) {
//         alert('팀장만 결제를 진행할 수 있습니다.');
//         setTimeout(() => setIsLoading(false), 1000);
//       }
//       else if (responseData.status === 206) {
//         setMerchantUid(responseData.data);
//         window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/admin'}));
//         // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.7:3001/payment/admin'}));
//         // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

//         setTimeout(() => setIsLoading(false), 1000);
//       }
//     }
//   )();
// }
// 다음 페이지로 이동 클릭시 //
function onClickNextButton() {
  let itemName = "FIT FORCE TEAM LEAGUE 2025"
  // setMerchantUid(responseData.data);
        
  window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/request/' + itemName + '/'  +  merchantUid + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));
// 
  // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.52:3000/payment/request/' + itemName + '/'  +  merchantUid + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

  // let details = [];

  // let itemName = (eventTitle + ((isSoldOut && !isReservePersonnelSoldOut) ? ' [대기 인원]' : '') + ' (' + eventMemberData.length + t('인') + ')');
  // let itemName = '2025 얼번핏 인비테이셔널';
  // let eventId = parseInt(params.eventId);

  // if (eventId === 58) itemName += ' - Elite';
  // else if (eventId === 59) itemName += ' - Rxd';
  // else if (eventId === 60) itemName += ' - Scaled';

  // // 참가비 내역 추가 //
  // details.push({
  //   merchantUid: '',
  //   eventId: eventId,
  //   commonSouvenirId: 0,
  //   additionalSouvenirId: 0,
  //   // itemName: '참가비',
  //   itemName: itemName,
  //   price: (80000),
  //   count: 1,
  //   totalPrice: (80000)
  // })

  // // totalPrice += (120000);

  // if (eventData.additionalSouvenirList !== undefined) {
  //   for (let i = 0; i < eventData.additionalSouvenirList.length; i++) {
  //     console.log(eventData.additionalSouvenirList[i])
  //     // 참가비 내역 추가 //
  //     details.push({
  //       merchantUid: '',
  //       eventId: 0,
  //       commonSouvenirId: 0,
  //       additionalSouvenirId: eventData.additionalSouvenirList[i].id,
  //       // itemName: '참가비',
  //       itemName: eventData.additionalSouvenirList[i].title + ' ' + eventData.additionalSouvenirList[i].gender + ' ' + eventData.additionalSouvenirList[i].size,
  //       price: eventData.additionalSouvenirList[i].price,
  //       count: 1,
  //       totalPrice: eventData.additionalSouvenirList[i].price
  //     })
  //   }
  // }
  // // console.log(eventData);
  // let requestData = {
  //   merchantUid: '',
  //   merchantKey: '',
  //   userId: '',
  //   teamId: teamId,
  //   memberId: memberId,
  //   organizerId: 31,
  //   // totalPrice: parseInt(params.eventId) === 36 ? 220000 : 70000,
  //   // totalPrice: totalPrice,
  //   totalPrice: eventData.totalPrice,
  //   // totalPrice: 150,
  //   status: 0,
  //   approvalDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
  //   salesDetails: details
  // }
  // // console.log(requestData);
  // const fetchModule = new FetchModule();
  // (
  //   async () => {
  //     const responseData = await fetchModule.postDataOfEvent('sales/merchant/', 'POST', requestData);
  //     // console.log(responseData);
  //     if (responseData.status === 200) {
  //       setMerchantUid(responseData.data);
        
  //       window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/request/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

  //       // console.log('http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/' + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', ''))
        
  //       // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 201) {
  //       alert(t('이미 신청내역이 존재합니다.'));
  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 202) {
  //       alert(t('올바르지 않은 접근입니다'));
  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 203) {
  //       alert('참가 신청 가능인원이 초과되었습니다.');
  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 204) {
  //       alert(t('신청 에러입니다. 잠시후 다시 시도해주세요'));
  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 205) {
  //       alert('팀장만 결제를 진행할 수 있습니다.');
  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //     else if (responseData.status === 206) {
  //       setMerchantUid(responseData.data);
  //       window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'https://event.link-coach.io/payment/admin'}));
  //       // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.7:3001/payment/admin'}));
  //       // window.ReactNativeWebView.postMessage(JSON.stringify({ openLink: 'http://172.30.1.39:3001/payment/show_your_performance/' + itemName + '/'  + responseData.data + '/' + window.localStorage.getItem('Token').replace('Bearer ', '') }));

  //       setTimeout(() => setIsLoading(false), 1000);
  //     }
  //   }
  // )();
}

  // 신청 정보 수정 버튼 클릭시 //
  function onClickModifyInfo() {
    // console.log('신청 정보 버튼 클릭');
    // /application/:isTeamFight/:isModify
    // alert('eventMemberData[0].phone : ' + eventMemberData[0].phone);
    // alert('myPhone : ' + myPhone);
    // 팀장만 수정권한이 있음
    if (eventMemberData[0].phone === myPhone) navigate('/application/' + eventId + '/' + isTeam + '/' + isModify);
    else alert(t('신청 정보 수정은 팀장만 가능합니다'));
    // navigate('/application/' + eventData.selectedEvent.isTeamFight + '/' + isModify);
  }

  // 참가 취소 버튼 클릭시 //
  function onClickCancellation() {
    if (eventMemberData[0].phone === myPhone) setIsClickedCancellation(true);
    else alert(t('참가 신청 취소는 팀장만 가능합니다'));
  }

  // 참가 취소 경고모달에서 취소버튼 클릭시 //
  function onClickCancellationYes() {
    setIsLoading(true);
    setIsClickedCancellation(false);
    // console.log(eventData);
    // console.log(eventId);
    let requestData = {
      // eventId: eventData.selectedEvent.eventId,
      eventId: eventId,
      merchantUid: merchantUid
    }

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.postDataOfEvent('sales/cancellation', 'POST', requestData);
        // console.log(responseData);
        if (responseData.status === 200) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          try {
            window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
          }
          catch (e) {
            console.log("Web Message Post Error : " + e);
          }
        }
        else if (responseData.status === 204) {
          setTimeout(() => setIsLoading(false), 1000);

          alert(t("이미 취소된 결제건 입니다."));
        }
        else if (responseData.status === 401) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('동일한 결제자가 아닙니다.\n참가 취소는 결제자만 가능합니다.'));
        }
        else if (responseData.status === 402) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('완료된 결제건이 아닙니다.'));
        }
        else if (responseData.status === 403) {
          // setIsClickedCancellation(false);
          setTimeout(() => setIsLoading(false), 1000);

          alert(t('환불 가능기간이 지났습니다.'));
        }
      }
    )();
  }

  // 메인화면으로 클릭시 //
  function onClickGoHome() {

    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ go: 'Event' }));
    }
    catch (e) {
      console.log("Web Message Post Error : " + e);
    }
  }

  return (
    <TopLevelWrapper>
      {isLoading && <LoadingAnimation />}
      {isClickedCancellation && <CancellationModal yesFn={onClickCancellationYes} closeFn={() => setIsClickedCancellation(false)} />}
      {
        isSelectPaymentMethod &&
          <PaymentSelectModal
            setIsSelectPaymentMethod={setIsSelectPaymentMethod}
            eventId={eventId}
            amount={amount}
            orderName={orderName}
            totalPrice={totalPrice}
            merchantUid={merchantUid} />
      }
      <ComponentWrapper>
        <TitleWrapper style={{ margin: '22px 0px 24px 0px' }}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('참가 신청 정보를 확인해 주세요.')}</Text>
        </TitleWrapper>
        {
          isTeam &&
          <MemberTotalInfoBox>
            <LayerInfoBox>
              <InfoBox>
                <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('종목')}</Text>
                {/* <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{eventData.title + ' ' + eventData.selectedEvent.eventTitle}</Text> */}
                <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{eventTitle}</Text>
                <Text style={{ marginTop: `20px` }} fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('팀이름')}</Text>
                <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{teamData.teamName}</Text>
              </InfoBox>
              <InfoBox>
                <Image src={teamData.teamLogo === '' ? NoneProfile : teamData.teamLogo} />
              </InfoBox>
            </LayerInfoBox>
            <DivisionLine />
          </MemberTotalInfoBox>
        }
        {
          eventMemberData.map((data, index) => (
            <MemberTotalInfoBox key={index}>
              <TextInfoWrapper>
                <Text fontFamily='NotoSansKR-Bold' fontSize={15} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' 정보 확인'}</Text>
              </TextInfoWrapper>
              <LayerInfoBox>
                {
                  data.division === 'representative' &&
                  <InfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('이름')}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.name}</Text>
                  </InfoBox>
                }
                {
                  data.division === 'representative' &&
                  <InfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>팀장 이메일</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.email}</Text>
                  </InfoBox>
                  // :
                  // <InfoBox>
                  //   <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{t('참가자')}{' ' + t('소속')}</Text>
                  //   <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.belong}</Text>
                  // </InfoBox>
                }
              </LayerInfoBox>
              <LayerInfoBox>
                {
                  data.division !== 'representative' &&
                  <InfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('이름')}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.name}</Text>
                  </InfoBox>
                }
                <InfoBox style={ data.division === 'representative' ? { marginTop: `24px` } : { marginTop: `0px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('연락처')}</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.phone}</Text>
                </InfoBox>
                {/* {
                  index === 0 &&
                  <InfoBox style={ data.division === 'representative' ? { marginTop: `24px` } : { marginTop: `0px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('생년월일')}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.birth}</Text>
                  </InfoBox>
                } */}
                {
                  index === 0 &&
                  <InfoBox style={ data.division === 'representative' ? { marginTop: `24px` } : { marginTop: `0px` }}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>공통 기념품</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.bloodType}</Text>
                  </InfoBox>
                }
                {/* <InfoBox style={ data.division === 'representative' ? { marginTop: `24px` } : { marginTop: `0px` }}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('생년월일')}</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.birth}</Text>
                </InfoBox> */}
              </LayerInfoBox>
              {
                (data.division === 'representative') &&
                <LayerInfoBox style={{ marginTop: `24px` }}>
                  <AddressInfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? t('팀장 체육관 주소') : '주소'}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.address}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.addressDetail}</Text>
                  </AddressInfoBox>
                </LayerInfoBox>
              }
              <LayerInfoBox style={{ marginTop: `24px` }}>
                <InfoBox>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('성별')}</Text>
                  <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.gender === 'male' ? '남자' : '여자'}</Text>
                </InfoBox>
                {/* {
                  index !== 0 &&
                  <InfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{' ' + t('생년월일')}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.birth}</Text>
                  </InfoBox>
                } */}

                {
                  index !== 0 &&
                  <InfoBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>공통 기념품</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.bloodType}</Text>
                  </InfoBox>
                }
                {
                  (data.division === 'representative') &&
                  <InfoBox>
                    {/* <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>기념품 티셔츠 사이즈</Text> */}
                    <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#4F4F4F'>{isTeam ? data.division === 'representative' ? (t('팀장') + ' ') : t('팀원') + String.fromCharCode(index + 64) + ' ' : t('참가자')}{(' 체육관 이름')}</Text>
                    <Text style={{ marginTop: `5px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959'>{data.belong}</Text>
                  </InfoBox>
                }
              </LayerInfoBox>
              <DivisionLine />
            </MemberTotalInfoBox>
          ))
        }
        <MemberTotalInfoBox>
          {/* <LayerInfoBox style={{ marginBottom: `24px` }}>
            <AddressInfoBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>{t('참가 종목')}</Text>
              <Text style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>{eventTitle + ' (' + eventMemberData.length + t('인') + ')'}</Text>
            </AddressInfoBox>
          </LayerInfoBox> */}
          {/* {
            commonSouvenir !== undefined &&
            <LayerInfoBox style={{ marginBottom: `24px` }}>
              <AddressInfoBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>{t('공통 기념품 선택')}</Text>
                <Text style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {commonSouvenir.title + (commonSouvenir.count === 0 ? '' : ' X ' + commonSouvenir.count)}</Text>
              </AddressInfoBox>
            </LayerInfoBox>
          } */}
          {/* {
            additionalSouvenirs.length !== 0 &&
            <LayerInfoBox>
              <AddressInfoBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>{t('추가 기념품 선택')}</Text>
                {
                  additionalSouvenirs.map((data, index) => (
                    <Text key={index} style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {data.title + (data.count === 0 ? '' : ' X ' + data.count)}</Text>
                  ))
                }
              </AddressInfoBox>
            </LayerInfoBox>
          } */}
          {/* {
            eventData.commonSouvenirCount !== 0 &&
            <LayerInfoBox style={{ marginBottom: `24px` }}>
              <AddressInfoBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>공통 기념품 선택</Text>
                <Text style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {eventData.commonSouvenir.title + (eventData.commonSouvenirCount === 0 ? '' : ' X ' + eventData.commonSouvenirCount)}</Text>
              </AddressInfoBox>
            </LayerInfoBox>
          } */}
          {/* {
            eventData.additionalSouvenirList.length !== 0 &&
            <LayerInfoBox>
              <AddressInfoBox>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>추가 기념품</Text>
                {
                  eventData.additionalSouvenirList.map((data, index) => (
                    <Text key={index} style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {data.title + ' ' + data.gender + ' ' + data.size + (data.count === 0 ? '' : ' X ' + data.count)}</Text>
                  ))
                }
              </AddressInfoBox>
            </LayerInfoBox>
          } */}
          <LayerInfoBox>
            <AddressInfoBox>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#4F4F4F'>구매내역</Text>
              {
                sales.salesItems.map((data, index) => (
                  <Text key={index} style={{ marginTop: `8px` }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F'>- {data.itemName + ' ' + data.size + (data.count === 0 ? '' : ' X ' + data.count)}</Text>
                ))
              }
            </AddressInfoBox>
          </LayerInfoBox>
          <DivisionLine />
        </MemberTotalInfoBox>
        <LayerInfoBox>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#4F4F4F'>{t('최종 결제 금액')}</Text>
          {/* <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#4F4F4F'>{eventData === null ? sales.totalPrice : eventData.totalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{t('원')}</Text> */}

          <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#4F4F4F'>{decideFinalPayment() + ' ' + t('원')}</Text>
          {/* <Text fontFamily='NotoSansKR-Medium' fontSize={18} color='#4F4F4F'>{(eventData === null).toString()}</Text> */}
        </LayerInfoBox>
      </ComponentWrapper>
      {/* {
          isModify ?
            <NextButtonWrapper>
              <NextInnerButtonWrapper>
                <Button backgroundColor='#6DD49E' onClick={onClickModifyInfo}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('정보 수정')}</Text>
                </Button>
                <Button backgroundColor='#FF3131' onClick={onClickCancellation}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('참가 취소')}</Text>
                </Button>
              </NextInnerButtonWrapper>
            </NextButtonWrapper>
            :
            params.isFirst === 'true' ?
            <NextButtonWrapper>
              <NextInnerButtonWrapper>
                <Button backgroundColor='#E0E0E0' onClick={onClickPrevButton}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('이전')}</Text>
                </Button>
                <Button backgroundColor='#6DD49E' onClick={onClickNextButton}>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('신청')}</Text>
                </Button>
              </NextInnerButtonWrapper>
            </NextButtonWrapper>
            :
            <NextButtonWrapper>
              <MainButton onClick={onClickGoHome}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{'메인 화면으로'}</Text>
              </MainButton>
            </NextButtonWrapper>
        } */}
      <NextButtonWrapper>
        {/* <NextInnerButtonWrapper>
          <Button backgroundColor='#E0E0E0' onClick={onClickPrevButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('이전')}</Text>
          </Button>
          <Button backgroundColor='#6DD49E' onClick={onClickNextButton}>
            <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>{t('신청')}</Text>
          </Button>
        </NextInnerButtonWrapper> */}
        <PrevButton onClick={onClickPrevButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>정보 수정</Text>
        </PrevButton>
        <PaymentButton onClick={onClickNextButton}>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#FFFFFF'>결제 하기</Text>
        </PaymentButton>
      </NextButtonWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;
  // align-items: space-between;

  width: 100%;
  // height: 100%;
  // height: calc(100vh - 68px);
  min-height: 100vh;

  background-color: #F9F9F9;
`;

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const MemberTotalInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;

const TextInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 16px;

  width: 90.33%;
`;

const LayerInfoBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  // width: 50%;
  width: 45%;
`;

const AddressInfoBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const Image = styled.img`
  width: 116px;
  height: 116px;

  border-radius: 8px;
  background-color: #D9D9D9;

  object-fit: cover;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 24px 0px 24px 0px;

  width: 90.33%;
  height: 1px;

  background-color: #E0E0E0;
`;

const NextButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: flex-end;

  // padding: 0px 0px 34px 0px;
  // padding-bottom: 15px;
  margin: 52px 0px 0px 0px;

  width: 100%;
  // height: 68px;

  background-color: #FFFFFF;
`;

const NextInnerButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 48.39%;
  height: 60px;

  border-radius: 12px;
  background-color: ${props => props.backgroundColor};

  :hover {
    cursor: pointer;
  }
`;

const MainButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  height: 60px;

  border-radius: 12px;
  background-color: #6DD49E;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 75px;

  border-top-right-radius: 12px;
  // border-top-right-radius: 12px;
  background-color: #6DD49E;
`;

const PrevButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 75px;

  border-top-left-radius: 12px;
  // border-top-right-radius: 12px;
  background-color: #FF8B48;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  padding: 0 0 0 0;
  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;