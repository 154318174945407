import React from "react";
import styled from "styled-components";

export default function PriceInput(props) {
  const {
    enabled,
    value,
    setValue,
    index,
  } = props;

  function priceHandleChange(e) {
    const curValue = e.target.value
    const newValue = curValue.replace(/[^0-9]/g, '')

    setValue(newValue, index);
  }

  function priceHandleFocus(e) {
  }

  function priceHandleBlur(e) {
  }

  return (
    <TopLevelWrapper>
      <ViewWrapper> 
        <InputViewWrapper>
            {
              enabled ?
                <InputView>
                  <Input
                    placeholder={0}
                    value={value}
                    type='text'
                    onChange={priceHandleChange}
                    onFocus={priceHandleFocus}
                    onBlur={priceHandleBlur} />
                </InputView>
                :
                <SettingInputView>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{value}</Text>
                </SettingInputView>
            }
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>KRW</Text>
          </InputViewWrapper>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: ${props => props.isUseOption ? `10px` : `0px`};
  margin-top: ${props => props.isUseOption ? `-15px` : `0px`};

  width: ${props => props.isUseOption ? `96.47%` : `100%`};

  gap: 28px;
`;

const InputViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isUseOption ? `50%` : `100%`};

  gap: 4px;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 5px 10px;

  width: ${props => props.isUseOption ? `176px` : `calc(90% - 20px)`};
  height: 18px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Input = styled.input`
	font-family: NotoSansKR-Medium;
	font-size: 14px;
	line-height: 18px;

	width: 100%;
	height: 18px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
  text-align: right;
	color: #FFFFFF;

  border: none;
  background-color: #444447;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;

const SettingInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  padding: 5px 10px;

  width: ${props => props.isUseOption ? `176px` : `calc(90% - 20px)`};
  height: 18px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;