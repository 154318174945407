import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import CalenderImagePath from '../../../Image/GrayCalenderIcon.png';
import CalenderRightArrowImagePath from '../../../Image/CalenderRightArrow.png';
import CalenderLeftArrowImagePath from '../../../Image/CalenderLeftArrow.png';
import CalenderLeftArrow2ImagePath from '../../../Image/CalenderLeftArrow2.png';
import CalenderRightArrow2ImagePath from '../../../Image/CalenderRightArrow2.png';
import TrashIcon from '../../../Image/WhiteTrashIcon.png';

export default function PriceInput(props) {
  const {
    enabled,
    isUseOption,
    appliedDate,
    setAppliedDate,
    name,
    setName,
    value,
    setValue,
    index,
    onClickDelete
  } = props;


  const [today, setToday] = useState(moment(appliedDate, 'YYYY.MM.DD'));
  const [dateList, setDateList] = useState([]);
  const [isViewCalender, setIsViewCalender] = useState(false);

  const TopLevelRef = useRef(null);
  const CalenderRef = useRef(null);

  const firstWeek = today.clone().startOf('month').week();
  const lastWeek = today.clone().endOf('month').week() === 1 ? 53 : today.clone().endOf('month').week();

  useEffect(() => {
    setToday(moment(appliedDate, 'YYYY.MM.DD'));
  }, [appliedDate])

  useEffect(() => {
    if (!isUseOption) return;

    let newDateList = [];

    for (let week = firstWeek; week <= lastWeek; week++) {
      for (let i = 0; i < 7; i++) {
        let days = today.clone().startOf('year').week(week).startOf('week').add(i, 'day');

        newDateList.push(days);
      }
    }
    setDateList(newDateList);

    if (isViewCalender) {
      CalenderRef.current.style.height = `${118 + 56 * (newDateList.length / 7)}px`;
      CalenderRef.current.style.border = `1px solid #6DD49E`;
      CalenderRef.current.style.zIndex = `500`;
      CalenderRef.current.style.opacity = `1`;
    }
    else {
      CalenderRef.current.style.height = `0px`;
      CalenderRef.current.style.border = `0px solid #2F2F32`;
      CalenderRef.current.style.zIndex = `-3`;
      CalenderRef.current.style.opacity = `0`;
    }
  }, [isUseOption, isViewCalender, today, firstWeek, lastWeek])

  // 컴포넌트 외부 클릭시 이벤트를 받기위한 리스너 연결
  useEffect(() => {
    document.addEventListener("mousedown", onClickOutSide);

    return () => {
      document.removeEventListener("mousedown", onClickOutSide);
    }
  }, [])

  // 컴포넌트 외부 클릭시 실행
  function onClickOutSide(e) {
    if (isUseOption && TopLevelRef.current !== e.target && !TopLevelRef.current.contains(e.target)) {
      setIsViewCalender(false);
    }
  }

  function inputClick() {
    if (!enabled) return;

    setIsViewCalender(!isViewCalender);
  }

  function dateClick(dateListIndex) {
    setAppliedDate(dateList[dateListIndex], index);
    setIsViewCalender(false);
  }

  function onClickDeleteButton() {
    if (!enabled) return;

    onClickDelete(index)
  }

  function nameHandleChange(e) {
    setName(e.target.value, index);
  }

  function nameHandleFocus(e) {
  }

  function nameHandleBlur(e) {
  }

  function priceHandleChange(e) {
    const curValue = e.target.value
    const newValue = curValue.replace(/[^0-9]/g, '')

    setValue(newValue, index);
  }

  function priceHandleFocus(e) {
  }

  function priceHandleBlur(e) {
  }

  return (
    <TopLevelWrapper isUseOption={isUseOption}>
      {
        isUseOption &&
        <DeleteButtonWrapper>
          <DeleteButton enabled={enabled} onClick={onClickDeleteButton}>
            <DeleteIcon src={TrashIcon} />
          </DeleteButton>
        </DeleteButtonWrapper>
      }
      <ViewWrapper isUseOption={isUseOption}>
        {
          isUseOption &&
          <DateBoxWrapper ref={TopLevelRef}>
            <RelativeComponentWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>가격 적용일</Text>
              <DateBox onClick={inputClick}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{appliedDate.format('YYYY.MM.DD')}</Text>
                <CalenderImage src={CalenderImagePath} />
              </DateBox>
            </RelativeComponentWrapper>
            <CalenderView ref={CalenderRef}>
              <ButtonWrapper>
                <TitleWrapper>
                  <Arrow img={CalenderLeftArrow2ImagePath} onClick={() => setToday(today.clone().subtract(1, 'year'))} style={{ margin: `0 -7px 0 0` }} />
                  <Arrow img={CalenderLeftArrowImagePath} onClick={() => setToday(today.clone().subtract(1, 'month'))} />
                  <Title>{today.format('YYYY년 MM월')}</Title>
                  <Arrow img={CalenderRightArrowImagePath} onClick={() => setToday(today.clone().add(1, 'month'))} />
                  <Arrow img={CalenderRightArrow2ImagePath} onClick={() => setToday(today.clone().add(1, 'year'))} style={{ margin: `0 0 0 -7px` }} />
                </TitleWrapper>
              </ButtonWrapper>
              <DateColumnWrapper>
                <DateColumn style={{ width: `25px`, marginRight: `36px` }}>SUN</DateColumn>
                <DateColumn style={{ width: `30px`, marginRight: `34px` }}>MON</DateColumn>
                <DateColumn style={{ width: `23px`, marginRight: `36px` }}>TUE</DateColumn>
                <DateColumn style={{ width: `28px`, marginRight: `35px` }}>WED</DateColumn>
                <DateColumn style={{ width: `25px`, marginRight: `42px` }}>THU</DateColumn>
                <DateColumn style={{ width: `18px`, marginRight: `40px` }}>FRI</DateColumn>
                <DateColumn style={{ width: `24px`, marginRight: `28px` }}>SAT</DateColumn>
              </DateColumnWrapper>
              <DateWrapper>
                {
                  dateList.map((data, index) => (
                    data.format('MM') === today.format('MM') ?
                      <Date key={index} onClick={() => dateClick(index)}>
                        {data.format('D')}
                      </Date>
                      :
                      <WithoutHoverDate key={index}>
                        {data.format('D')}
                      </WithoutHoverDate>
                  ))
                }
              </DateWrapper>
            </CalenderView>
          </DateBoxWrapper>
        }
        <LayerWrapper>
          {
            isUseOption &&
            <InputViewWrapper isUseOption={isUseOption} >
              {
                enabled ?

                  <InputView isUseOption={isUseOption}>
                    <Input
                      placeholder='가격 이름'
                      value={name}
                      type='text'
                      onChange={nameHandleChange}
                      onFocus={nameHandleFocus}
                      onBlur={nameHandleBlur} />
                  </InputView>
                  :
                  <SettingInputView isUseOption={isUseOption}>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{name}</Text>
                  </SettingInputView>
              }
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>가격 이름</Text>
            </InputViewWrapper>
          }
          <InputViewWrapper isUseOption={isUseOption} >
            {
              enabled ?

                <InputView isUseOption={isUseOption}>
                  <Input
                    placeholder={0}
                    value={value}
                    type='text'
                    onChange={priceHandleChange}
                    onFocus={priceHandleFocus}
                    onBlur={priceHandleBlur} />
                </InputView>
                :
                <SettingInputView isUseOption={isUseOption}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>{value}</Text>
                </SettingInputView>
            }
            <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' cursor='default'>KRW</Text>
          </InputViewWrapper>
        </LayerWrapper>
      </ViewWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  // gap: 28px;

  border: ${props => props.isUseOption ? `2px dashed #909090` : `0px`};
  border-radius: 5px;
  background-color: ${props => props.isUseOption ? `#444447` : `#2F2F32`};
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-end;

  width: 100%;
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  z-index: 2;

  border-radius: 0px 5px 0px 5px;
  background-color: #FF5A5A;

  opacity: ${props => props.enabled ? 1 : 0};

  :hover {
    cursor: ${props => props.enabled ? `pointer` : `default`};
  }
  
  transition: all 0.3s ease-in-out;
`;

const DeleteIcon = styled.img`
  width: 15px;
  height: 15px;
`;

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-bottom: ${props => props.isUseOption ? `10px` : `0px`};
  margin-top: ${props => props.isUseOption ? `-15px` : `0px`};

  width: ${props => props.isUseOption ? `96.47%` : `100%`};

  gap: 28px;
`;

const DateBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;
`;

const RelativeComponentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;

  gap: 5px;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 131px;
  height: 28px;

  gap: 8px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;

  :hover {
    cursor: pointer;
  }
`;

const LayerWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: ${props => props.isUseOption ? `flex-end` : `flex-start`};
  align-items: center;

  width: 100%;

  gap: 8px;
`;

const InputViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: ${props => props.isUseOption ? `50%` : `100%`};

  gap: 4px;
`;

const InputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 5px 10px;

  width: ${props => props.isUseOption ? `176px` : `calc(90% - 20px)`};
  height: 18px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const Input = styled.input`
	font-family: NotoSansKR-Medium;
	font-size: 14px;
	line-height: 18px;

	width: 100%;
	height: 18px;

	padding: 0 0 0 0;
	margin: 0 0 0 0;

	outline: none;
	
  text-align: right;
	color: #FFFFFF;

  border: none;
  background-color: #444447;

	::placeholder {
		color: #6A6A6C;
	}
  
  transition: all 0.3s ease-in-out;
`;

const SettingInputView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-end;

  padding: 5px 10px;

  width: ${props => props.isUseOption ? `176px` : `calc(90% - 20px)`};
  height: 18px;

  border: 2px dashed #909090;
  border-radius: 5px;
  background-color: #444447;
`;

const CalenderImage = styled.img`
  width: 18px;
  height: 18px;
`;

const CalenderView = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;

  margin-top: 38px;

  border: 0px solid #FF8B48;
  border-radius: 8px;
  background-color: #FFFFFF;

  z-index: -30;
  opacity: 0;
  
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: calc(100% - 56px);

  margin-top: 28px;
  padding: 0 28px 0 28px;
`;

const ExitButton = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 6px;
`;

const Arrow = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
  
  background: url(${props => props.img}) no-repeat center center;

  :hover {
    cursor: pointer;
  }
`;

const Title = styled.p`
  font-family: NotoSansKR-Bold;
  font-size: 14px;

  color: #232323;

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;

const DateColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;

  // justify-content: center;
  // align-items: center;

  // gap: 38px;
  margin-top: 29px;
  margin-left: 28px;
  margin-bottom: 23px;
`;

const DateColumn = styled.p`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  font-family: Poppins-bold;
  font-size: 12px;

  color: #6DD49E;
  
  margin: 0 0 0 0;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  width: 448px;
  margin-left: 8px;
`;

const Date = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  margin: 0 7px 5px 7px;

  background-color: #FFFFFF;
  
  font-family: Poppins-Medium;
  font-size: 14px;

  color: #595959;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
    background-color: #6DD49E;
    color: #FFFFFF;

    font-fmaily: Poppins-Bold;
    font-size: 14px;
  }
`;

const WithoutHoverDate = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 48px;
  height: 48px;

  border-radius: 48px;

  margin: 0 7px 5px 7px;

  background-color: #FFFFFF;

  font-family: Poppins-Regular;
  font-size: 14px;

  color: #777777;

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;