import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import ProfilePlusIcon from '../../../../Image/ProfilePlusIcon.png';

export default function LogoFileInput(props) {
  const { size, plusIconSize, imgBase64, setImgBase64 } = props;

  const [pictureWidth, setPictureWidth] = useState(0);
  const [pictureHeight, setPictureHeight] = useState(0);
  const [imgFile, setImgFile] = useState();
  const [imgLocalPath, setImgLocalPath] = useState('');

  const hiddenFileInput = useRef(null);
  const CanvasRef = useRef();
  
  useEffect(() => {
    if (imgBase64 !== undefined && imgBase64 !== '') setImgFile(true);
  }, [imgBase64])

  const handleClick = e => {
    hiddenFileInput.current.click();
  };

  const handleChange = e => {
    var pathpoint = e.target.value.lastIndexOf('.');
    var filepoint = e.target.value.substring(pathpoint + 1, e.target.length);
    var filetype = filepoint.toLowerCase();

    if (filetype === 'jpg' || filetype === 'png' || filetype === 'jpeg') {

      // 정상적인 이미지 확장자 파일인 경우
      const reader = new FileReader();
      
      var tmp = e.target.value.split('\\');
      setImgLocalPath(tmp[tmp.length - 1]);
      reader.onloadend = () => {
        const base64 = reader.result;
        const naturalImage = new Image();

        naturalImage.src = reader.result.toString();

        naturalImage.onload = function (e) {

          let naturalWidth = e.target.naturalWidth;
          let naturalHeight = e.target.naturalHeight;

          let resizeWidth = 0;
          let resizeHeight = 0;

          if (naturalWidth < 600) {
            resizeWidth = naturalWidth;
            resizeHeight = naturalHeight;
          }
          else {
            resizeWidth = 600;
            resizeHeight = (600 * naturalHeight) / naturalWidth;
          }


          let finalHeight = (resizeHeight * 368) / resizeWidth;

          setPictureWidth(368);
          setPictureHeight(finalHeight);

          const image = new Image();
          const canvas = CanvasRef.current;
          const ctx = canvas?.getContext('2d');

          if (ctx) {
            if (naturalWidth > 600) {
              canvas.width = resizeWidth;
              canvas.height = resizeHeight;

              image.src = base64.toString();

              // 사진 크기 조정후 Canvas에 그리기 //
              image.onload = function () {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);
              }

              // 크기 조절된 사진 데이터 정리하기 //
              canvas.toBlob(function (blob) {
                const reader = new FileReader();
                // Canvas에 다 그려졌을때 Event //
                reader.onload = function (e) {
                  // setPicture(canvas.toDataURL())
                  setImgBase64(canvas.toDataURL());
                }
                reader.readAsDataURL(blob);
              })
            }
            else {
              // setPicture(base64.toString());
              setImgBase64(canvas.toDataURL());
            }
          }
          else {
            alert('사용할 수 없는 파일입니다');
            console.log("Could not get context");
          }
        }
      };
      if (e.target.files[0]) {
        reader.readAsDataURL(e.target.files[0]);
      }
    }
    else {
      alert('.jpeg .jpg .png 파일만 가능합니다!');
    }
  };

  return (
    <TopLevelWrapper>
      <InputWrapper>
        <canvas ref={CanvasRef} style={{ display: 'none' }} />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: 'none' }} />
        <ProfileBox size={size} onClick={handleClick}>
          {
            imgFile ?
              <Logo size={size} src={imgBase64} />
              :
              <Logo size={plusIconSize} src={ProfilePlusIcon} />
          }
        </ProfileBox>
      </InputWrapper>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;

  align-items: flex-end;
`;

const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  margin-top: 8px;
  border-radius: 8px;
  background-color: #D9D9D9;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  object-fit: cover;
`;