import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ProfilePath from '../../Image/DefaultProfile.png';
import FetchModule from '../../Model/Network/FetchModule';

export default function TopBar(props) {
  const { nowPage } = props;

  const [name, setName] = useState('');
  const [logo, setLogo] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!nowPage.includes('/main')
      && !nowPage.includes('/product')
      && !nowPage.includes('/terms')
      && !nowPage.includes('/unit')
      && !nowPage.includes('/setting')) return;

    const fetchModule = new FetchModule();
    (
      async () => {
        const responseData = await fetchModule.getDataOfEventVer2('account/info', 'GET');
        
        if (responseData.status === 200) {
          setName(responseData.data.name);
          setLogo(responseData.data.logo);
        }
        else if (responseData.status === 401) {
          alert('로그인 후 이용이 가능합니다');
          
          navigate('/login');
        }
      }
    )();
  }, [nowPage])

  if (!nowPage.includes('/main')
    && !nowPage.includes('/product')
    && !nowPage.includes('/terms')
    && !nowPage.includes('/unit')
    && !nowPage.includes('/note')
    && !nowPage.includes('/setting')) return null;

  function onClickLogout() {
    alert('로그아웃 되었습니다!');

    window.localStorage.removeItem('StoreId');
    window.localStorage.removeItem('Token');
    window.localStorage.removeItem('RefreshToken');
    window.localStorage.removeItem('AccountId');

    navigate('/v2/login');
  }
  
  return (
    <TopLevelWrapper>
      <TotalWrapper>
        <TitleWrapper onClick={() => navigate('/main')}>
          <Title>EVENTER</Title>
        </TitleWrapper>
        <DynamicIslandView>
          <Text fontFmaily='NotoSansKR-Medium' fontSize={16} color='#FFFFFF' cursor='default'>{name}</Text>
        </DynamicIslandView>
        <InfoWrapper>
          <LogoutButton onClick={onClickLogout}>로그아웃</LogoutButton>
          <Profile src={logo === '' ? ProfilePath : logo} />
        </InfoWrapper>
      </TotalWrapper>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  padding: 14px 0px 24px 0px;

  width: 100vw;
  
  // height: 92px;

  background-color: #000000;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  max-width: 1194px;
  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  min-width: 202px;

  :hover {
    cursor: pointer;
  }
`;

const DynamicIslandView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 396px;
  height: 45px;

  border-radius: 30px;
  background: linear-gradient(90deg, #5BD98A 0%, #6DD49E 100%);
`;

const Title = styled.p`
  font-family: Poppins-SemiBold;
  font-size: 24px;
  color: #6DD49E;

  margin: 0 0 0 0;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-end;
  align-items: center; 

  gap: 16px;

  min-width: 141px;
`;

const LogoutButton = styled.p`
  font-family: NotoSansKR-Medium;
  font-size: 12px;

  color: #FFFFFF;

  margin: 0 0 0 0;

  :hover {
    cursor: pointer;
  }
`;

const Profile = styled.img`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 32px;

  transition: all 0.3s ease-in-out;

  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */

  backface-visibility: hidden;
  transform: translateZ(0);

  :hover {
    cursor: pointer;
  }
`;

const Text = styled.div`
  font-family: ${props => props.fontFmaily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }

  transition: all 0.3s ease-in-out;
`;
