import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import ProfilePath from '../../../Image/DefaultProfile.png';
import EventBox from "./EventBox";
import useResizeObserver from "../../Share/useResizeObserver";

export default function UserView(props) {
  const {
    title,
    mainPicture,
    bannerPicture,
    content,
    period,
    startDate,
    startTime,
    endDate,
    endTime,
    place,
    subjectivity,
    sponsor,
    support,
    souvenirPicture,
    tickets,
  } = props;

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [titleFontSize, setTitleFontSize] = useState(30);
  const [eventTitleFontSize, setEventTitleFontSize] = useState(18);
  const [isFixed, setIsFixed] = useState(false);
  const [profileSize, setProfileSize] = useState(0);
  const [buttonTopMargin, setButtonTopMargin] = useState(0);
  const [scrollDivision, setScrollDivision] = useState('info');

  const TopLevelRef = useRef();
  const SectionRef = useRef();
  const InfoRef = useRef();
  const EventRef = useRef();

  const { width, height } = useResizeObserver(TopLevelRef);

  // 화면 크기 추출후 사진, 참가신청 공백 크기 계산 //
  useEffect(() => {
    let eventMargin = 0;
    
    if (tickets === undefined) eventMargin = 36;
    else eventMargin = windowSize.height - (159 + (178 * tickets.length) + (24 * (tickets.length - 1)));

    setButtonTopMargin(eventMargin > 36 ? eventMargin : 36);
  }, [windowSize, tickets])

  // Window Resize Handler And Scroll Detect Save And Delete //
  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [])

  useEffect(() => {
    let finalSize = width * 0.9033;
    
    setProfileSize(finalSize);
    setTitleFontSize(Math.round((20 * width) / 414));
    setEventTitleFontSize(Math.round((17 * width) / 414));
  }, [width])

  // Window Resize Handler //
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  return (
    <TopLevelWrapper ref={TopLevelRef}>
      <ExplanationWrapper>
        <Text fontFamily='NotoSansKR-Medium' fontSize={16} color='#333333' cursor='default'>사용자가 보이는 화면입니다</Text>
      </ExplanationWrapper>
      <ComponentWrapper>
        <ImageWrapper>
          <Image src={mainPicture === '' ? ProfilePath : mainPicture} width={profileSize} height={profileSize} />
        </ImageWrapper>
        <TitleWrapper>
          <Text fontFamily='NotoSansKR-Black' fontSize={titleFontSize} color='#4F4F4F' cursor='default'>{title}</Text>
        </TitleWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={SectionRef} isFixed={isFixed} style={{ backgroundColor: '#F9F9F9' }}>
        <SectionTotalWrapper>
          <SectionWrapper>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F' cursor='default'>대회 내용</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'info'} />
          </SectionWrapper>
          <SectionWrapper>
            <SectionTextBox>
              <Text fontFamily='NotoSansKR-Medium' fontSize={15} color='#4F4F4F' cursor='default'>참가 종목</Text>
            </SectionTextBox>
            <SectionDivisionLine isClicked={scrollDivision === 'event'} />
          </SectionWrapper>
        </SectionTotalWrapper>
      </ComponentWrapper>
      <ComponentWrapper ref={InfoRef} style={{ marginTop: `0px` }}>
        <InfoTotalWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>이벤트 이름</Text>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{title}</Text>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>이벤트 내용</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{content}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px`, gap: '4px' }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>대회 기간</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{period}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>신청 기간</Text>
            <InfoInnerWrapper>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{`${startDate.format('YY년 MM월 DD일')}(${startTime})부터 ${endDate.format('YY년 MM월 DD일')}(${endTime})까지`}</Text>
            </InfoInnerWrapper>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>대회 장소</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{place}</PreText>
          </InfoWrapper>
          <InfoWrapper style={{ marginTop: `12px` }}>
            <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>주관사</Text>
            <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{subjectivity}</PreText>
          </InfoWrapper>
          {
            (sponsor !== undefined && sponsor !== '') &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>후원사</Text>
              <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{sponsor}</PreText>
            </InfoWrapper>
          }
          {
            (support !== undefined && support !== '') &&
            <InfoWrapper style={{ marginTop: `12px` }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282' cursor='default'>협찬사</Text>
              <PreText fontFamily='NotoSansKR-Regular' fontSize={14} color='#4F4F4F' cursor='default'>{support}</PreText>
            </InfoWrapper>
          }
          {
            (souvenirPicture !== undefined && souvenirPicture !== '') &&
            <InfoWrapper style={{ marginTop: `12px`, marginBottom: '8px' }}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>공통 기념품</Text>
            </InfoWrapper>
          }
        </InfoTotalWrapper>
        {
          (souvenirPicture !== undefined && souvenirPicture !== '') &&
          <InfoWrapper>
            <TShirtsSize src={souvenirPicture} />
          </InfoWrapper>
        }
      </ComponentWrapper>
      <ComponentWrapper ref={EventRef} style={{ paddingTop: `36px` }}>
        {
          tickets !== undefined &&
          <EventWrapper>
            {
              tickets.map((data, index) => (
                <EventBox
                  key={'UserViewTicket-' + index}
                  data={data}
                  index={index}
                  eventTitleFontSize={eventTitleFontSize} 
                  isUsePriceOption={data.isUsePriceOption}/>
              ))
            }
          </EventWrapper>
        }
        <Button>
          <Text fontFamily='NotoSansKR-Bold' fontSize={18} color='#F9F9F9'>접수 신청</Text>
        </Button>
      </ComponentWrapper>
    </TopLevelWrapper>
  );
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  padding-top: 10px;

  width: 100%;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const ExplanationWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
`;


const ComponentWrapper = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'static'};
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  background-color: #F9F9F9;
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
  
  margin-top: 30px;
`;

const Image = styled.img`
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: 12px;
  background-color: #E0E0E0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;

  margin-top: 12px;
`;

const SectionTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;

  // margin-top: 28px;
  margin-top: 18px;


  :hover {
    cursor: pointer;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 50%;
  height: 35px;
`;

const SectionTextBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 33px;
`;

const SectionDivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 3px;

  background-color: ${props => props.isClicked ? '#FF8B48' : '#D9D9D9'};

  transition: all 0.3s ease-in-out;
`;

const InfoTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 90.33%;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;
`;

const InfoInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const EventWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 90.33%;
`;

const Button = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 36px;

  width: 100%;
  height: 75px;

  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  background-color: #6DD49E;

  :hover {
    cursor: pointer;
    background-color: #2BCB67;
  }
  
  transition: all 0.3s ease-in-out;
`;

const TShirtsSize = styled.img`
  width: 100%;

  object-fit: contain;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.cursor};
  }
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  white-space: pre-line;

  :hover {
    cursor: ${props => props.cursor};
  }
`;
