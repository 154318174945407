import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useTranslation } from "react-i18next";

import NameArrow from '../../../../Image/NameArrow.png';

export default function EventBox(props) {
  const { data, index, eventTitleFontSize } = props;

  const { t } = useTranslation();

  // 참가비 글자화 //
  function numberToKorean(info){
    if (data.isUsePriceOption) return `${info.name} ${moment(info.appliedDate).format('M월 D일')} : ${info.price.toLocaleString()} KRW`;
    return `${info.price.toLocaleString()} KRW`;
  }

  return (
    <TopLevelWrapper isFirst={index === 0}>
      <EventInnerWrapper>
        <EventNameWrapper>
          <EventNameImage src={NameArrow} style={{ marginRight: `6px` }}/>
          <Text fontFamily='NotoSansKR-Black' fontSize={eventTitleFontSize} color='#333333'>{data.name}</Text>
        </EventNameWrapper>
        <EventInfoWrapper style={{ marginTop: `24px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가자격')}</Text>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#333333'>{data.entryQualification}</Text>
        </EventInfoWrapper>
        <EventInfoWrapper style={{ justifyContent: 'flex-end' }}>
          <PreText style={{ marginTop: '10px', textAlign: 'right' }} fontFamily='NotoSansKR-Medium' fontSize={14} color={'#828282'}>{data.additionalInstructions}</PreText>
        </EventInfoWrapper>

        {
          data.priceOptions.map((priceData, priceIndex) => (
            <EventInfoWrapper 
              key={'TicketPrice-' + priceIndex} 
              style={{ marginTop: priceIndex === 0 ? `16px` : `4px` }}>
              <Text style={{ opacity: priceIndex === 0 ? 1 : 0 }} fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('참가비')}</Text>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{numberToKorean(priceData)}</Text>
            </EventInfoWrapper>
          ))
        }
        <EventInfoWrapper style={{ marginTop: `3px` }}>
          <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#828282'>{t('장소')}</Text>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#333333'>{data.place}</Text>
        </EventInfoWrapper>
      </EventInnerWrapper>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 16px 0px;

  width: 100%;
  // height: 178px;
  // height: 243px;

  margin-top: ${props => props.isFirst ? 0 : 24}px;

  border-radius: 12px;
  box-shadow: 0px 10px 10px rgba(0, 54, 71, 0.02), 0px 14px 32px rgba(0, 54, 71, 0.05);
  background-color: #FFFFFF;
`;

const EventInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 92.24%;
`;

const EventNameWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

const EventNameImage = styled.img`
  width: 12px;
  height: 12px;
`;

const EventInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const PreText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  white-space: pre-line;

  :hover {
    cursor: default;
  }
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: default;
  }
`;